import { TransportType } from 'types';

export const typeOptions: {
  value: TransportType;
  label: string;
}[] = [
  { value: 'BUS', label: 'Autobuss' },
  { value: 'TRAM', label: 'Tramvajs' },
  { value: 'TROLLEY', label: 'Trolejbuss' },
];
