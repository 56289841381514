import { Person, Contact, City, Country, Bank } from 'types';
import { createSelectField } from './createSelectField';

export const PersonSelectField = createSelectField<Person>('crm/persons/', {
  getOptionLabel: ({ first_name, last_name }: Person) => `${first_name} ${last_name}`,
  getOptionValue: ({ id }: Person) => id.toString(),
});

export const ContactSelectField = createSelectField<Contact>('crm/contacts/', {
  getOptionLabel: ({ title }: Contact) => title,
  getOptionValue: ({ id }: Contact) => id.toString(),
});

export const CitySelectField = createSelectField<City>('crm/cities/', {
  getOptionLabel: ({ name }: City) => name,
  getOptionValue: ({ id }: City) => id.toString(),
});

export const CountrySelectField = createSelectField<Country>(
  [
    { name: 'Igaunija', code: 'EE' },
    { name: 'Latvija', code: 'LV' },
    { name: 'Lietuva', code: 'LT' },
    { name: 'Polija', code: 'PL' },
    { name: 'Somija', code: 'FI' },
    { name: 'Vācija', code: 'DE' },
    { name: 'Francija', code: 'FR' },
    { name: 'Zviedrija', code: 'SE' },
    { name: 'Norvēģija', code: 'NO' },
    { name: 'Krievija', code: 'RU' },
    { name: 'Baltkrievija', code: 'BY' },
    { name: 'Ukraina', code: 'UA' },
    { name: 'Gruzija', code: 'GE' },
    { name: 'Beļģija', code: 'BE' },
  ],
  {
    getOptionLabel: ({ name }: Country) => name,
    getOptionValue: ({ code }: Country) => code,
  },
);

export const BankSelectField = createSelectField<Bank>('crm/banks/', {
  getOptionLabel: ({ name, country: { name: country } }: Bank) => `${name} (${country})`,
  getOptionValue: ({ id }: { id: number }) => id.toString(),
});
