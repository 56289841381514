import React from 'react';

import { NavLink } from 'react-router-dom';

import { Bank } from 'types';
import { Column } from 'ffsdk';

export const columns: Column<Bank>[] = [
  {
    content: 'Nosaukums',
    key: 'name',
    render: ({ id, name }) => <NavLink to={`/crm/banks/${id}`}>{name}</NavLink>,
  },
  {
    content: 'Valsts',
    key: 'country.name',
  },
  {
    content: 'Kods',
    key: 'code',
  },
];
