import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { List, NewAdd, Item, NewChange, Remove } from 'core/route';
import { Crumb } from 'core/ui';

import { Vehicle, Fleet } from 'types';
import { columns } from './columns';
import { Form } from './Form';
import { ListFilter } from 'core';

export function Vehicles(): React.ReactElement {
  return (
    <List<Vehicle> url="transport/vehicles/" columns={columns}>
      <ListFilter />
    </List>
  );
}

const initialValues = {
  model: null,
  number: '',
  registration_number: null,
  notes: '',
  is_active: true,
};

export function NestedVehicles(props: { endpoint: string; fleet: Fleet; crumb: Crumb }): React.ReactElement {
  const { fleet, endpoint, crumb } = props;
  return (
    <Routes>
      <Route
        path="/"
        element={
          <List<Vehicle> columns={columns} url={endpoint} crumbs={[crumb]} addUrl="add">
            <ListFilter />
          </List>
        }
      />
      <Route
        path="add"
        element={
          <NewAdd
            crumbs={[crumb, { title: 'Pievienot transportlīdzekli' }]}
            initialValues={initialValues}
            element={<Form fleet={fleet} />}
            url={endpoint}
          />
        }
      />
      <Route
        path=":id/*"
        element={
          <Item<Vehicle>
            title={(vehicle) => vehicle.number}
            endpoint={{
              endpoint: `${endpoint}:id/`,
              method: 'GET',
            }}
          >
            {(itemContext) => {
              const crumbs = [crumb, { subTitle: 'Transportlīdzeklis', title: itemContext.title }];

              return (
                <Routes>
                  <Route
                    path="/"
                    element={
                      <NewChange
                        element={<Form fleet={fleet} log />}
                        crumbs={crumbs}
                        itemContext={itemContext}
                        url={`${endpoint}${itemContext.item.id}/`}
                        removeUrl="remove"
                      />
                    }
                  />
                  <Route
                    path="remove"
                    element={
                      <Remove
                        endpoint={{
                          endpoint: `${endpoint}${itemContext.item.id}/`,
                          method: 'DELETE',
                        }}
                        successUrl="../../"
                        backUrl="../"
                        itemTitle={itemContext.title}
                      />
                    }
                  />
                </Routes>
              );
            }}
          </Item>
        }
      />
    </Routes>
  );
}
