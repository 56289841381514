import React from 'react';
import { AgreementBalance } from '../types';

interface HeaderProps {
  balance: AgreementBalance;
}

export function Header({ balance: { total, bound, diff } }: HeaderProps): React.ReactElement {
  return (
    <div className="agreement-invoices-header border-bottom pb-2 mb-3">
      <h4>Maksājumu plāns</h4>
      <div className="sums">
        <div className="sm">
          Jāizraksta: <strong data-testid="invoiceable">{total.toFixed(2)}</strong>
        </div>
        <div className="sm">
          Izrakstīts: <strong data-testid="invoiced">{bound.toFixed(2)}</strong>
        </div>
        <div>
          Atlikums: <strong data-testid="reminder">{diff.toFixed(2)}</strong>
        </div>
      </div>
    </div>
  );
}
