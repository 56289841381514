import React, { useEffect, useState, useCallback } from 'react';
import { Advert, AllocationResult } from 'types';
import { useAPI, useDebounce } from 'ffsdk';
import { useFormState } from 'react-final-form';
import { State, defaultLabels } from './State';

const estimateStateLables = {
  ...defaultLabels,
  reserved: 'pieejami',
};

export function Estimate(): React.ReactElement | null {
  const api = useAPI();
  const [allocation, setAllocation] = useState<AllocationResult | undefined>(undefined);
  const {
    values: { date_from, date_to, rent_units: demand, service },
  } = useFormState<Advert>({
    subscription: { values: true },
  });

  const getAllocation = useDebounce(
    useCallback(
      async (values) => {
        const valid = values.date_from && values.date_to && values.demand && values.service;
        if (valid) {
          const response = await api.post<AllocationResult, unknown>(`reservation/allocation/`, values);
          if (response.ok) {
            setAllocation(response.data);
          } else if (response.status === 400) {
            // We think payload is valid but server thinks otherwise
            setAllocation(undefined);
          } else {
            throw response.error;
          }
        } else {
          setAllocation(undefined);
        }
      },
      [api],
    ),
    500,
  );

  useEffect(() => {
    getAllocation({
      date_from,
      date_to,
      demand,
      service: service?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date_from, date_to, demand, service]);

  if (allocation) {
    return <State {...allocation} labels={estimateStateLables} />;
  }
  return null;
}
