import React, { isValidElement } from 'react';
import styled from '@emotion/styled';
import { Action, Actions } from './actions';
import cx from 'classnames';
const Sticky = styled.div`
  position: sticky;
  z-index: 10;
  top: 0px;
  background: #f4f4f4;
  height: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
`;

const Wrap = styled.div`
  flex-grow: 1;
`;

const ResourceNodeContainer = styled.div<{ right?: boolean }>`
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 1rem;
  padding-top: 10px;
  box-sizing: border-box;
  ${(props) => props.right && 'order: 1; margin-left: auto !important;'}
  h1 {
    margin: 5px 0;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
  }
  h2 {
    margin: 0;
    font-size: 11px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.28px;
    color: #565656;
  }
  .nav-container {
    margin-left: -0.4rem;
    margin-top: auto;
    padding-top: 3px;
    margin-bottom: 0;
    border-bottom: none;
    font-size: 13px;
  }

  .nav-link {
    padding: 0.4rem 0.4rem;
    &.active {
      border-color: #dee2e6 #dee2e6 #fff;
    }
  }
`;

interface CrumbNodeProps {
  title?: React.ReactNode;
  subTitle?: string;
  right?: boolean;
  className?: string;
}

export const CrumbNode: React.FC<CrumbNodeProps> = ({ title, subTitle, right, children, className }) => (
  <ResourceNodeContainer right={right} className={cx('resource-node-container', className)}>
    {subTitle && <h2>{subTitle}</h2>}
    {title && <h1>{title}</h1>}
    {children}
  </ResourceNodeContainer>
);

export type Crumb = React.ReactElement | CrumbNodeProps;
export type Crumbs = Crumb[];

const ResourceStackContainer = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
  min-width: 0;
  height: 100%;
  .resource-node-container + .resource-node-container {
    border-left: 1px solid #d8d8d8;
    margin-left: 1rem;
    padding-left: 1rem;
  }
`;

const ResourceStack: React.FC<{ crumbs: Crumb[] }> = ({ crumbs }) => (
  <ResourceStackContainer>
    {crumbs.map((crumb, idx) =>
      isValidElement(crumb) ? React.cloneElement(crumb, { key: `${idx}` }) : <CrumbNode {...crumb} key={idx} />,
    )}
  </ResourceStackContainer>
);

interface ActionBarProps {
  actions?: Action[];
  crumbs: Crumb[];
  title?: string;
  extraActions?: React.ReactElement;
}

const Container = styled.div`
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  display: flex;
  min-height: 50px;
`;

export const ActionBar: React.FC<ActionBarProps> = ({ crumbs, children, actions, extraActions }) => (
  <Sticky>
    {/* <BreadCrumbs crumbs={crumbs} /> */}
    <Wrap>
      <Container className="container">
        <ResourceStack crumbs={crumbs} />
        <Actions actions={actions}>{extraActions}</Actions>
        {children}
      </Container>
    </Wrap>
  </Sticky>
);
