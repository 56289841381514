import React from 'react';
import { typeOptions } from '../const';
import { SelectField, TextField, CheckField } from 'ffsdk';
import { ContactSelectField } from 'components';

export function Form(): React.ReactElement {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-row">
          <TextField name="title" label="Nosaukums" className="col" required />
          <ContactSelectField name="contact" className="col" label="Kontakts" required />
        </div>
        <SelectField name="type" label="Tips" options={typeOptions} simple required />
        <TextField name="notes" multiline autosize label="Piezīmes" />
        <TextField name="address" label="Adrese" />
        <div className="form-row">
          <TextField name="phone" label="Tālrunis" className="col" />
          <TextField name="email" label="E-pasts" type="email" className="col" />
        </div>
        <CheckField name="is_active" label="Iespējot" variant="switch" />
      </div>
    </div>
  );
}
