import React from 'react';
import { useAPIRequest } from 'core';
import { LoadingContainer, Alert } from 'ffsdk';
import { Invoice } from 'types';
import { Link } from 'react-router-dom';
import { formatDate } from 'core/util';

function Card(): React.ReactElement {
  const invoices = useAPIRequest<Invoice[]>({
    url: 'invoicing/invoices/',
    method: 'GET',
    queryParams: {
      page_size: '',
      late: true,
      ordering: 'payment_date',
    },
  });

  const rows = invoices || [];
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Kavēti rēķini</h5>
        <LoadingContainer loading={!invoices}>
          {rows.length > 0 ? (
            <table className="table table-sm mb-0">
              <thead>
                <tr>
                  <th>Numurs</th>
                  <th>Klients</th>
                  <th>Apm. dat.</th>
                  <th className="text-right">Summa</th>
                </tr>
              </thead>
              <tbody>
                {rows.map(({ id, display_number, client, payment_date, sum_total }) => (
                  <tr key={id}>
                    <td>
                      <Link to={`/campaigns/invoices/${id}`}>{display_number}</Link>
                    </td>
                    <td>{client.title}</td>
                    <td>{formatDate(payment_date)}</td>
                    <td className="text-right">{sum_total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Alert variant="success" className="mb-0">
              Nav kavēti rēķini
            </Alert>
          )}
        </LoadingContainer>
      </div>
    </div>
  );
}
export function LateInvoices(): React.ReactElement {
  return <Card />;
}
