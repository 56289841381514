import React from 'react';
import { SelectField, SelectFieldProps } from 'ffsdk';
import { OptionsType } from 'react-select';
import useOptions, { useDefaultOptions } from './useOptions';
import { useField } from 'react-final-form';

type BoundProps<T> = Omit<SelectFieldProps<T>, 'options' | 'loadOptions'> & { filter?: Record<string, unknown> };

interface DefaultOptionsProviderProps<T> {
  name: string;
  url: string;
  filter?: Record<string, unknown>;
  children: (defaultOptions?: T[] | boolean) => React.ReactElement;
}

function DefaultOptionProvider<T>({ url, filter, name, children }: DefaultOptionsProviderProps<T>): React.ReactElement {
  const {
    input: { value },
  } = useField(name, { subscription: { value: true } });

  const defaultOptions = useDefaultOptions<T>(url, { ...filter, id: value });
  return children(defaultOptions);
}

function AsyncSelectField<T>({ url, filter, ...props }: BoundProps<T> & { url: string }): React.ReactElement {
  const loadOptions = useOptions<any>(url, filter);

  if (props.simple && (!props.defaultOptions || props.defaultOptions === true)) {
    return (
      <DefaultOptionProvider<T> name={props.name} url={url} filter={filter}>
        {(defaultOptions) => <SelectField loadOptions={loadOptions} {...props} defaultOptions={defaultOptions} />}
      </DefaultOptionProvider>
    );
  }
  return <SelectField loadOptions={loadOptions} {...props} />;
}

export type Option<V = string> = {
  label: string;
  value: V;
};

export function createSelectField<T>(
  optionsOrUrl: string | OptionsType<T>,
  extra?: Partial<SelectFieldProps<T>>,
): React.FC<BoundProps<T>> {
  const isAsync = typeof optionsOrUrl === 'string';
  const Component: React.ReactType = isAsync ? AsyncSelectField : SelectField;
  const boundProps = isAsync
    ? {
        url: optionsOrUrl,
      }
    : {
        options: optionsOrUrl,
      };

  function BoundSelect<T>(props: BoundProps<T>): React.ReactElement {
    return <Component {...boundProps} {...extra} {...props} />;
  }

  return BoundSelect;
}
