import { badgeFactory } from 'core/ui';
import { PrintJobState, printJobStateLabels } from 'types';

export const StateBadge = badgeFactory<PrintJobState>(
  {
    RECEIVED: 'info',
    APPROVED: 'info',
    PASSED_FOR_PRINT: 'warning',
    DONE: 'success',
    REJECTED: 'danger',
  },
  printJobStateLabels,
  'print-job-state',
);
