import { EmbeddedContact } from '../crm/contacts';
import { EmbeddedAgreement } from '../campaigns/agreements';
import { Person } from '../crm';
import { EmbeddedCampaign } from 'types/campaigns';
import { Option } from 'components/fields/createSelectField';

export type InvoiceType = 'INVOICE' | 'ADVANCE' | 'CREDIT_NOTE';
export const invoiceTypeLabels: Record<InvoiceType, string> = {
  INVOICE: 'Rēķins',
  ADVANCE: 'Avansa rēķins',
  CREDIT_NOTE: 'Kredītrēķins',
};

export const invoiceTypeOptions: Option<InvoiceType>[] = (['INVOICE', 'ADVANCE', 'CREDIT_NOTE'] as InvoiceType[]).map(
  (value) => ({
    value,
    label: invoiceTypeLabels[value],
  }),
);

export type InvoiceState = 'PENDING' | 'DRAFT' | 'CONFIRMED' | 'CANCELED';
export const invoiceStateLabels: Record<InvoiceState, string> = {
  PENDING: 'Gaida',
  DRAFT: 'Sagatave',
  CONFIRMED: 'Apstiprināts',
  CANCELED: 'Anulēts',
};

export const invoiceStateOptions: Option<InvoiceState>[] = ([
  'PENDING',
  'DRAFT',
  'CONFIRMED',
  'CANCELED',
] as InvoiceState[]).map((value) => ({
  value,
  label: invoiceStateLabels[value],
}));

export type PaymentState = 'NEW' | 'LATE' | 'PARTIAL' | 'PAID';
export const paymentStateLabels: Record<PaymentState, string> = {
  NEW: 'Jauns',
  LATE: 'Kavēts',
  PARTIAL: 'Daļējs',
  PAID: 'Apmaksāts',
};
export const paymentStateOptions: Option<PaymentState>[] = (['NEW', 'LATE', 'PARTIAL', 'PAID'] as PaymentState[]).map(
  (value) => ({
    value,
    label: paymentStateLabels[value],
  }),
);

export interface Invoice {
  id: number;
  enterprise: EmbeddedContact;
  client: EmbeddedContact;
  agreement: EmbeddedAgreement | null;
  campaign: EmbeddedCampaign | null;
  type: InvoiceType;
  state: InvoiceState;
  payment_state: PaymentState | null;
  // sequence:
  // position:
  // number:
  checkout_date: string;
  payment_date: string;
  motivation: string;
  language: 'en' | 'lv';
  po_number: string;
  sum_raw: string;
  vat_rate: string;
  sum_vat: string;
  sum_total: string;
  paid_amount: string;
  signature_person: Person;
  display_number: string;
  public_url: string | null;
  reminder: string;
  modified_at?: string;
  days_late?: number;
}
