import React from 'react';
import { Module } from 'components';
import { Navigate } from 'react-router-dom';
import { Reservations } from './Reservations';
import { TNS} from './TNS';

export const route = {
  path: 'report/*',
  element: (
    <Module
      menu={[
        { to: 'reservations', children: 'Rezervācijas' },
        { to: 'tns', children: 'TNS' },

      ]}
    />
  ),
  children: [
    { path: 'reservations/*', element: <Reservations /> },
    { path: 'tns/*', element: <TNS /> },
    { path: '*', element: <Navigate to="reservations/" /> },
  ],
};
