import React from 'react';
import { useField } from 'react-final-form';
import { DateRangePickerField, IntField } from 'ffsdk';
import { required } from 'core/fields/validate';
import { Days } from '../common';
import { Allocation } from '../common/Allocation';
import { Advert } from 'types';

export function AdvertFields({ advert }: { advert?: Advert }): React.ReactElement | null {
  const {
    input: { value: service },
  } = useField('service', { subscription: { value: true } });
  if (service) {
    return (
      <>
        <tr>
          <th>Periods</th>
          <td>
            <div className="d-flex form-inline">
              <DateRangePickerField
                fromName="date_from"
                fromPlaceholder="No"
                toName="date_to"
                toPlaceholder="Līdz"
                required
                validate={required}
              />
              <Days />
            </div>
          </td>
        </tr>
        <tr>
          <th>Borta numuru skaits</th>
          <td>
            <div className="d-flex">
              <IntField name="rent_units" required validate={required} />
              {service.estimate_units && (
                <>
                  <div className="th flex-grow-1 text-right">Vienības</div>
                  <IntField name="estimate_units" required validate={required} />
                </>
              )}
            </div>
          </td>
        </tr>
        <tr>
          <th></th>
          <td>
            <Allocation advert={advert} />
          </td>
        </tr>
        <tr>
          <th>Ražošanas vienības</th>
          <td>
            <div className="d-flex">
              <IntField name="production_units" required validate={required} />
            </div>
          </td>
        </tr>
      </>
    );
  }
  return null;
}
