import React from 'react';
import { useField } from 'react-final-form';
import { DecimalField, SelectField } from 'ffsdk';
import { Rent, PositionTotals, Service } from 'types';

export type Tax = 'riga_51' | 'daugavpils_55' | 'jurmala_20' | 'liepaja_3' | 'rezekne_7';
export const CITY_TAX_MAP: Record<string, Tax> = {
  Rīga: 'riga_51',
  Daugavpils: 'daugavpils_55',
  Jūrmala: 'jurmala_20',
  Liepāja: 'liepaja_3',
  Rēzekne: 'rezekne_7',
};

export const taxOptions: Array<{
  value: Tax;
  label: string;
}> = [
  { value: 'riga_51', label: 'riga_51' },
  { value: 'daugavpils_55', label: 'daugavpils_55' },
  { value: 'jurmala_20', label: 'jurmala_20' },
  { value: 'liepaja_3', label: 'liepaja_3' },
  { value: 'rezekne_7', label: 'rezekne_7' },
];

export function RentFields({
  name = 'rent',
  totals,
  lockTax,
}: {
  name?: string;
  totals?: PositionTotals;
  lockTax?: boolean;
}): React.ReactElement | null {
  const {
    input: {
      value: { id: service_id, is_taxable },
    },
  } = useField<Service>('service', { subscription: { value: true } });

  const {
    input: { value: rent },
  } = useField<Rent>(name, { subscription: { value: true } });
  if (!rent) {
    return null;
  }

  const showTax = is_taxable || rent.tax;

  const unitPrice = totals?.unit_price || '—';
  const sum = totals?.sum || '—';
  const price = totals?.price || '—';

  return (
    <table className="table table-bordered table-form">
      <tbody>
        <tr>
          <th>Pakalpojums</th>
          <td>
            <div className="form-control-plaintext">{rent.title || '—'}</div>
          </td>
        </tr>
        {showTax && (
          <tr data-test-id="rent-tax">
            <th>Nodeva</th>
            <td>
              {lockTax ? (
                <div className="form-control-plaintext">{rent.tax || '—'}</div>
              ) : (
                <SelectField name="rent.tax" simple options={taxOptions} />
              )}
            </td>
          </tr>
        )}
        {rent.surfaces.map(({ title, type, area }, idx) => (
          <tr key={idx}>
            <th>{`${title} (${type})`}</th>
            <td>
              <div className="form-control-plaintext">{area} m²</div>
            </td>
          </tr>
        ))}
        {rent.price_unit === 'AREA' && (
          <tr>
            <th>Cena par m²</th>
            <td>
              <div className="form-control-plaintext" data-test-id="rent-price">
                {price}
              </div>
            </td>
          </tr>
        )}
        <tr>
          <th>{rent.time_unit ? `Vienības cena ${rent.time_unit}` : `Vienības cena`}</th>
          <td>
            <div className="form-control-plaintext">{unitPrice}</div>
          </td>
        </tr>
        <tr>
          <th>Summa</th>
          <td>
            <div className="d-flex">
              <div className="flex-grow-1">
                <div className="form-control-plaintext">{sum}</div>
              </div>
              {service_id && (
                <>
                  <div className="th text-right">Atl.%</div>
                  <DecimalField name="rent.discount" className="discount-field" disabled={lockTax} />
                </>
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
