import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReservationGroup } from 'types';
import { Column } from 'ffsdk';

export const columns: Column<ReservationGroup>[] = [
  {
    content: 'Nosaukums',
    key: 'title',
    render: ({ id, title }: ReservationGroup): React.ReactElement => (
      <NavLink to={`/settings/reservation/reservation_groups/${id}`}>{title}</NavLink>
    ),
  },
];
