import React from 'react';
import { Link } from 'react-router-dom';
import { EmbeddedAdvert, Advert } from 'types';
import { formatPeriod } from 'core/util';
import { StateBadge } from 'views/campaigns/Adverts/badge';
import './AdvertTitle.scss';

interface AdvertTitleProps {
  advert: EmbeddedAdvert | Advert;
  children?: React.ReactNode;
  hideCampaign?: boolean;
  hideState?: boolean;
  hideLink?: boolean;
}

function isEmbedded(a: EmbeddedAdvert | Advert): a is EmbeddedAdvert {
  return Boolean(a.sum_total);
}

export function AdvertTitle({
  advert,
  children,
  hideCampaign,
  hideState,
  hideLink,
}: AdvertTitleProps): React.ReactElement {
  let title = (
    <>
      <span className="advert-title-number">{advert.number}</span> ⋅ {`${advert.service.title} × ${advert.rent_units}`}
    </>
  );
  if (!hideLink && isEmbedded(advert)) {
    title = (
      <Link className="advert-title-advert" to={`/campaigns/campaigns/${advert.campaign.id}/adverts/${advert.id}`}>
        {title}
      </Link>
    );
  }
  return (
    <div className="advert-title-container">
      <div className="advert-title-content">
        {!hideCampaign && isEmbedded(advert) && (
          <div className="advert-title-campaign">
            <strong>{advert.campaign.title}</strong> {advert.campaign.client.title}
          </div>
        )}
        <div className="advert-title-advert">{title}</div>
        <div className="advert-title-meta">
          {!hideState && <StateBadge state={advert.state} />} {formatPeriod(advert)}{' '}
          {isEmbedded(advert) && <>⋅ € {advert.sum_total}</>} {children}
        </div>
      </div>
    </div>
  );
}
