import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Advert, agreementTypeLabels, Campaign } from 'types';
import { AdvertForm, ExtensionForm } from './Form';
import { columns, campaignColumns } from './columns';
import { formatDate } from 'core/util';
import { isImmutable, title } from './util';
import { StateBadge as AgreementStateBadge } from '../Agreements/badge';

import { resolveActions } from './resolveActions';

import { NewAdd, Item, NewChange, ItemContextType, List, Remove } from 'core/route';
import { Crumb, CrumbNode } from 'core/ui';

import { CitySelectField, ServiceSelectField, AdvertStateSelectField, EntityActions, AdvertTitle } from 'components';
import { ListFilter } from 'core';
import { TextField, DatePickerField } from 'ffsdk';

export function Adverts(): React.ReactElement {
  return (
    <List columns={columns} url="campaign/adverts/">
      <ListFilter>
        <TextField name="search" placeholder="Meklēt..." size="sm" />
        <CitySelectField name="city" size="sm" placeholder="Pakalp. pilsēta" simple defaultOptions className="ml-1" />
        <ServiceSelectField name="service" size="sm" placeholder="Pakalpojums" simple className="ml-1" />
        <AdvertStateSelectField name="state" size="sm" placeholder="Stāvoklis" className="ml-1" />
        <DatePickerField name="date_from" size="sm" placeholder="Rekl. no" className="ml-1" />
        <DatePickerField name="date_to" size="sm" placeholder="Rekl. līdz" className="ml-1" />
      </ListFilter>
    </List>
  );
}

// might want to memoize
const resolveCrumbs = ({ item: advert }: ItemContextType<Advert>): Crumb[] => {
  const crumbs = [
    <CrumbNode key="advert" className="justify-content-center pt-0">
      <AdvertTitle advert={advert} />
    </CrumbNode>,
  ];

  if (advert.agreement) {
    crumbs.push(
      <CrumbNode
        right
        title={advert.agreement.number}
        subTitle={`${agreementTypeLabels[advert.agreement.type]} ${formatDate(advert.agreement.date)}`}
        key="agreement"
      >
        <AgreementStateBadge state={advert.agreement.state} />
      </CrumbNode>,
    );
  }
  return crumbs;
};

const initialValues = {
  state: 'OFFER',
  service: null,
  rent_units: 1,
  production_units: 1,
  estimate_units: null,
  date_from: null,
  date_to: null,
  rent: {
    surfaces: [],
  },
  positions: [],
  standing: null,
};
export function CampaignAdverts(props: {
  campaign: Campaign;
  campaignCrumb: Crumb;
  setCampaign: (item: Campaign) => void;
}): React.ReactElement {
  const { campaign, campaignCrumb, setCampaign } = props;
  const endpoint = `/api/v1/campaign/campaigns/${campaign.id}/adverts/`;

  return (
    <Routes>
      <Route
        path="/"
        element={
          <List
            crumbs={[campaignCrumb]}
            columns={campaignColumns}
            url={endpoint}
            actions={[
              {
                variant: 'primary',
                to: 'extend',
                children: 'Pagarināt reklāmu',
              },
              {
                variant: 'primary',
                to: 'add',
                children: 'Pievienot reklāmu',
              },
            ]}
          />
        }
      />
      <Route
        path="add"
        element={
          <NewAdd
            crumbs={[campaignCrumb, { title: 'Pievienot reklāmu' }]}
            initialValues={initialValues}
            element={<AdvertForm campaign={campaign} />}
            url={endpoint}
          />
        }
      />
      <Route
        path="extend"
        element={
          <NewAdd
            crumbs={[campaignCrumb, { title: 'Pievienot pagarinājumu' }]}
            initialValues={initialValues}
            element={<ExtensionForm campaign={campaign} />}
            url={`${endpoint}extend/`}
          />
        }
      />
      <Route
        path=":id/*"
        element={
          <Item
            title={(a: Advert) => title(a)}
            endpoint={{
              endpoint: `${endpoint}:id/`,
              method: 'GET',
            }}
          >
            {(itemContext) => {
              const crumbs = [campaignCrumb, ...resolveCrumbs(itemContext)];
              const {
                item: { state, agreement },
              } = itemContext;
              const canRemove = state === 'CANCELED' && !agreement;
              return (
                <Routes>
                  <Route
                    path="/"
                    element={
                      <NewChange
                        itemContext={itemContext}
                        url={`${endpoint}${itemContext.item.id}/`}
                        element={
                          itemContext.item.extension_of ? (
                            <ExtensionForm campaign={campaign} itemContext={itemContext} />
                          ) : (
                            <AdvertForm campaign={campaign} itemContext={itemContext} />
                          )
                        }
                        extraActions={<EntityActions resolve={resolveActions} />}
                        disabled={isImmutable(itemContext.item)}
                        crumbs={crumbs}
                      />
                    }
                  />
                  {canRemove && (
                    <Route
                      path="remove"
                      element={
                        <Remove
                          endpoint={{
                            endpoint: `${endpoint}${itemContext.item.id}/`,
                            method: 'DELETE',
                          }}
                          successUrl={`/campaigns/campaigns/${campaign.id}`}
                          backUrl="../"
                          itemTitle={itemContext.title}
                          onSuccess={() =>
                            setCampaign({
                              ...campaign,
                              adverts: campaign.adverts.filter((a) => a.id !== itemContext.item.id),
                            })
                          }
                        />
                      }
                    />
                  )}
                </Routes>
              );
            }}
          </Item>
        }
      />
    </Routes>
  );
}
