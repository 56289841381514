import React from 'react';
import { Module } from 'components';
import { Navigate } from 'react-router-dom';
import { Fleets } from './Fleets';
import { Vehicles } from './Vehicles';
import { Models } from './Models';
import { Idles } from './Idles';

export const route = {
  path: 'transport/*',
  element: (
    <Module
      menu={[
        { to: 'fleets', children: 'Autoparki' },
        { to: 'vehicles', children: 'Transportlīdzekļi' },
        { to: 'models', children: 'Modeļi' },
        { to: 'idles', children: 'Dīkstāves' },
      ]}
    />
  ),
  children: [
    { path: 'fleets/*', element: <Fleets /> },
    { path: 'vehicles/*', element: <Vehicles /> },
    { path: 'models/*', element: <Models /> },
    { path: 'idles/*', element: <Idles /> },
    { path: '/*', element: <Navigate to="fleets/" /> },
  ],
};
