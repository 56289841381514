import { useEffect, useRef, useState } from 'react';

import { useAPI, RequestArgs } from 'ffsdk';

// @TODO counter, errors, abort

export function useAPIRequest<R>(args: RequestArgs, ...deps: any[]): R | undefined {
  const isMounted = useRef<boolean>(true);
  const [result, setResult] = useState<R | undefined>(undefined);
  const api = useAPI();

  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      setResult(undefined);
    }
    api.request<R, unknown>(args).then((res) => {
      if (res.ok) {
        if (isMounted.current) {
          setResult(res.data);
        }
      } else {
        setResult(() => {
          throw res.error;
        });
      }
    });
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return result;
}
