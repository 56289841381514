import React, { useCallback, useState } from 'react';
import { Button, DateRangePickerField, Form, LoadingContainer, Submit, useAPI } from 'ffsdk';
import { useLoader } from 'core';
import { formatISO, addMonths, subMonths, parseISO } from 'date-fns';

import { required } from 'core/fields/validate';
import { EmbeddedCampaign, EmbeddedService } from 'types';
import { NavLink } from 'react-router-dom';
import { formatDate } from 'core/util';
import './TNS.scss';
import { lightFormat } from 'date-fns/esm';
import { Icon } from 'components';

const now = new Date();
const date_from = formatISO(subMonths(now, 1), { representation: 'date' });
const date_to = formatISO(addMonths(now, 1), { representation: 'date' });

const initialState = {
  date_from,
  date_to,
};

interface TNSAdvert {
  id: number;
  campaign: EmbeddedCampaign;
  service: EmbeddedService;
  date_from: string;
  date_to: string;
  overlap_from: string;
  overlap_to: string;
  overlap_days: number;
  estimate: {
    units: number;
    area: string | null;
    price: string;
  };
  types: string[];
}

interface ResponseType {
  results: TNSAdvert[];
  total: string;
}

function ResultTable({ results, total }: ResponseType): React.ReactElement {
  return (
    <table className="table table-sm table-striped table-bordered" id="tns-report">
      <thead>
        <tr>
          <th>Reklāmdevējs</th>
          <th>Reklāmas nosaukums</th>
          <th>TL veids</th>
          <th>Reklāmas noformējums</th>
          <th>Vienību skaits</th>
          <th>Kopējā platība m²</th>
          <th>Eksp. sākums</th>
          <th>Eksp. beigas</th>
          <th>Tekošais periods</th>
          <th>Dienu skaits</th>
          <th>Kampaņas cena</th>
        </tr>
      </thead>
      <tbody>
        {results.map(
          ({
            id,
            campaign,
            service,
            date_from,
            date_to,
            overlap_from,
            overlap_to,
            overlap_days,
            estimate: { units, area, price },
            types,
          }) => (
            <tr key={id}>
              <td>{campaign.client.title}</td>
              <td>
                <NavLink to={`/campaigns/campaigns/${campaign.id}/adverts/${id}`}>{campaign.title}</NavLink>
              </td>
              <td>{types.join(', ')}</td>
              <td>{service.title}</td>
              <td className="text-center">{units}</td>
              <td className="text-center">{area}</td>
              <td className="text-center">{formatDate(date_from)}</td>
              <td className="text-center">{formatDate(date_to)}</td>
              <td className="text-center">
                {lightFormat(parseISO(overlap_from), 'dd.MM')}-{lightFormat(parseISO(overlap_to), 'dd.MM')}
              </td>
              <td className="text-center">{overlap_days}</td>
              <td className="text-right">{price}</td>
            </tr>
          ),
        )}
      </tbody>
      <tfoot>
        <tr>
          <th className="text-right" colSpan={10}>
            Kopējā summa
          </th>
          <th className="text-right">{total}</th>
        </tr>
      </tfoot>
    </table>
  );
}

export function TNS(): React.ReactElement {
  const api = useAPI();
  const [filter, setState] = useState<{ date_from: string; date_to: string }>(initialState);
  const onSubmit = useCallback((v) => {
    setState(v);
  }, []);

  const onExportClick = useCallback(() => {
    api
      .request<Blob, unknown>({
        url: 'reporting/tns/',
        method: 'GET',
        queryParams: {
          ...filter,
          export: true,
        },
      })
      .then((res) => {
        if (res.ok) {
          saveAs(res.data, 'Report_TNS.xlsx');
        } else {
          console.log(res);
          alert('error');
          throw res.error;
        }
      });
  }, [filter, api]);

  const loader = useLoader<ResponseType>(
    {
      url: 'reporting/tns/',
      method: 'GET',
      queryParams: filter,
    },
    filter.date_from,
    filter.date_to,
  );

  return (
    <div className="container-fluid">
      <div className="main py-3">
        <Form onSubmit={onSubmit} initialValues={filter} className="form-inline list-filter" noValidate>
          <DateRangePickerField fromName="date_from" toName="date_to" size="sm" required validate={required} />
          <Submit variant="outline-primary" size="sm" className="ml-1 mb-3">
            Meklēt
          </Submit>
          {(filter.date_from !== initialState.date_from || filter.date_to !== initialState.date_to) && (
            <Button variant="outline-danger" size="sm" className="ml-1 mb-3" onClick={() => setState(initialState)}>
              ×
            </Button>
          )}
          <Button size="sm" variant="outline-primary" className="ml-1 mb-3" onClick={onExportClick}>
            <Icon icon="file-excel" /> ekports uz Excel
          </Button>
        </Form>
        <LoadingContainer loading={loader.loading}>{loader.item && <ResultTable {...loader.item} />}</LoadingContainer>
      </div>
    </div>
  );
}
