import React from 'react';
import { StateBadge } from 'views/invoicing/Invoices/badge';
import { AgreementInvoice } from 'types';
import { Button, DatePickerField, TextField } from 'ffsdk';
import { Icon } from 'components';
import { NavLink } from 'react-router-dom';

interface EditProps extends Pick<AgreementInvoice, 'id' | 'state' | 'display_number'> {
  name: string;
  index: number;
  remove?: (idx: number) => void;
}
export function Edit({ id, state, display_number, name, index, remove }: EditProps): React.ReactElement {
  return (
    <tr>
      <td>
        <div>
          <StateBadge state={state} />
        </div>
        <small>{id ? <NavLink to={`/campaigns/invoices/${id}`}>{display_number}</NavLink> : display_number}</small>
      </td>
      <td className="date">
        <DatePickerField name={`${name}.checkout_date`} size="sm" required />
      </td>
      <td>
        <TextField multiline autosize name={`${name}.motivation`} size="sm" required />
      </td>
      <td className="summ">
        <TextField name={`${name}.sum_total`} size="sm" required />
      </td>
      <td className="tools">
        {remove && (
          <Button
            data-test-id="remove-inline"
            variant="link"
            type="button"
            onClick={() => remove(index)}
            className="text-danger"
            size="sm"
          >
            <Icon icon="times" />
          </Button>
        )}
      </td>
    </tr>
  );
}
