import React, { useRef, useEffect } from 'react';
import { useAPIRequest } from 'core';
import useMeasure from 'react-use-measure';

import { Alert, LoadingContainer } from 'ffsdk';

import { renderGraph } from './renderGraph';
import { Advert, Service } from './types';

function RenderGraph({ adverts, fleet }: { adverts: Advert[]; fleet: number }): React.ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);
  const [measureRef, { width }] = useMeasure({ debounce: 300, scroll: false });
  useEffect(() => {
    if (adverts && containerRef.current && width) {
      renderGraph(containerRef.current, adverts, width, fleet);
    }
  }, [adverts, width, fleet]);

  return (
    <div className="reservation-report-container" ref={measureRef}>
      <div ref={containerRef} />
      <div id="reservation-tooltip" />
    </div>
  );
}

export function Graph({
  service: { service_group, fleet },
  date_from,
  date_to,
}: {
  service: Service;
  date_from: string | null;
  date_to: string | null;
}): React.ReactElement {
  const data = useAPIRequest<Advert[]>(
    {
      url: `reporting/reservation/${service_group}/`,
      method: 'GET',
      queryParams: {
        date_from,
        date_to,
      },
    },
    service_group,
    date_from,
    date_to,
  );

  return (
    <LoadingContainer loading={!data}>
      {data &&
        (data.length > 0 ? (
          <RenderGraph adverts={data} fleet={fleet} />
        ) : (
          <Alert variant="info">Pagaidām nav rezervāciju</Alert>
        ))}
    </LoadingContainer>
  );
}
