import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { List, NewAdd, Item, NewChange } from 'core/route';

import { User } from 'types';
import { Form, PasswordForm } from './Form';
import { columns } from './columns';
import { Button } from 'ffsdk';

const endpoint = `/api/v1/security/users/`;

const initialValues = {
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  is_active: true,
  groups: [],
  password1: '',
  password2: '',
};

const initialPasswordValues = {
  password1: '',
  password2: '',
};

export function Users(): React.ReactElement {
  const navigate = useNavigate();
  return (
    <Routes>
      <Route path="/" element={<List columns={columns} url={endpoint} addUrl="add" />} />
      <Route path="add" element={<NewAdd initialValues={initialValues} element={<Form />} url={endpoint} />} />
      <Route
        path=":id/*"
        element={
          <Item
            title={(user: User) => user.username}
            endpoint={{
              endpoint: `${endpoint}:id/`,
              method: 'GET',
            }}
          >
            {(itemContext) => {
              return (
                <Routes>
                  <Route
                    path="/"
                    element={
                      <NewChange
                        element={<Form promptPassword />}
                        itemContext={itemContext}
                        url={`${endpoint}${itemContext.item.id}/`}
                        extraActions={
                          <Button variant="link" to="set_password">
                            Iestatīt paroli
                          </Button>
                        }
                      />
                    }
                  />
                  <Route
                    path="set_password"
                    element={
                      <NewChange
                        url={`${endpoint}${itemContext.item.id}/set_password/`}
                        method="POST"
                        itemContext={
                          {
                            ...itemContext,
                            item: initialPasswordValues,
                          } as any
                        }
                        onSuccess={() => navigate('../../')}
                        crumbs={[
                          {
                            subTitle: 'Iestatīt paroli',
                            title: itemContext.title,
                          },
                        ]}
                        element={<PasswordForm />}
                        extraActions={
                          <Button variant="link" to="../" end>
                            Atpakaļ
                          </Button>
                        }
                      />
                    }
                  />
                </Routes>
              );
            }}
          </Item>
        }
      />
    </Routes>
  );
}
