import React from 'react';
import { Module } from 'components';
import { Navigate } from 'react-router-dom';
import { Contacts } from './Contacts';
import { Persons } from './Persons';
import { Cities } from './Cities';
import { Banks } from './Banks';

export const route = {
  path: 'crm/*',
  element: (
    <Module
      menu={[
        { to: 'contacts', children: 'Kontakti' },
        { to: 'persons', children: 'Personas' },
        { to: 'cities', children: 'Pilsētas' },
        { to: 'banks', children: 'Bankas' },
      ]}
    />
  ),
  children: [
    { path: 'contacts/*', element: <Contacts /> },
    { path: 'persons/*', element: <Persons /> },
    { path: 'cities/*', element: <Cities /> },
    { path: 'banks/*', element: <Banks /> },
    { path: '*', element: <Navigate to="contacts/" /> },
  ],
};
