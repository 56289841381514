import React from 'react';
import cx from 'classnames';
import './StateBadge.scss';

// @TODO DRY
export type Variant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';

export function StateBadge({
  variant,
  label,
  state,
  children,
}: {
  variant: Variant;
  label?: string;
  state?: string;
  children?: React.ReactNode;
}): React.ReactElement {
  return (
    <span className={cx('state-badge', variant)} data-label={label} data-state={state}>
      {children}
    </span>
  );
}
