import React, { useState } from 'react';
import { Card, Modal } from 'core/ui';
import { Vehicle, Advert } from 'types';
import { Form, Button, DatePickerField, SelectField, Submit, useSubmitHandler } from 'ffsdk';
import { useAPIRequest } from 'core';
import { required } from 'core/fields/validate';
import { useField } from 'react-final-form';

interface VehicleOptionGroup {
  label: string;
  options: Vehicle[];
}

const groupByPark = (result: Vehicle[]): VehicleOptionGroup[] => {
  const parks = result.reduce((acc: Record<number, VehicleOptionGroup>, vehicle: Vehicle) => {
    const {
      fleet: { id, title: label },
    } = vehicle;
    if (!acc[id]) {
      acc[id] = { label, options: [] };
    }

    acc[id].options.push(vehicle);
    return acc;
  }, {});

  return Object.values(parks);
};

const selectProps = {
  name: 'vehicles',
  label: 'Transportlīdzekļi',
  isMulti: true,
  isRequired: true,
  getOptionLabel: ({ number }: Vehicle) => number,
  getOptionValue: ({ id }: Vehicle) => id.toString(),
  formatOptionLabel: ({ number, model: { title } }: Vehicle) => (
    <>
      {number} <small>({title})</small>
    </>
  ),
};

const ExposureVehicleSelect: React.FC<any> = ({ pickAll, ...params }) => {
  const {
    input: { onChange },
  } = useField('vehicles', { subscription: {} });

  const res = useAPIRequest<Vehicle[]>({
    url: '/api/v1/transport/vehicles/',
    method: 'GET',
    queryParams: {
      page_size: null,
      ...params,
    },
  });

  const options = res ? groupByPark(res) : [];
  return (
    <div className="form-row">
      <SelectField {...selectProps} options={options} className="col" />
      {pickAll && (
        <div className="col-3 d-flex">
          <div className="form-group flex-grow-1 align-self-stretch d-flex align-items-end">
            <Button
              className="btn-block"
              onClick={() => {
                onChange(res || []);
              }}
            >
              Visi
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

type Action = 'expose' | 'neutralize';

function Fields({ action, advert }: { action: Action; advert: Advert }): React.ReactElement | null {
  const {
    input: { value: date },
  } = useField('date', { subscription: { value: true } });
  if (!date) {
    return null;
  }

  return (
    <>
      {action === 'expose' ? (
        <ExposureVehicleSelect date_from={date} date_to={advert.date_to} service={advert.service.id} />
      ) : (
        <ExposureVehicleSelect exposed_for={advert.id} pickAll />
      )}

      <Submit>Iesniegt</Submit>
    </>
  );
}

export function VehicleExposureAction({
  advert,
  updateItem,
  action,
}: {
  advert: Advert;
  updateItem: (item: any) => void;
  action: Action;
}): React.ReactElement {
  const [open, setOpen] = useState<boolean>(false);

  const initialValues = {
    date: action === 'expose' ? advert.date_from : advert.date_to,
    vehicles: [],
  };

  const onSubmit = useSubmitHandler(
    {
      url: `campaign/campaigns/${advert.campaign}/adverts/${advert.id}/${action}_vehicles/`,
      method: 'POST',
    },
    {
      onSuccess: (item) => {
        updateItem(item);
        setOpen(false);
      },
    },
  );

  const title = action === 'expose' ? `Eksponēt` : 'Neitralizēt';
  return (
    <>
      <Button type="button" variant="primary" onClick={() => setOpen(true)}>
        {title}
      </Button>
      <Modal isOpen={open} ariaHideApp={false} onRequestClose={() => setOpen(false)}>
        <Card title={title}>
          <Form initialValues={initialValues} onSubmit={onSubmit}>
            <DatePickerField name="date" label="Datums" required validate={required} />
            <Fields advert={advert} action={action} />
          </Form>
        </Card>
      </Modal>
    </>
  );
}
