import React from 'react';
import { Outlet, NavLink, NavLinkProps } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon } from './Icon';
import './Module.scss';

type Menu = Array<NavLinkProps & { icon?: IconProp }>;
interface NavProps {
  menu: Menu;
}

function Nav({ menu }: NavProps): React.ReactElement {
  return (
    <ul className="navbar-nav nav">
      {menu.map(({ icon, children, ...props }, i) => (
        <li className="nav-item" key={i}>
          <NavLink className="nav-link" {...props}>
            {icon && <Icon icon={icon} />} {children}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}

interface NavBarProps {
  menu: Menu;
  altMenu?: Menu;
}

function NavBar({ menu, altMenu }: NavBarProps): React.ReactElement {
  return (
    <nav className="navbar navbar-expand navbar-dark module-navbar">
      <div className="container">
        <Nav menu={menu} />
        {altMenu && <Nav menu={altMenu} />}
      </div>
    </nav>
  );
}

export function Module(props: NavBarProps): React.ReactElement {
  return (
    <>
      <NavBar {...props} />
      <Outlet />
    </>
  );
}
