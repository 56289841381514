import React from 'react';
import { Totals } from 'types';

export function TotalsFields({ totals }: { totals?: Totals }): React.ReactElement {
  return (
    <table className="table table-bordered table-form">
      <tbody>
        <tr>
          <th>Starpsumma</th>
          <td>
            <div className="form-control-plaintext" data-test-id="sum-raw">
              {totals?.sum_raw || '—'}
            </div>
          </td>
        </tr>
        {(totals?.discounts || []).map(({ title, rate, sum }, i) => (
          <tr key={i}>
            <th>
              {title} ({rate}%)
            </th>
            <td>
              <div className="form-control-plaintext">{sum || '—'}</div>
            </td>
          </tr>
        ))}

        {totals?.sum_tax !== '0.00' && (
          <tr>
            <th>Nodeva</th>
            <td>
              <div className="form-control-plaintext">{totals?.sum_tax || '—'}</div>
            </td>
          </tr>
        )}
        <tr>
          <th>Summa bez PVN</th>
          <td>
            <div className="form-control-plaintext">{totals?.sum_subtotal || '—'}</div>
          </td>
        </tr>
        <tr>
          <th>PVN</th>
          <td>
            <div className="form-control-plaintext">{totals?.sum_vat || '—'}</div>
          </td>
        </tr>
        <tr>
          <th>Kopā</th>
          <td>
            <div className="form-control-plaintext" data-test-id="sum-total">
              {totals?.sum_total || '—'}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
