import React from 'react';
import { Conflict } from 'types';
import { AllocationBadge, AdvertTitle } from 'components';

export function Conflicts({ conflicts }: { conflicts: Conflict[] }): React.ReactElement | null {
  if (conflicts.length === 0) {
    return null;
  }
  return (
    <div className="px-1 pt-2">
      <h6 className="text-danger mb-0 px-2">Konflikti:</h6>
      {conflicts.map(({ advert, live, reserved }) => (
        <div className="px-2" key={advert.id}>
          <AdvertTitle advert={advert}>
            <div className="pt-1">
              <AllocationBadge className="live" count={live} label="aktīvi" />
              <AllocationBadge className="reserved" count={reserved} label="rezervēti" />
            </div>
          </AdvertTitle>
        </div>
      ))}
    </div>
  );
}
