import React, { Component } from 'react';
import { captureException, showReportDialog, lastEventId } from '@sentry/browser';
import { Alert } from 'ffsdk';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, { error: any }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }
  componentDidCatch(error: any, errorInfo: any): void {
    console.log('catch');
    this.setState({ error });
    captureException(error, { extra: errorInfo });
  }
  render(): React.ReactNode {
    if (this.state.error) {
      return (
        <div className="container">
          <div className="row justify-content-center align-items-center min-vh-100">
            <div className="col-9">
              <Alert variant="danger" heading="We're sorry — something's gone wrong">
                <div className="btn btn-link px-0" role="button" onClick={() => lastEventId() && showReportDialog()}>
                  Our team has been notified, but click here fill out a report.
                </div>
              </Alert>
            </div>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
