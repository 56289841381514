import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { List, NewAdd, Item, NewChange, Remove } from 'core/route';
import { Crumb } from 'core/ui';

import { Person } from 'types';
import { columns } from './columns';
import { Form } from './Form';
import { ListFilter } from 'core';

export function Persons(): React.ReactElement {
  return (
    <List<Person> url="crm/persons/" columns={columns}>
      <ListFilter />
    </List>
  );
}

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  role: '',
  mobile_phone: '',
  work_phone: '',
  notes: '',
};

export function NestedPersons({ endpoint, crumb }: { endpoint: string; crumb: Crumb }): React.ReactElement {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <List columns={columns} url={endpoint} crumbs={[crumb]} addUrl="add">
            <ListFilter />
          </List>
        }
      />
      <Route path="add" element={<NewAdd initialValues={initialValues} element={<Form />} url={endpoint} />} />
      <Route
        path=":id/*"
        element={
          <Item
            title={(person: Person) => `${person.first_name} ${person.last_name}`}
            endpoint={{
              endpoint: `${endpoint}:id/`,
              method: 'GET',
            }}
          >
            {(itemContext) => (
              <Routes>
                <Route
                  path="/"
                  element={
                    <NewChange
                      removeUrl="remove"
                      element={<Form />}
                      itemContext={itemContext}
                      crumbs={[crumb, { subTitle: 'Person', title: itemContext.title }]}
                      url={`${endpoint}${itemContext.item.id}/`}
                    />
                  }
                />
                <Route
                  path="remove"
                  element={
                    <Remove
                      endpoint={{
                        endpoint: `${endpoint}${itemContext.item.id}/`,
                        method: 'DELETE',
                      }}
                      successUrl="../../"
                      backUrl="../"
                      itemTitle={itemContext.title}
                    />
                  }
                />
              </Routes>
            )}
          </Item>
        }
      />
    </Routes>
  );
}
