import React, { useCallback } from 'react';
import { Advert } from 'types';

import { useField, useForm } from 'react-final-form';
import { FormGroup, Select } from 'ffsdk';
import { required } from 'core/fields/validate';
import { advertSelectProps } from 'components/fields';
import { useAPIRequest } from 'core';
import { rentFields, productionFields } from './factory';

interface ParentSelectProps {
  campaignId: number;
}

const subscription = {
  value: true,
  invalid: true,
  submitError: true,
  dirtySinceLastSubmit: true,
  error: true,
  dirty: true,
  touched: true,
};

export function ParentSelect({ campaignId }: ParentSelectProps): React.ReactElement {
  const form = useForm();
  const options: Advert[] =
    useAPIRequest<Advert[]>({
      url: `campaign/adverts/`,
      queryParams: {
        campaign: campaignId,
        for_extension: true,
        page_size: '',
      },
      method: 'GET',
    }) || [];

  const {
    input: { onChange: _, ...input },
    meta,
  } = useField<Advert, any>('extension_of', {
    subscription,
    validate: required,
  });

  const onChange = useCallback(
    async (advert: Advert) => {
      if (!advert) {
        return;
      }

      form.initialize({
        extension_of: advert,
        service: advert.service,

        rent_units: advert.rent_units,
        upkeep_rate: null,
        // fimxe,
        estimate_units: advert.estimate_units,

        // rent
        ...rentFields(advert, false),

        ...productionFields(advert, false),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <FormGroup meta={meta} required={true}>
      <Select<any>
        {...advertSelectProps}
        options={options}
        {...input}
        error={meta.invalid && meta.touched}
        onChange={onChange}
      />
    </FormGroup>
  );
}
