import React from 'react';
import { Scene, SceneProps } from 'core/ui';
import { List as Lst, ListProps as LstProps, Alert } from 'ffsdk';

export interface ListProps<T extends object> extends SceneProps, LstProps<T> {
  addUrl?: string;
  children?: React.ReactNode;
}

const empty = <Alert variant="info">Nav rezultātu</Alert>;

export function List<T extends object = {}>(props: ListProps<T>): React.ReactElement {
  const {
    columns,
    children,
    url,
    addUrl,
    actions = [],
    crumbs = [],
    filter,
    defaultFilter,
    tfoot,
    ...sceneProps
  } = props;
  const sceneActions = [...actions];
  if (addUrl) {
    sceneActions.push({
      variant: 'primary',
      to: addUrl,
      children: 'Pievienot',
    });
  }

  if (crumbs.length === 0) {
    crumbs.push({
      title: 'Saraksts',
    });
  }

  return (
    <Scene {...sceneProps} actions={sceneActions} crumbs={crumbs}>
      <Lst columns={columns} url={url} emptyElement={empty} filter={filter} defaultFilter={defaultFilter} tfoot={tfoot}>
        {children}
      </Lst>
    </Scene>
  );
}
