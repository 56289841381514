import React, { useState } from 'react';
import { Invoice } from 'types';
import { Button, Form, DecimalField, FormGroup, Submit, useSubmitHandler, DatePickerField, useAPI } from 'ffsdk';
import { Icon } from 'components';
import { Modal } from 'core/ui';
import { useField } from 'react-final-form';
import { parseNumberOrZero } from 'core/util';
import { PaymentProps } from './types';
import { formatISO } from 'date-fns';
import { useAPIRequest } from 'core';
import { toast } from 'react-toastify';
import { Advance } from './types';

function Reminder({ reminder }: { reminder: string }): React.ReactElement {
  const {
    input: { value: amount },
  } = useField('amount', { subscription: { value: true } });
  const diff = parseNumberOrZero(reminder).minus(parseNumberOrZero(amount)).toFixed(2);
  return (
    <FormGroup label="Atlikums" className="col-3">
      <div className="form-control-plaintext font-weight-bold">{diff}</div>
    </FormGroup>
  );
}

function Advances({
  invoice,
  onSuccess,
}: {
  invoice: Invoice;
  onSuccess: (i: Invoice) => void;
}): React.ReactElement | null {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { id, modified_at, type, checkout_date, reminder: invoiceReminder } = invoice;
  const data = useAPIRequest<Advance[]>({ url: `invoicing/invoices/${id}/payment/`, method: 'GET' }, id, modified_at);
  const api = useAPI();

  if (type !== 'INVOICE') {
    return null;
  }
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <div>
      <h5 className="border-bottom pb-2 mb-3">Avansi</h5>
      <div className="form-row">
        <div className="col-3">Rēķina Nr.</div>
        <div className="col-3">Summa</div>
        <div className="col-3">Atlikums</div>
        <div className="col-3" />
      </div>
      {data.map(({ id: advanceId, number, sum_total, reminder }) => (
        <div className="form-row" key={advanceId}>
          <div className="col-3">
            <div className="form-control-plaintext">{number}</div>
          </div>
          <div className="col-3">
            <div className="form-control-plaintext">{sum_total}</div>
          </div>
          <div className="col-3">
            <div className="form-control-plaintext">{reminder}</div>
          </div>
          <div className="col-3">
            <Button
              className="btn-block"
              disabled={submitting}
              onClick={async () => {
                setSubmitting(true);
                const res = await api.post<Invoice, unknown>(`invoicing/invoices/${id}/payment/`, {
                  // @ts-ignore
                  amount: Math.min(parseNumberOrZero(reminder), parseNumberOrZero(invoiceReminder)).toFixed(2),
                  date: checkout_date,
                  reference: advanceId,
                });
                if (res.ok) {
                  onSuccess(res.data);
                } else {
                  throw res.error;
                }
              }}
            >
              Pievienot
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}

export function AddControl(props: PaymentProps): React.ReactElement {
  const {
    invoice: { id, reminder, type },
    setInvoice,
  } = props;
  const [open, setOpen] = useState<boolean>(false);

  const onSuccess = (item: Invoice): void => {
    toast.success('Apmaksa pievienota');
    setInvoice(item);
    setOpen(false);
  };
  const onSubmit = useSubmitHandler(
    {
      url: `invoicing/invoices/${id}/payment/`,
      method: 'POST',
    },
    {
      onSuccess,
    },
  );

  return (
    <>
      <Modal isOpen={open} ariaHideApp={false} onRequestClose={() => setOpen(false)}>
        <div className="card">
          <div className="card-header">Pievienot apmaksu</div>
          <div className="card-body">
            <Form
              onSubmit={onSubmit}
              initialValues={{
                amount: reminder,
                date: formatISO(new Date(), { representation: 'date' }),
                reference: null,
              }}
            >
              <div className="form-row">
                <DatePickerField label="Datums" name="date" className="col-3" />
                <DecimalField label="Summa" name="amount" className="col-3" />
                <Reminder reminder={reminder} />
                <div className="col-3 d-flex align-items-end pb-3">
                  <Submit className="btn-block">Pievienot</Submit>
                </div>
              </div>
            </Form>
            {type === 'INVOICE' && <Advances invoice={props.invoice} onSuccess={onSuccess} />}
          </div>
        </div>
      </Modal>
      <Button size="sm" className="ml-2 py-0" variant="outline-primary" onClick={() => setOpen(true)}>
        <Icon icon="coins" />
      </Button>
    </>
  );
}
