import React from 'react';
import { PaymentStateBadge } from '../badge';
import { PaymentProps } from './types';
import { AddControl } from './AddControl';

export function Payment(props: PaymentProps): React.ReactElement | null {
  const { invoice } = props;

  if (!invoice.payment_state) {
    return null;
  }

  const { payment_state, state, reminder } = invoice;

  const addControl = state === 'CONFIRMED' && reminder !== '0.00';
  return (
    <>
      <PaymentStateBadge state={payment_state} />
      {addControl && <AddControl {...props} />}
    </>
  );
}
