import React from 'react';
import { Route, Routes, Navigate, useParams, useNavigate } from 'react-router-dom';
import { Idle } from 'types';

import parseISO from 'date-fns/parseISO';
import lightFormat from 'date-fns/lightFormat';
import { ActionBar } from 'core/ui';
import { Table, Column, DatePicker, LoadingContainer } from 'ffsdk';
import { Icon, AdvertTitle } from 'components';

import { ImportAction } from './import';
import { useAPIRequest } from 'core';

const COLUMNS: Column<Idle>[] = [
  {
    content: 'Borta numurs',
    key: 'vehicle.number',
    width: 150,
  },
  {
    content: 'Modelis',
    key: 'vehicle',
    render: ({
      vehicle: {
        model: { title, brand },
      },
    }) => {
      return `${title} ${brand}`;
    },
  },
  {
    content: 'Eksponēšana',
    className: 'py-0',
    key: 'exposure',
    render: ({ exposure }) => {
      if (exposure) {
        return <AdvertTitle advert={exposure.advert} />;
      }
      return (
        <>
          <Icon icon="exclamation-circle" color="#dc3545" /> Missing exposure
        </>
      );
    },
  },
];

const IdleTable: React.FC<{ date: string }> = ({ date }) => {
  const idles = useAPIRequest<Idle[]>(
    {
      url: `/api/v1/transport/idle/${date}/`,
      method: 'GET',
    },
    date,
  );

  return (
    <LoadingContainer loading={!idles}>
      <Table columns={COLUMNS} data={idles || []} />
    </LoadingContainer>
  );
};

function DayIdle(): React.ReactElement {
  const navigate = useNavigate();
  const { date: rawDate } = useParams();

  const date = parseISO(rawDate);
  const onSuccess = (d: string): void => navigate(`${lightFormat(parseISO(d), 'yyyy-MM-dd')}`);
  const onDayChange = (d: Date | undefined): void => {
    if (d) {
      navigate(`${lightFormat(d, 'yyyy-MM-dd')}`);
    }
  };
  return (
    <>
      <ActionBar crumbs={[{ title: 'Dīkstāves' }]} actions={[<ImportAction key="import" onSuccess={onSuccess} />]} />
      <div className="container">
        <div className="form-inline mb-3">
          <DatePicker label="Datums" name="day" size="sm" omitGroup selected={date} onChange={onDayChange} />
        </div>
        <IdleTable date={rawDate} />
      </div>
    </>
  );
}

const today = new Date();

export function Idles(): React.ReactElement {
  return (
    <Routes>
      <Route path={`:date`} element={<DayIdle />} />
      <Route path="*" element={<Navigate to={`${lightFormat(today, 'yyyy-MM-dd')}`} />} />
    </Routes>
  );
}
