import React, { useCallback } from 'react';
import { ActionsContainer } from 'core/ui';

import { PrintJob, PrintJobState } from 'types';
import { StateBadge } from './badge';
import { formatDateTime } from 'core/util';

import { RejectPrintJobAction, PrintJobAction, JobAction } from './actions';
import { Column, useList } from 'ffsdk';
import { AdvertTitle } from 'components';

const CellContainer: React.FC<{
  meta: React.ReactNode;
  advertId: number;
}> = ({ meta, children, advertId }) => (
  <div className="d-flex print-actions" data-advert-id={advertId}>
    <div>{meta}</div>
    <div className="ml-auto">{children}</div>
  </div>
);

const ACTION_MAP: Partial<Record<
  PrintJobState,
  {
    action: JobAction;
    title: string;
  }
>> = {
  RECEIVED: {
    action: 'approve',
    title: 'Apstiprināt',
  },
  APPROVED: {
    action: 'pass_for_print',
    title: 'Nodot drukai',
  },
  PASSED_FOR_PRINT: {
    action: 'mark_done',
    title: 'Atzīmēt pabeigtu',
  },
};

const ActionCell: React.FC<{ printJob: PrintJob }> = ({ printJob }) => {
  const { setState } = useList<PrintJob>();
  const toggleLoadingState = useCallback(() => {
    setState(({ loading, ...rest }) => ({ loading: !loading, ...rest }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateItem = useCallback((item: PrintJob) => {
    setState(({ data, ...state }) => ({
      data: data.map((i) => (i.id === item.id ? item : i)),
      ...state,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (printJob.state === 'REJECTED') {
    return (
      <CellContainer meta="Noraidīts:" advertId={printJob.advert.id}>
        <strong>{printJob.rejection_reason}</strong>
      </CellContainer>
    );
  } else if (printJob.state === 'DONE') {
    return <CellContainer meta={printJob.file} advertId={printJob.advert.id} />;
  } else {
    return (
      <CellContainer meta={printJob.file} advertId={printJob.advert.id}>
        <ActionsContainer>
          {printJob.state === 'RECEIVED' && <RejectPrintJobAction printJobId={printJob.id} updateItem={updateItem} />}
          <PrintJobAction
            printJobId={printJob.id}
            updateItem={updateItem}
            toggleLoadingState={toggleLoadingState}
            {...(ACTION_MAP[printJob.state] as {
              action: JobAction;
              title: string;
            })}
          />
        </ActionsContainer>
      </CellContainer>
    );
  }
};

export const columns: Column<PrintJob>[] = [
  {
    content: 'Laiks',
    key: 'modified_at',
    render: ({ modified_at }) => formatDateTime(modified_at),
    width: '120px',
  },
  { content: 'Stāvoklis', width: '110px', key: 'state', render: ({ state }) => <StateBadge state={state} /> },
  {
    content: 'Reklāma',
    key: 'advert.id',
    className: 'py-0',
    render: ({ advert }) => <AdvertTitle key={advert.id} advert={advert} />,
  },
  {
    content: 'Darbība',
    key: 'id',
    render: (printJob) => <ActionCell printJob={printJob} />,
    headerClassName: 'text-right',
  },
];
