import React from 'react';
import { TextField, IntField, SelectField } from 'ffsdk';
import { themeOptions, laguageOptions } from './const';
import { UserSelectField, ContactSelectField } from 'components';

export function Form({ disabled = false }: { disabled?: boolean }): React.ReactElement {
  return (
    <div className="row">
      <div className="col-md-6">
        <TextField name="title" label="Nosaukums" required />
        <ContactSelectField
          name="enterprise"
          label="Slēdzējiestāde"
          required
          filter={{ is_enterprise: true }}
          defaultOptions
          disabled={disabled}
        />
        <ContactSelectField name="client" label="Klients" required filter={{ is_client: true }} disabled={disabled} />
        <UserSelectField name="managers" label="Proj. vadītājs" required isMulti disabled={disabled} />
      </div>
      <div className="col-md-6">
        <div className="form-row">
          <IntField name="vat_rate" label="PVN likme" required className="col" disabled={disabled} />
          <SelectField
            name="theme"
            label="Tēma"
            options={themeOptions}
            simple
            required
            className="col"
            disabled={disabled}
          />
        </div>
        <SelectField name="language" label="Valoda" options={laguageOptions} simple required disabled={disabled} />
      </div>
    </div>
  );
}
