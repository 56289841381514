import React from 'react';
import { StateBadge } from 'components';
// @TODO DRY
export type Variant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';

interface BadgeProps<T> {
  state: T;
  onlyDot?: boolean;
}
export function badgeFactory<StateType extends string>(
  variantMap: { [k in StateType]: Variant },
  labelMap: { [k in StateType]: React.ReactNode } | null,
  label?: string,
): React.FC<BadgeProps<StateType>> {
  function Badge({ state, onlyDot = false }: BadgeProps<StateType>): React.ReactElement {
    return (
      <StateBadge variant={variantMap[state]} label={label} state={state}>
        {onlyDot ? null : labelMap ? labelMap[state] : state}
      </StateBadge>
    );
  }
  return Badge;
}
