import React from 'react';
import { List } from 'core/route';
import { columns } from './columns';
import { PrintJob } from 'types';

// This is here because we are supposed to have jobs of diffrent type some day

export function Work(): React.ReactElement {
  return <List<PrintJob> url={'work/print_jobs/'} columns={columns} />;
}
