import React from 'react'; // { Suspense }
import { PartialRouteObject, Navigate } from 'react-router';
import { Module } from 'components';

import { Dashboard } from './Dashboard';
import { route as campaingRoute } from './campaigns';
import { Work } from './Work';
import { route as crmRoute } from './crm';
import { route as tranportRoute } from './transport';
import { route as reportRoute } from './report';
import { Services } from './advert/Services';
import { Users, Groups } from './security';
import { ReservationGroups } from './reservation';

export const routes: PartialRouteObject[] = [
  {
    path: '/*',
    element: (
      <Module
        menu={[
          { to: '/', end: true, children: 'Darba virsma', icon: 'tachometer' },
          { to: 'report', children: 'Atskaites', icon: 'chart-bar' },
          { to: 'campaigns', children: 'Kampaņas', icon: 'project-diagram' },
          { to: 'work', children: 'Uzdevumi', icon: 'tasks' },
          { to: 'crm', children: 'Kontakti', icon: 'user-friends' },
          { to: 'transport', children: 'Transports', icon: 'bus' },
        ]}
        altMenu={[
          { to: 'settings', children: 'Uzstādījumi', icon: 'cogs' },
          { to: 'logout', children: 'Iziet', icon: 'sign-out' },
        ]}
      />
    ),
    children: [
      { path: '/', element: <Dashboard /> },
      reportRoute,
      campaingRoute,
      { path: 'work', element: <Work /> },
      crmRoute,
      tranportRoute,
      {
        path: 'settings',
        element: (
          <Module
            menu={[
              { to: 'services', children: 'Pakalpojumi' },
              { to: 'reservation/reservation_groups', children: 'Rezervāciju grupas' },
              { to: `security/users`, children: 'Lietotāji' },
              { to: `security/groups`, children: 'Grupas' },
            ]}
          />
        ),
        children: [
          { path: 'services/*', element: <Services /> },
          { path: 'reservation/reservation_groups/*', element: <ReservationGroups /> },
          { path: 'security/users/*', element: <Users /> },
          { path: 'security/groups/*', element: <Groups /> },

          { path: '*', element: <Navigate to="services" /> },
        ],
      },
    ],
  },
];

export * from './Login';
export * from './Logout';
