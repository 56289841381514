import React from 'react';

import { ServiceGroupsProps } from './types';
import { groupColors } from './const';

export function ServiceGroups({ serviceOptions, service }: ServiceGroupsProps): React.ReactElement {
  const selectedServices = service ? serviceOptions.filter((so) => so.service_group === service.service_group) : [];
  return (
    <div className="list-group mt-3">
      {selectedServices.map(({ id, title, fleet }, idx) => {
        return (
          <div
            className="list-group-item text-light  d-flex justify-content-between align-items-center"
            key={idx}
            style={{
              backgroundColor: groupColors(id.toString()),
            }}
          >
            {title}
            <span className="badge badge-primary badge-pill">{fleet}</span>
          </div>
        );
      })}
    </div>
  );
}
