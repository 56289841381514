import React from 'react';
import { Module } from 'components';
import { Navigate } from 'react-router-dom';
import { Campaigns } from './Campaigns';
import { Adverts } from './Adverts';
import { Invoices } from '../invoicing/Invoices';

export const route = {
  path: 'campaigns/*',
  element: (
    <Module
      menu={[
        { to: 'campaigns', children: 'Kampaņas' },
        { to: 'adverts', children: 'Reklāmas' },
        { to: 'invoices', children: 'Rēķini' },
      ]}
    />
  ),
  children: [
    { path: 'campaigns/*', element: <Campaigns /> },
    { path: 'adverts/*', element: <Adverts /> },
    { path: 'invoices/*', element: <Invoices /> },
    { path: '*', element: <Navigate to="campaigns/" /> },
  ],
};
