import React, { createContext, useContext, useState, useEffect } from 'react';
import invariant from 'tiny-invariant';
import { useAPI, Spinner } from 'ffsdk';
import { User } from './types';

interface Session {
  user: User | null;
  setUser: (user: User | null) => void;
  hasPerm(perm: string): boolean;
}

const SessionContext = createContext<Session | undefined>(undefined);

export function useSession(): Session {
  const c = useContext(SessionContext);
  invariant(c, 'Session missing');
  return c;
}

export function Session({ children }: { children: React.ReactNode }): React.ReactElement {
  const api = useAPI();
  const [, setState] = useState();
  const [user, setUser] = useState<User | undefined | null>(undefined);

  // restore session
  useEffect(() => {
    api.get<User, unknown>('me/').then((res) => {
      if (res.ok) {
        setUser(res.data);
        // No session
      } else if (res.status === 403 || res.status === 401) {
        setUser(null);
      } else {
        setState(() => {
          throw res.error;
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (typeof user === 'undefined') {
    return (
      <div className="text-center py-5">
        <Spinner />
      </div>
    );
  }

  const hasPerm = (perm: string): boolean => {
    return user ? user.permissions.includes(perm) : false;
  };

  return <SessionContext.Provider value={{ user, setUser, hasPerm }}>{children}</SessionContext.Provider>;
}

const TOKEN_NAME = 'pl_token';

export const persistToken = (token: string | null): void => {
  if (token === null) {
    localStorage.removeItem(TOKEN_NAME);
  } else {
    localStorage.setItem(TOKEN_NAME, token);
  }
};

export const loadToken = (): string | null => {
  return localStorage.getItem(TOKEN_NAME);
};
