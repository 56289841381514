import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { List, Item, NewAdd, NewChange, Remove, ItemContextType } from 'core/route';
import { columns } from './columns';
import { Campaign, Agreement, agreementTypeLabels } from 'types';
import { StateBadge } from './badge';
import { formatDate } from 'core/util';
import { Crumb, CrumbNode } from 'core/ui';
import { Form } from './Form';
import { EntityActions } from 'components';
import { resolveActions } from './resolveActions';

const initialValues = {
  number: '',
  type: 'AGREEMENT',
  adverts: [],
  invoices: [],
  po_number: '',
  payment_days: 10,
};

const resolveCrumb = ({ item: agreement, title }: ItemContextType<Agreement>): React.ReactElement => (
  <CrumbNode title={title} subTitle={`${agreementTypeLabels[agreement.type]} ${formatDate(agreement.date)}`}>
    <StateBadge state={agreement.state} />
  </CrumbNode>
);

export function CampaignAgreements(props: { campaign: Campaign; campaignCrumb: Crumb }): React.ReactElement {
  const { campaign, campaignCrumb } = props;
  const endpoint = `/api/v1/campaign/campaigns/${campaign.id}/agreements/`;
  return (
    <Routes>
      <Route path="/" element={<List crumbs={[campaignCrumb]} columns={columns} url={endpoint} addUrl="add" />} />
      <Route
        path="add"
        element={
          <NewAdd
            crumbs={[campaignCrumb, { title: 'Izveidot līgumu' }]}
            initialValues={initialValues}
            element={<Form campaign={campaign} agreementId={null} />}
            url={endpoint}
          />
        }
      />
      <Route
        path=":id/*"
        element={
          <Item
            title={(a: Agreement) => a.number}
            endpoint={{
              endpoint: `${endpoint}:id/`,
              method: 'GET',
            }}
          >
            {(itemContext) => {
              const canRemove = itemContext.item.invoices.filter((i) => i.state !== 'PENDING').length === 0;
              const crumbs = [campaignCrumb, resolveCrumb(itemContext)];

              return (
                <Routes>
                  <Route
                    path="/"
                    element={
                      <NewChange<Agreement>
                        crumbs={crumbs}
                        element={<Form campaign={campaign} agreementId={itemContext.item.id} />}
                        itemContext={itemContext}
                        url={`${endpoint}${itemContext.item.id}/`}
                        extraActions={<EntityActions resolve={resolveActions} />}
                      />
                    }
                  />
                  {canRemove && (
                    <Route
                      path="remove"
                      element={
                        <Remove
                          crumbs={crumbs}
                          endpoint={{
                            endpoint: `${endpoint}${itemContext.item.id}/`,
                            method: 'DELETE',
                          }}
                          successUrl="../../"
                          backUrl="../"
                          itemTitle={itemContext.title}
                        />
                      }
                    />
                  )}
                </Routes>
              );
            }}
          </Item>
        }
      />
    </Routes>
  );
}
