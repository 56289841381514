import React from 'react';
import cx from 'classnames';

import { Position, PositionTotals } from 'types';
import { Button, useIsDisabled, TextField, DecimalField, CheckField } from 'ffsdk';

import { Icon } from 'components';
import { useFieldArray } from 'react-final-form-arrays';
import { additionalConst } from '../common';
import { required } from 'core/fields/validate';

function PositionRow({
  position,
  name,
  totals,
}: {
  name: string;
  position: Position;
  totals?: PositionTotals;
}): React.ReactElement {
  const { title, price, area, price_unit, optional, enabled } = position;
  const label = `${title}${area ? ` (${area}m²)` : ''}`;

  let content: React.ReactNode = '—';

  if (totals) {
    const { unit_price, sum, units } = totals;
    switch (price_unit) {
      case 'FIXED':
        content = sum;
        break;
      case 'UNIT':
        content = (
          <>
            {totals.sum}{' '}
            <small>
              = {unit_price} ∗ {units} units
            </small>
          </>
        );

        break;
      case 'AREA':
        content = (
          <>
            {totals.sum}{' '}
            <small>
              = ({area}m² * {price}) ∗ {units} units
            </small>
          </>
        );
    }
  }

  return (
    <tr className={cx({ 'text-line-through text-muted': !enabled })}>
      <th>{label}</th>
      <td>
        <div className="d-flex">
          <div className="flex-grow-1">
            <div className="form-control-plaintext">{content}</div>
          </div>
          {optional && (
            <CheckField
              fieldLabel="Pielietot"
              name={`${name}.enabled`}
              variant="switch"
              className="ml-auto align-self-center mr-2"
            />
          )}
          <div className="th text-right">Atl.%</div>
          <DecimalField name={`${name}.discount`} className="discount-field" />
        </div>
      </td>
    </tr>
  );
}

function AdditionalCostRow({ name, remove }: { name: string; remove?: () => void }): React.ReactElement {
  return (
    <tr>
      <th className="px-0">
        <TextField name={`${name}.title`} required validate={required} placeholder="Pozīcija" />
      </th>
      <td>
        <div className="d-flex">
          <DecimalField name={`${name}.price`} required validate={required} placeholder="Cena" className="" />

          {remove && (
            <Button onClick={() => remove()} size="sm" className="remove-btn border-0 ml-1" variant="outline-danger">
              <Icon icon="times" />
            </Button>
          )}

          <div className="th flex-grow-1 text-right">Atl.%</div>
          <DecimalField name={`${name}.discount`} className="discount-field" />
        </div>
      </td>
    </tr>
  );
}

export function PositionFields({ totals }: { totals?: PositionTotals[] }): React.ReactElement {
  const { fields } = useFieldArray('positions', { subscription: { value: true } });
  const disabled = useIsDisabled();

  return (
    <div className="mb-4">
      <table className="table table-bordered table-form">
        <tbody>
          {fields.map((name, index: number) => {
            const position = fields.value[index];
            return position.type === 'ADDITIONAL_COST' ? (
              <AdditionalCostRow
                name={name}
                remove={
                  !disabled
                    ? () => {
                        fields.remove(index);
                      }
                    : undefined
                }
                key={index}
              />
            ) : (
              <PositionRow name={name} key={index} position={position} totals={totals ? totals[index] : undefined} />
            );
          })}
        </tbody>
      </table>
      <Button onClick={() => fields.push(additionalConst)} size="sm" disabled={disabled}>
        <Icon icon="plus" /> Papildus pozīcija
      </Button>
    </div>
  );
}
