import React from 'react';
import { useAPIRequest } from 'core';
import { LoadingContainer, Alert } from 'ffsdk';
import { ListAgreement } from 'types';
import { Link } from 'react-router-dom';
import { formatDate } from 'core/util';

export function ConfirmedAgreements(): React.ReactElement {
  const agreements = useAPIRequest<ListAgreement[]>({
    url: 'campaign/agreements/',
    method: 'GET',
    queryParams: {
      page_size: '',
      state: 'CONFIRMED',
    },
  });

  const rows = agreements || [];
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Nesaņemtie līgumi</h5>
        <LoadingContainer loading={!agreements}>
          {rows.length > 0 ? (
            <table className="table table-sm mb-0">
              <thead>
                <tr>
                  <th>Numurs</th>
                  <th>Kampaņa</th>
                  <th className="text-right">Datums</th>
                </tr>
              </thead>
              <tbody>
                {rows.map(({ id, number, campaign, date }) => (
                  <tr key={id}>
                    <td>
                      <Link to={`/campaigns/campaigns/${campaign.id}/agreements/${id}`}>{number}</Link>
                    </td>
                    <td>
                      <Link to={`/campaigns/campaigns/${campaign.id}/`}>{campaign.title}</Link>
                    </td>
                    <td className="text-right">{formatDate(date)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Alert variant="success" className="mb-0">
              Nav nesaņemtu līgumu
            </Alert>
          )}
        </LoadingContainer>
      </div>
    </div>
  );
}
