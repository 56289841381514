import React from 'react';
import { ApproachingInvoices } from './ApproachingInvoices';
import { ApproachingExpositions } from './ApproachingExpositions';
import { ApproachingNeutralisation } from './ApproachingNeutralisation';
import { LateInvoices } from './LateInvoices';
import { ConfirmedAgreements } from './ConfirmedAgreements';
import { RejectedPrintJobs } from './RejectedPrintJobs';
import './Dashboard.scss';

export function Dashboard(): React.ReactElement {
  return (
    <div className="container">
      <div className="main py-3">
        <h4>Darba virsma</h4>
        <div className="dashboard-columns">
          <ApproachingInvoices />
          <ApproachingExpositions />
          <ApproachingNeutralisation />
          <LateInvoices />
          <ConfirmedAgreements />
          <RejectedPrintJobs />
        </div>
      </div>
    </div>
  );
}
