import React from 'react';
import { TextField, SelectField, DecimalField, Button } from 'ffsdk';
import { Icon } from 'components';

import { surfaceTypeOptions } from '../const';
import { useFieldArray } from 'react-final-form-arrays';

const initialSurface = {
  title: '',
  area: '',
  type: 'NORMAL',
};

export function Surfaces(): React.ReactElement {
  const { fields } = useFieldArray('surfaces');
  return (
    <fieldset className="border p-3 my-4">
      <legend className="w-auto pr-3  mb-0">Virsmas</legend>
      {fields.map((name, index) => (
        <div className="d-flex flex-row" key={name}>
          <div className="flex-grow-1 form-row">
            <TextField name={`${name}.title`} label="Nosaukums" className="col" required />
            <DecimalField precision={4} name={`${name}.area`} label="Laukums" className="col" required />
            <SelectField
              name={`${name}.type`}
              label="Tips"
              simple
              options={surfaceTypeOptions}
              className="col"
              required
            />
          </div>
          <div className="ml-3 pt-4">
            <Button
              data-test-id="remove-inline"
              variant="outline-danger"
              type="button"
              onClick={() => fields.remove(index)}
              className="border-0 mt-2"
              size="sm"
            >
              <Icon icon="times" />
            </Button>
          </div>
        </div>
      ))}
      <Button
        type="button"
        variant="outline-success"
        size="sm"
        data-test-id="add-inline"
        onClick={() => {
          fields.push(initialSurface);
        }}
      >
        Pievienot
      </Button>
    </fieldset>
  );
}
