import { parseISO, addDays } from 'date-fns';
import * as d3 from 'd3';
import invariant from 'tiny-invariant';
import { groupColors } from './const';
import { Advert } from './types';
import { formatPeriod } from 'core/util';

const margin = { top: 20, right: 30, bottom: 20, left: 50 };

type Row = Advert & {
  dateFrom: Date;
  dateTo: Date;
};

export function renderGraph(c: HTMLElement, rawData: Advert[], containerWidth: number, fleet: number): void {
  const width = containerWidth - margin.left - margin.right;
  const height = fleet * 10 - margin.top - margin.bottom;

  let minDate: Date | undefined;
  let maxDate: Date | undefined;

  const data: Row[] = rawData.map(({ date_from, date_to, depth, rent_units, ...rest }) => {
    const dFrom = parseISO(date_from);
    const dTo = addDays(parseISO(date_to), 1);

    if (!minDate || dFrom < minDate) {
      minDate = dFrom;
    }
    if (!maxDate || dTo > maxDate) {
      maxDate = dTo;
    }

    // maxHeight = Math.max(maxHeight, depth + rent_units);
    return {
      date_from,
      date_to,
      dateFrom: dFrom,
      dateTo: dTo,
      depth,
      rent_units,
      ...rest,
    };
  });

  invariant(minDate && maxDate);

  const x = d3.scaleTime().domain([minDate, maxDate]).range([0, width]);

  const y = d3.scaleLinear().domain([fleet, 0]).range([height, 0]);

  // Clean up after previous render
  d3.select(c).select('svg').remove();

  // Define the div for the tooltip
  const tooltip = d3.select('#reservation-tooltip').style('opacity', 0);

  const svg = d3
    .select(c)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom);

  const container = svg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
  container
    .append('g')
    .attr('transform', 'translate(0,' + height + ')')
    .call(d3.axisBottom(x));
  container.append('g').call(d3.axisLeft(y));

  // add the X gridlines
  container
    .append('g')
    .attr('class', 'grid')
    .attr('transform', 'translate(0,' + height + ')')
    .call(d3.axisBottom(x).ticks(d3.timeDay.every(1)).tickSize(-height))
    .call((g) => g.selectAll('.tick text').remove());

  container
    .selectAll('rect')
    .data(data)
    .enter()
    .append('rect')
    .attr('class', 'advert')
    .attr('x', ({ dateFrom }) => x(dateFrom))
    .attr('y', ({ depth }) => y(depth))
    .attr('width', ({ dateFrom, dateTo }) => {
      return x(dateTo) - x(dateFrom);
    })
    .attr('height', ({ rent_units }) => y(rent_units))
    .attr('fill', ({ service_id }) => groupColors(service_id.toString()))
    .style('stroke', ({ service_id }) => groupColors(service_id.toString()))
    .on('click', ({ id, campaign_id }) => {
      window.location.href = `/campaigns/campaigns/${campaign_id}/adverts/${id}`;
    })
    .on('mouseover', function ({ campaing_title, client_title, date_from, date_to, rent_units, state }) {
      tooltip.transition().duration(200).style('opacity', 1);
      tooltip
        .html(
          `<div class="popover">
            <h3 class="popover-header">${formatPeriod({ date_from, date_to })}</h3>
            <div class="popover-body">
                <dl>
                    <dt>Kampaņa</dt>
                    <dd>${campaing_title}</dd>
                    <dt>Klients</dt>
                    <dd>${client_title}</dd>
                    <dt>Vienības</dt>
                    <dd>${rent_units}</dd>
                    <dt>Stāvoklis</dt>
                    <dd>${state}</dd>
                </dl>
            </div>
          </div>
        `,
        )
        .style('left', d3.event.layerX + 30 + 'px')
        .style('top', d3.event.layerY - 28 + 'px');
    })
    .on('mouseout', function (d: any) {
      tooltip.transition().duration(500).style('opacity', 0);
    });
}
