import { OptionsType } from 'react-select';
import { useAPI, useDebounce } from 'ffsdk';
import { useCallback, useEffect, useRef, useState } from 'react';

interface OptionLoader<OptionType> {
  (inputValue: string, callback: (options: OptionsType<OptionType>) => void): void;
}
type Page<T> = {
  results: T[];
  count: number;
};

export default function useOptions<T>(url: string, filter?: Record<string, unknown>): OptionLoader<T> {
  const api = useAPI();

  return useDebounce(
    useCallback(
      (inputValue: string, cb: any) => {
        api
          .get<Page<T>, unknown>(url, {
            queryParams: {
              search: inputValue,
              page_size: 10,
              ...filter,
            },
          })
          .then((res) => {
            if (res.ok) {
              cb(res.data.results);
            } else {
              throw res.error;
            }
          });
      },
      [api, url, filter],
    ),
    300,
  );
}

export function useDefaultOptions<T>(url: string, filter?: Record<string, unknown>, ...deps: any[]): T[] {
  const isMounted = useRef<boolean>(true);

  const [result, setResult] = useState<T[]>([]);

  const api = useAPI();

  const loadOptions = useCallback(
    async () => {
      // reset
      if (isMounted.current && result.length !== 0) {
        setResult([]);
      }
      const res = await api.get<Page<T>, unknown>(url, {
        queryParams: {
          page_size: 10,
          ...filter,
        },
      });
      if (isMounted.current) {
        if (res.ok) {
          setResult(res.data.results);
        } else {
          setResult(() => {
            throw res.error;
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );

  useEffect(() => {
    isMounted.current = true;
    loadOptions();
    return () => {
      isMounted.current = false;
    };
  }, [loadOptions]);

  return result;
}
