import React from 'react';
import { useParams, useResolvedPath } from 'react-router-dom';
import { Entity } from '../types';
import { Endpoint } from 'core/api';

import { ItemContextType } from '../types';
import { ItemContext } from './itemContext';
import { mapParamsToEndpoint } from '../mapEndpointToRoute';
import { LoadingContainer, Alert } from 'ffsdk';
import { useLoader } from 'core/useLoader';

export { useItem } from './itemContext';

export interface ItemProps<T extends Entity, Payload = undefined> {
  title: (item: T) => string;
  endpoint: Endpoint<Payload>;
  children: (itemContext: ItemContextType<T>) => React.ReactNode;
}

export function Item<T extends Entity>(props: ItemProps<T>): React.ReactElement {
  const { title: titleResolver, endpoint: rawEndpoint, children } = props;
  const params = useParams();
  // This is hack need to build absolute paths in crumbs
  const currentUrl = useResolvedPath('').pathname;

  const { endpoint: url, method, params: queryParams, payload: body } = mapParamsToEndpoint(rawEndpoint, params);
  const loader = useLoader<T>(
    {
      url,
      method,
      queryParams,
      body,
    },
    url,
  );

  const { item, loading, fetchItem, setItem, setLoading } = loader;

  let content = null;
  if (item) {
    const title = titleResolver(item);
    const itemContext = { item, title, url: currentUrl, setItem, fetchItem, setLoading };
    content = <ItemContext.Provider value={itemContext}>{children(itemContext)}</ItemContext.Provider>;
  } else if (item === null) {
    content = (
      <div className="container">
        <Alert variant="danger">404 Not found</Alert>
      </div>
    );
  } else {
    content = null;
  }

  return <LoadingContainer loading={loading}>{content}</LoadingContainer>;
}
