import { Service, PriceUnit, TimeUnit, Surface, ServicePosition, PriceStep } from '../advert/services';
import { CampaignTheme } from './campaigns';
import { EmbeddedAgreement } from './agreements';
import { EmbeddedPrintJob } from '../work';
import { Option } from 'components/fields/createSelectField';

export type AdvertState = 'OFFER' | 'RESERVED' | 'PLANNING' | 'PRODUCTION' | 'EXPOSURE' | 'CANCELED' | 'DONE';
export const advertStateLabels: Record<AdvertState, string> = {
  OFFER: 'Piedāvājums',
  RESERVED: 'Rezervēts',
  PLANNING: 'Plānošana',
  PRODUCTION: 'Ražošana',
  EXPOSURE: 'Eksponēšana',
  CANCELED: 'Anulēts',
  DONE: 'Pabeigts',
};

export const advertStateOptions: Option<AdvertState>[] = ([
  'OFFER',
  'RESERVED',
  'PLANNING',
  'PRODUCTION',
  'EXPOSURE',
  'CANCELED',
  'DONE',
] as AdvertState[]).map((value) => ({
  value,
  label: advertStateLabels[value],
}));

export interface Position extends Omit<ServicePosition, 'price_steps'> {
  price_steps: Omit<PriceStep, 'id'>[];
  enabled: boolean;
  discount: string | null; // Decimal
}

export interface Rent {
  title: string;
  price_unit: PriceUnit;
  price: string;
  time_unit: TimeUnit | null;
  surfaces: Omit<Surface, 'id'>[];
  theme: CampaignTheme;
  tax: string | null;
  discount: string | null; // Decimal
  price_political: string | null;
}

interface Common extends Record<string, unknown> {
  id: number;
  has_production: boolean;
  has_rent: boolean;
  number: string;
  state: AdvertState;
  agreement: EmbeddedAgreement | null;
  rent_units: number;
  date_from: string;
  date_to: string;
  extension_of: EmbeddedAdvert | null;
}

export interface EmbeddedAdvert extends Common {
  campaign: {
    id: number;
    title: string;
    client: {
      id: number;
      title: string;
    };
    enterprise: {
      id: number;
      title: string;
    };
  };
  service: {
    id: number;
    title: string;
  };
  sum_total: string;
  first_payment?: string;
  rent: Rent | null;
}

export interface Advert extends Common {
  modified_at: string;

  campaign: number;
  service: Service;
  production_units: number;

  reserved_at: string | null;

  // planning
  sketch_received_at: string | null;
  sketch_confirmed_at: string | null;
  fleet_confirmed_at: string | null;

  // production
  print_jobs: EmbeddedPrintJob[];

  rent: Rent | null;
  positions: Position[];

  totals?: Totals;
}

export interface PositionTotals {
  title: string;
  price: string;
  unit_price: string;
  units: number;
  sum: string;
  discount: string | null;
  sum_discount: string;
}

export interface Totals {
  rent: PositionTotals;
  positions: PositionTotals[];
  discounts: Array<{
    title: string;
    rate: string;
    sum: string;
  }>;
  upkeep_rate?: number;
  sum_raw: string;
  sum_discount?: string;
  sum_tax?: string;
  sum_subtotal: string;
  sum_vat: string;
  sum_total: string;
}

export interface Allocation {
  neutralised: number;
  live: number;
  reserved: number;
  deficit: number;
  extended: number;
}

export interface Conflict {
  advert: EmbeddedAdvert;
  live: number;
  reserved: number;
}

export interface AllocationResult {
  allocation: Allocation;
  conflicts: Conflict[];
}
