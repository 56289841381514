import { Rent, Position, Service, ServicePosition } from 'types';
import { CITY_TAX_MAP } from './RentFields';

export const additionalConst: Omit<Position, 'id'> = {
  title: '',
  price: '',
  price_unit: 'FIXED',
  area: null,
  is_range: false,
  price_steps: [],
  type: 'ADDITIONAL_COST',
  optional: false,
  enabled: true,
  discount: null,
};

export function createRent(
  { title, price, price_political, price_unit, time_unit, surfaces, is_taxable, city: { name } }: Service,
  discount: string | null,
): Omit<Rent, 'theme'> {
  return {
    title,
    price,
    price_unit,
    time_unit,
    surfaces: [...surfaces],
    tax: is_taxable ? CITY_TAX_MAP[name] || null : null,
    discount,
    price_political,
  };
}

export function createPosition(
  { title, price, price_unit, area, is_range, price_steps, type, optional }: ServicePosition,
  discount: string | null,
): Omit<Position, 'id'> {
  return {
    title,
    price,
    price_unit,
    area,
    is_range,
    price_steps,
    type,
    optional,
    enabled: true,
    discount,
  };
}
