import React from 'react';
import { AdvertState, Advert, EmbeddedAdvert, advertStateOptions } from 'types';
import { createSelectField, Option } from './createSelectField';
import { formatPeriod } from 'core/util';

export const AdvertStateSelectField = createSelectField<Option<AdvertState>>(advertStateOptions, { simple: true });

export const advertSelectProps = {
  getOptionLabel: (advert: Advert | EmbeddedAdvert): string =>
    `${advert.rent_units} × ${advert.service.title}  ${formatPeriod(advert)} `,
  getOptionValue: ({ id }: Advert | EmbeddedAdvert): string => id.toString(),
  formatOptionLabel: (advert: Advert | EmbeddedAdvert) => (
    <div>
      <div>
        {advert.service.title} ({advert.rent_units})
      </div>
      <small className="d-block">{formatPeriod(advert)}</small>
    </div>
  ),
};

export const AdvertSelectField = createSelectField<EmbeddedAdvert>('campaign/adverts/', advertSelectProps);
