import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { List, NewAdd, Item, NewChange, Remove } from 'core/route';

import { Model } from 'types';
import { columns } from './columns';
import { Form } from './Form';
import { ListFilter } from 'core';

const endpoint = `/api/v1/transport/models/`;

const initialValues = {
  type: null,
  title: '',
  brand: '',
  description: '',
  notes: '',
};

export function Models(): React.ReactElement {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <List columns={columns} url={endpoint} addUrl="add">
            <ListFilter />
          </List>
        }
      />
      <Route path="add" element={<NewAdd initialValues={initialValues} element={<Form />} url={endpoint} />} />
      <Route
        path=":id/*"
        element={
          <Item
            title={(model: Model) => model.title}
            endpoint={{
              endpoint: `${endpoint}:id/`,
              method: 'GET',
            }}
          >
            {(itemContext) => (
              <Routes>
                <Route
                  path="/"
                  element={
                    <NewChange
                      removeUrl="remove"
                      element={<Form />}
                      itemContext={itemContext}
                      url={`${endpoint}${itemContext.item.id}/`}
                    />
                  }
                />
                <Route
                  path="remove"
                  element={
                    <Remove
                      endpoint={{
                        endpoint: `${endpoint}${itemContext.item.id}/`,
                        method: 'DELETE',
                      }}
                      successUrl="../../"
                      backUrl="../"
                      itemTitle={itemContext.title}
                    />
                  }
                />
              </Routes>
            )}
          </Item>
        }
      />
    </Routes>
  );
}
