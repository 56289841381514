import React, { useCallback } from 'react';
import { Campaign, Service, Advert } from 'types';
import { AdvertFields } from './AdvertFields';
import { useField, useForm } from 'react-final-form';
import { FormGroup, Select, useIsDisabled } from 'ffsdk';
import useOptions from 'components/fields/useOptions';
import { createRent, createPosition, RentFields, useEstimateTotals, TotalsFields, PositionFields } from '../common';
import { Sidebar } from '../Sidebar';
import { required } from 'core/fields/validate';
import { ItemContextType } from 'core/route';

interface FormProps {
  campaign: Campaign;
  itemContext?: ItemContextType<Advert>;
}

const serviceSelectProps = {
  getOptionLabel: ({ title }: Service) => title,
  getOptionValue: ({ id }: Service) => id.toString(),
  name: 'service',
  isClearable: false,
  required: true,
};
const subscription = {
  value: true,
  invalid: true,
  submitError: true,
  dirtySinceLastSubmit: true,
  error: true,
  dirty: true,
  touched: true,
};

function ServiceSelect({ discount }: { discount: string | null }): React.ReactElement {
  const disabled = useIsDisabled();
  const loadOptions = useOptions<Service>('advert/services/');
  const form = useForm();

  const onChange = useCallback(
    (service: Service) => {
      service &&
        form.initialize({
          ...form.getState().values,
          service,
          rent: createRent(service, discount),
          // ! perhaps we want to keep additional costs around?
          positions: service.positions.map((pos) => createPosition(pos, discount)),
        });
    },
    [form, discount],
  );

  const {
    input: { onChange: _, ...input },
    meta,
  } = useField<Service, any>('service', {
    subscription,
    validate: required,
  });

  return (
    <tr>
      <th>Pakalpojums</th>
      <td>
        <FormGroup meta={meta} required={true}>
          <Select<any>
            loadOptions={loadOptions}
            {...serviceSelectProps}
            {...input}
            disabled={disabled}
            error={meta.invalid && meta.touched}
            onChange={onChange}
          />
        </FormGroup>
      </td>
    </tr>
  );
}

export function AdvertForm({ campaign, itemContext }: FormProps): React.ReactElement {
  const [totals, _] = useEstimateTotals(campaign.id);
  return (
    <div className="row">
      <div className="col-md-6 ">
        <table className="table table-bordered table-form">
          <tbody>
            <ServiceSelect
              discount={campaign.client.client_discount ? campaign.client.client_discount.toString() : null}
            />
            <AdvertFields advert={itemContext?.item} />
          </tbody>
        </table>
        <h4>Noma</h4>
        <RentFields totals={totals?.rent} />
        <h4>Pozīcijas</h4>
        <PositionFields totals={totals?.positions} />
        <h4>Kopā</h4>
        <TotalsFields totals={totals} />
      </div>
      <div className="col-md-6">{itemContext && <Sidebar itemContext={itemContext} />}</div>
    </div>
  );
}
