import React from 'react';
import { TextField, CheckField } from 'ffsdk';
import { coerceNullProps } from 'core/fields';
import { CountrySelectField, CitySelectField } from 'components';
import { Accounts } from './Accounts';
import { ContactFields } from './ContactFields';

export const Form: React.FC = () => (
  <div>
    <div className="row">
      <div className="col-md-6">
        <h4>Pamatinformācija</h4>

        <TextField name="title" label="Nosaukums" required />
        <TextField name="notes" label="Piezīmes" multiline autosize />

        <div className="form-row">
          <CheckField name="is_active" label="Iespējot" variant="switch" className="col" />
          <CheckField name="is_enterprise" label="Slēdzējiestāde" variant="switch" className="col" />
          <CheckField name="is_client" label="Klients" variant="switch" className="col" />
        </div>
        <ContactFields />
      </div>
      <div className="col-md-6">
        <h4>Rekvizīti</h4>
        <TextField name="legal_title" label="Juridiskais nosaukums" {...coerceNullProps} />
        <div className="form-row">
          <TextField name="registration_number" label="Reģistrācijas numurs" {...coerceNullProps} className="col" />
          <TextField name="vat_number" label="PVN numurs" {...coerceNullProps} className="col" />
        </div>
        <TextField name="office_address" label="Biroja adrese" />
        <TextField name="legal_address" label="Juridiskā adrese" />
        <div className="form-row">
          <CountrySelectField name="country" label="Valsts" className="col" />
          <CitySelectField name="city" label="Pilsēta" className="col" />
        </div>
        <div className="form-row">
          <TextField name="phone" label="Tālrunis" className="col" />
          <TextField name="email" label="E-pasts" type="email" className="col" />
          <TextField name="www" label="WWW" className="col" />
        </div>
        <Accounts />
      </div>
    </div>
  </div>
);
