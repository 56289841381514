import React, { useEffect } from 'react';
import { DatePickerField, TextField, IntField, SelectField, DecimalField } from 'ffsdk';
import { useField } from 'react-final-form';
import { Sidebar } from './Sidebar';
import { laguageOptions } from '../../campaigns/Campaigns/const';

import { ContactSelectField, PersonSelectField, InvoiceTypeSelectField } from 'components/fields';

function EnterprisePersonField({ className }: { className?: string }): React.ReactElement {
  const {
    input: { value: enterprise },
  } = useField('enterprise', { subscription: { value: true } });
  const {
    input: { value: person, onChange },
  } = useField('signature_person', { subscription: { value: true } });

  useEffect(
    () => {
      if (person) {
        if (person.entity.type !== 'contact' || person.entity.id !== enterprise?.id) {
          onChange(null);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enterprise, person],
  );

  return (
    <PersonSelectField
      name="signature_person"
      label="Paraksta persona"
      className={className}
      disabled={!enterprise}
      filter={{ contact_id: enterprise?.id }}
    />
  );
}

export function Form({
  hasAgreement = false,
  sidebar,
  lock = false,
}: {
  hasAgreement?: boolean;
  sidebar?: boolean;
  lock?: boolean;
}): React.ReactElement {
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-row">
            <InvoiceTypeSelectField name="type" label="Tips" className="col" required disabled={hasAgreement || lock} />
            <ContactSelectField
              name="enterprise"
              label="Slēdzējiestāde"
              className="col"
              required
              filter={{ is_enterprise: true }}
              defaultOptions
              disabled={hasAgreement || lock}
            />
            <ContactSelectField
              name="client"
              label="Klients"
              className="col"
              required
              filter={{ is_client: true }}
              disabled={hasAgreement || lock}
            />
          </div>
          <div className="form-row">
            <DatePickerField
              name="checkout_date"
              className="col-6"
              label="Izrakstīšanas datums"
              required
              disabled={lock}
              // disabled={hasAgreement}
            />
            <DatePickerField
              name="payment_date"
              className="col-6"
              label="Apmaksas datums"
              required
              disabled={lock}
              // disabled={hasAgreement}
            />
          </div>

          <div className="form-row">
            <EnterprisePersonField className="col" />
            <SelectField name="language" label="Valoda" className="col" options={laguageOptions} simple required />
            <TextField name="po_number" className="col" label="PO numurs" />
          </div>
          <TextField name="motivation" multiline autosize label="Motivācija" />
          <div className="form-row">
            <IntField name="vat_rate" label="PVN likme" className="col" required disabled={hasAgreement || lock} />
            <DecimalField
              name="sum_total"
              label="Kopā ar PVN"
              className="col"
              required
              disabled={hasAgreement || lock}
            />
          </div>
        </div>
        <div className="col-md-6">{sidebar && <Sidebar />}</div>
      </div>
    </div>
  );
}
