import React from 'react';
import styled from '@emotion/styled';
import { NavLink as LinkBase } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon } from 'components';

type Variant = 'tabs' | 'pills';

export const NavContainer = styled.ul<{ variant: Variant }>`
  display: flex;
  flex-wrap: no-wrap;
  padding-left: 0;
  margin-top: 0;
  list-style: none;

  margin-bottom: 1rem;
  ${(props) => props.variant === 'tabs' && 'border-bottom: 1px solid #dee2e6;'}
`;

export const Item = styled.li<{ variant: Variant }>`
  & + & {
    margin-left: 0.25rem;
  }
  min-width 0;
  ${(props) => props.variant === 'tabs' && 'margin-bottom: -1px;'}
`;

export const NavLink = styled(LinkBase)<{ variant: Variant }>`
  display: block;
  padding: 0.75rem 1rem;
  text-decoration: none;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) =>
    props.variant === 'tabs' &&
    `
        border: 1px solid transparent;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        &.active {
            color: #495057;
            background-color: #fff;
            border-color: #dee2e6 #dee2e6 transparent;
        }
        &:hover {
            border-color: #e9ecef #e9ecef #dee2e6;
        }
  `}
`;

export interface NavItemProps {
  icon?: IconProp;
  to: string;
  end?: boolean;
  children?: React.ReactNode;
  target?: string;
}

export interface NavProps {
  variant?: Variant;
  items: NavItemProps[];
}

export const Nav = ({ variant = 'tabs', items }: NavProps): React.ReactElement => (
  <NavContainer variant={variant} className="nav-container">
    {items.map(({ icon, children, ...link }) => (
      <Item variant={variant} key={link.to} className="nav-item">
        <NavLink variant={variant} {...link} className="nav-link">
          {icon && <Icon icon={icon} />} {children}
        </NavLink>
      </Item>
    ))}
  </NavContainer>
);
