import React from 'react';
import cx from 'classnames';
import './AllocationBadge.scss';

interface AllocationBadgeProps {
  label?: React.ReactNode;
  count: number;
  className?: string;
}

export function AllocationBadge({ label, count, className }: AllocationBadgeProps): React.ReactElement | null {
  if (count === 0) {
    return null;
  }
  return (
    <div className={cx('allocation-badge d-flex align-items-center', className)}>
      <div className="pill">{count}</div>
      {label}
    </div>
  );
}
