import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleNotch,
  faInfoCircle,
  faExclamationCircle,
  faCogs,
  faSignOut,
  faTachometer,
  faUserFriends,
  faChartBar,
  faBus,
  faAd,
  faProjectDiagram,
  faTimesCircle,
  faCalendar,
  faTasks,
  faPrint,
  faPaintRoller,
  faTimes,
  faPlus,
  faChevronRight,
  faChevronDown,
  faEdit,
  faCoins,
  faFileExcel,
} from '@fortawesome/pro-solid-svg-icons';
import { faSquare, faCheckSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(
  faEdit,
  faCircleNotch,
  faInfoCircle,
  faExclamationCircle,
  faCogs,
  faSignOut,
  faTachometer,
  faUserFriends,
  faChartBar,
  faBus,
  faAd,
  faProjectDiagram,
  faSquare,
  faCheckSquare,
  faTimesCircle,
  faCalendar,
  faTasks,
  faPrint,
  faPaintRoller,
  faTimes,
  faPlus,
  faChevronRight,
  faChevronDown,
  faCoins,
  faFileExcel,
);

export const Icon = FontAwesomeIcon;
