import React from 'react';
import { TextField } from 'ffsdk';

export function Form(): React.ReactElement {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-row">
          <TextField name="first_name" label="Vārds" required className="col" />
          <TextField name="last_name" label="Uzvārds" required className="col" />
        </div>
        <div className="form-row">
          <TextField name="email" label="E-pasts" type="email" className="col" />
          <TextField name="role" label="Loma" className="col" />
        </div>
        <div className="form-row">
          <TextField name="mobile_phone" label="Mobīlais tālrunis" className="col" />
          <TextField name="work_phone" label="Darba tālrunis" className="col" />
        </div>
        <TextField name="notes" label="Piezīmes" multiline autosize />
      </div>
    </div>
  );
}
