import React from 'react';
import { useField } from 'react-final-form';
import { useSession } from 'core';
import { UserSelectField } from 'components';
import { DecimalField, CheckField } from 'ffsdk';

export function ContactFields(): React.ReactElement | null {
  const {
    input: { value: isClient },
  } = useField('is_client', { subscription: { value: true } });

  const { hasPerm } = useSession();
  const canEditClientDiscount = hasPerm('crm.change_client_discount');

  if (!isClient) {
    return null;
  }

  return (
    <div className="mt-3">
      <h4>Klients</h4>
      <div className="form-row">
        <UserSelectField name="client_manager" label="Manager" className="col" />

        <DecimalField name="client_discount" label="Discount" disabled={!canEditClientDiscount} />
      </div>
      <CheckField name="client_is_agency" label="Is agency" variant="switch" />
    </div>
  );
}
