import React from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { Button, TextField } from 'ffsdk';
import { Icon, BankSelectField } from 'components';

const accountInitialValues = {
  bank: null,
  name: '',
  number: '',
};

export function Accounts(): React.ReactElement {
  const { fields } = useFieldArray('accounts', { subscription: {} });
  return (
    <fieldset className="border p-3 my-4">
      <legend className="w-auto pr-3  mb-0">Konti</legend>
      {fields.map((name, index) => (
        <div className="d-flex flex-row" key={name}>
          <div className="flex-grow-1">
            <div className="form-row">
              <BankSelectField name={`${name}.bank`} label="Banka" className="col" required />
              <TextField name={`${name}.name`} label="Nosaukums" className="col" />
            </div>
            <TextField name={`${name}.number`} label="Numurs" required />
          </div>
          <div className="ml-3 pt-4">
            <Button
              data-test-id="remove-inline"
              variant="outline-danger"
              type="button"
              onClick={() => fields.remove(index)}
              className="border-0 mt-2"
              size="sm"
            >
              <Icon icon="times" />
            </Button>
          </div>
        </div>
      ))}
      <Button
        type="button"
        variant="outline-success"
        size="sm"
        data-test-id="add-inline"
        onClick={() => {
          fields.push(accountInitialValues);
        }}
      >
        Pievienot
      </Button>
    </fieldset>
  );
}
