import React from 'react';
import { Advert } from 'types';
import { ItemContextType } from 'core/route';
import { Planning } from './Planning';
import { Production } from './Production';
import { Exposure } from './Exposure';

interface SidebarProps {
  itemContext?: ItemContextType<Advert>;
}

export function Sidebar(props: SidebarProps): React.ReactElement | null {
  const { itemContext } = props;
  if (!itemContext) {
    return null;
  }
  const advert = itemContext.item;

  return (
    <>
      {(() => {
        switch (advert.state) {
          case 'PLANNING':
            return <Planning itemContext={itemContext} />;
          case 'PRODUCTION':
            return <Production itemContext={itemContext} />;
          default:
            return null;
        }
      })()}
      <Exposure advert={advert} />
    </>
  );
}
