import React, { useEffect } from 'react';

import { useSession, persistToken, User } from 'core';
import { Form, Submit, useSubmitHandler, TextField, useAPI } from 'ffsdk';
import { useNavigate } from 'react-router-dom';

const initialValues = {
  username: '',
  password: '',
};

export function Login(): React.ReactElement {
  const api = useAPI();
  const navigate = useNavigate();
  const { user, setUser } = useSession();

  useEffect(() => {
    if (user) {
      navigate('/logout');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useSubmitHandler<typeof initialValues, { token: string }>(
    {
      url: '/api-token-auth/',
      method: 'POST',
    },
    {
      onSuccess: ({ token }) => {
        persistToken(token);
        api.get<User, unknown>('me/').then((res) => {
          if (res.ok) {
            setUser(res.data);
            navigate('/');
            // No session
          } else {
            persistToken(null);
            throw res.error;
          }
        });
      },
    },
  );

  return (
    <div className="container">
      <div className="row vh-100 justify-content-center align-items-center">
        <div className="col-sm-9 col-md-7 col-lg-5">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Ieiet</h5>
              <Form onSubmit={onSubmit} initialValues={initialValues}>
                <TextField name="username" placeholder="Lietotājvārds" autoComplete="username" />
                <TextField name="password" placeholder="Parole" type="password" autoComplete="current-password" />
                <Submit className="btn-block btn-lg">Iesniegt</Submit>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
