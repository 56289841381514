import React from 'react';

import { TextField, SelectField, DatePickerField, IntField } from 'ffsdk';
import { EmbeddedAdvert, Campaign, agreementTypeOptions } from 'types';
import { AdvertTitle } from 'components';
import { useAPIRequest } from 'core';
import { formatPeriod } from 'core/util';
import { Invoices } from './Invoices';

interface AdvertOption extends EmbeddedAdvert {
  first_payment: string;
}

const formatOptionLabel = ({ first_payment, ...advert }: AdvertOption): React.ReactNode => (
  <AdvertTitle hideCampaign hideLink advert={advert}>
    <div>
      primais maksājums: <strong>€ {first_payment}</strong>
    </div>
  </AdvertTitle>
);

const getOptionLabel = (advert: AdvertOption): string =>
  `${advert.service.title} (${advert.rent_units}) ${formatPeriod(advert)} `;
const getOptionValue = ({ id }: AdvertOption): string => id.toString();

interface FormProps {
  campaign: Campaign;
  agreementId: number | null;
}

export function Form({ campaign, agreementId }: FormProps): React.ReactElement {
  const campaignId = campaign.id;

  // useDefaultOptions ,page_size=100
  const adverts = useAPIRequest<AdvertOption[]>(
    {
      url: `campaign/campaigns/${campaignId}/adverts/`,
      method: 'GET',
      queryParams: {
        for_agreement: agreementId || '',
      },
    },
    campaignId,
    agreementId,
  );

  return (
    <div className="row">
      <div className="col-md-4 pr-0">
        <div className="form-row">
          <SelectField name="type" label="Tips" required simple options={agreementTypeOptions} className="col" />
          <TextField name="number" label="Numurs" required className="col" />
        </div>
        <TextField name="note" multiline label="Piezīme" />
        <div className="form-row">
          <div
            style={{
              width: '120px',
            }}
          >
            <DatePickerField name="date" label="Datums" required />
          </div>
          <TextField name="po_number" label="PO numurs" className="col" />
          <IntField name="payment_days" label="Maks. dienas" required className="col" />
        </div>

        <SelectField
          name="adverts"
          label="Reklāmas"
          isMulti
          required
          isClearable={false}
          options={adverts || []}
          formatOptionLabel={formatOptionLabel}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
        />
      </div>
      <div className="col-md-8">
        <Invoices campaignTitle={campaign.title} />
      </div>
    </div>
  );
}
