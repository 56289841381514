import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { List, NewAdd, Item, NewChange, Remove } from 'core/route';

import { Service } from 'types';
import { columns } from './columns';
import { Form } from './Form';
import { ListFilter } from 'core';
import { EntityActions } from 'components';
import { resolveActions } from './resolveActions';

const endpoint = `/api/v1/advert/services/`;

const initialValues = {
  title: '',
  city: null,
  country: null,
  is_active: true,

  is_taxable: true,
  price: '',
  price_political: null,
  rent_units: false,
  price_unit: 'UNIT',
  time_unit: 'MONTH',

  transport_models: [],
  surfaces: [],
  positions: [],
};

export function Services(): React.ReactElement {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <List<Service> columns={columns} url={endpoint} addUrl="add">
            <ListFilter />
          </List>
        }
      />
      <Route path="add" element={<NewAdd initialValues={initialValues} element={<Form />} url={endpoint} />} />
      <Route
        path=":id/*"
        element={
          <Item
            title={(service: Service) => service.title}
            endpoint={{
              endpoint: `${endpoint}:id/`,
              method: 'GET',
            }}
          >
            {(itemContext) => (
              <Routes>
                <Route
                  path="/"
                  element={
                    <NewChange
                      removeUrl="remove"
                      element={<Form />}
                      itemContext={itemContext}
                      url={`${endpoint}${itemContext.item.id}/`}
                      extraActions={<EntityActions resolve={resolveActions} />}
                    />
                  }
                />
                <Route
                  path="remove"
                  element={
                    <Remove
                      endpoint={{
                        endpoint: `${endpoint}${itemContext.item.id}/`,
                        method: 'DELETE',
                      }}
                      successUrl="../../"
                      backUrl="../"
                      itemTitle={itemContext.title}
                    />
                  }
                />
              </Routes>
            )}
          </Item>
        }
      />
    </Routes>
  );
}
