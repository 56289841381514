import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { List, NewAdd, Item, NewChange, Remove, ItemContextType } from 'core/route';
import { CrumbNode, Nav } from 'core/ui';

import { Contact } from 'types';
import { columns } from './columns';
import { Form } from './Form';

import { NestedPersons } from '../Persons';
import { ListFilter } from 'core';

const endpoint = `/api/v1/crm/contacts/`;

const initialValues = {
  title: '',
  legal_title: null,
  registration_number: null,
  vat_number: null,

  office_address: '',
  legal_address: '',
  country: null,
  city: null,
  phone: '',
  email: '',
  www: '',
  notes: '',

  is_active: true,

  // types
  is_enterprise: false,

  is_client: false,
  client_discount: 0,
  client_is_agency: false,
  client_manager: null,

  accounts: [],
};

const resolveCrumb = ({ title, url }: ItemContextType<Contact>): React.ReactElement => (
  <CrumbNode subTitle="Kontakts" title={title}>
    <Nav
      items={[
        { to: url, end: true, children: 'Kontakts' },
        { to: `${url}/persons`, children: 'Personas' },
      ]}
    />
  </CrumbNode>
);

export function Contacts(): React.ReactElement {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <List<Contact> columns={columns} url={endpoint} addUrl="add">
            <ListFilter />
          </List>
        }
      />
      <Route path="add" element={<NewAdd initialValues={initialValues} element={<Form />} url={endpoint} />} />
      <Route
        path=":id/*"
        element={
          <Item
            title={(contact: Contact) => contact.title}
            endpoint={{
              endpoint: `${endpoint}:id/`,
              method: 'GET',
            }}
          >
            {(itemContext) => {
              const crumb = resolveCrumb(itemContext);
              return (
                <Routes>
                  <Route
                    path="/"
                    element={
                      <NewChange
                        element={<Form />}
                        itemContext={itemContext}
                        url={`${endpoint}${itemContext.item.id}/`}
                        crumbs={[crumb]}
                        removeUrl="remove"
                      />
                    }
                  />
                  <Route
                    path="persons/*"
                    element={
                      <NestedPersons endpoint={`/api/v1/crm/contacts/${itemContext.item.id}/persons/`} crumb={crumb} />
                    }
                  />

                  <Route
                    path="remove"
                    element={
                      <Remove
                        endpoint={{
                          endpoint: `${endpoint}${itemContext.item.id}/`,
                          method: 'DELETE',
                        }}
                        successUrl="../../"
                        backUrl="../"
                        itemTitle={itemContext.title}
                      />
                    }
                  />
                </Routes>
              );
            }}
          </Item>
        }
      />
    </Routes>
  );
}
