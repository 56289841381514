import React from 'react';
import { Icon } from 'components';
import { get } from 'lodash-es';

export * from './pagination';
export * from './actions';
export * from './actionbar';
export * from './nav';
export * from './badge';

export * from './modal';

export * from './containers';
export * from './card';

export function tBool<T>(accessor: string): (v: T) => React.ReactNode {
  return (v: T): React.ReactElement => <Icon icon={['far', get(v, accessor) ? 'check-square' : 'square']} />;
}
