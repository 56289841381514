import React from 'react';
import { useAPIRequest } from 'core';
import { LoadingContainer, Alert } from 'ffsdk';
import { EmbeddedAdvert } from 'types';

import { differenceInDays, parseISO, formatISO, addDays } from 'date-fns';
import { AdvertTitle } from 'components';

const now = new Date();
const deadline = formatISO(addDays(new Date(), 10), { representation: 'date' });

function Card(): React.ReactElement {
  const adverts = useAPIRequest<EmbeddedAdvert[]>({
    url: 'campaign/adverts/',
    method: 'GET',
    queryParams: {
      page_size: '',
      ends_before: deadline,
      ordering: 'date_from',
    },
  });

  const rows = adverts || [];
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Tuvojas reklāmas beigu datums</h5>
        <LoadingContainer loading={!adverts}>
          {rows.length > 0 ? (
            <table className="table table-sm mb-0">
              <thead>
                <tr>
                  <th>Reklāma</th>
                  <th className="text-right">Dienas</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((advert) => (
                  <tr key={advert.id}>
                    <td className="py-0">
                      <AdvertTitle advert={advert} />
                    </td>
                    <td className="text-right align-middle">{differenceInDays(parseISO(advert.date_to), now)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Alert variant="success" className="mb-0">
              Nav reklāmu
            </Alert>
          )}
        </LoadingContainer>
      </div>
    </div>
  );
}
export function ApproachingNeutralisation(): React.ReactElement {
  return <Card />;
}
