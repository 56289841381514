import React from 'react';
import { NavLink } from 'react-router-dom';
import { tBool } from 'core/ui';
import { City } from 'types';
import { Column } from 'ffsdk';

export const columns: Column<City>[] = [
  {
    content: 'Nosaukums',
    key: 'name',
    render: ({ id, name }) => <NavLink to={`/crm/cities/${id}`}>{name}</NavLink>,
  },
  {
    content: 'Valsts',
    key: 'country.name',
  },
  {
    content: 'Galvaspilsēta',
    key: 'is_capital',
    headerClassName: 'text-center',
    className: 'text-center',
    render: tBool('is_capital'),
    width: '90px',
  },
];
