import React from 'react';
import { Advert } from 'types';
import { Reservation } from './Reservation';
import { Estimate } from './Estimate';

interface AllocationProps {
  advert?: Advert;
}

export function Allocation({ advert }: AllocationProps): React.ReactElement | null {
  if (advert?.state === 'CANCELED') {
    return null;
  }
  if (advert && advert.reserved_at) {
    return <Reservation {...advert} />;
  }

  return <Estimate />;
}
