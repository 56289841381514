import React from 'react';
import { ItemContextType } from 'core/route';
import { Advert } from 'types';

import { StateBadge as PrintStateBadge } from 'views/Work/badge';
import { formatDateTime } from 'core/util';

interface ProductionProps {
  itemContext: ItemContextType<Advert>;
}

export function Production({ itemContext: { item } }: ProductionProps): React.ReactElement {
  return (
    <div className="card mb-1">
      <div className="card-header">Ražošana</div>
      <ul className="list-group list-group-flush">
        {item.print_jobs.map(({ id, state, modified_at, file, rejection_reason }) => (
          <li key={id} className="list-group-item">
            <div className="d-flex">
              <PrintStateBadge state={state} />
              <div className="ml-auto">{formatDateTime(modified_at)}</div>
            </div>

            {state === 'RECEIVED' && (
              <div className="pt-3" data-test-id="file-received">
                {file}
              </div>
            )}
            {state === 'REJECTED' && (
              <div className="pt-3" data-test-id="file-rejected">
                {rejection_reason}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
