import React from 'react';
import { useField } from 'react-final-form';
import { required } from 'core/fields/validate';
import { CheckField, IntField, SelectField, DatePickerField } from 'ffsdk';
import { Days } from '../common';
import { Allocation } from '../common/Allocation';
import { Advert } from 'types';

const upkeepOptions = [
  {
    value: null,
    label: 'Dizaina maiņa',
  },
  {
    value: 25,
    label: '25%',
  },
  {
    value: 35,
    label: '35%',
  },
  {
    value: 50,
    label: '50%',
  },
];
function Production(): React.ReactElement | null {
  const {
    input: { value },
  } = useField('has_production', { subscription: { value: true } });

  if (value) {
    return (
      <tr>
        <th>Uzturēšana</th>
        <td>
          <SelectField
            name="upkeep_rate"
            options={upkeepOptions}
            placeholder="Dizaina maiņa"
            simple
            isClearable={false}
          />
        </td>
      </tr>
    );
  }
  return null;
}

export function ExtensionFields({ advert }: { advert?: Advert }): React.ReactElement | null {
  const {
    input: { value: extension_of },
  } = useField('extension_of', { subscription: { value: true } });
  const {
    input: { value: has_rent },
  } = useField('has_rent', { subscription: { value: true, active: true } });

  if (!extension_of) {
    return null;
  }

  return (
    <>
      <tr>
        <th>Veids</th>
        <td>
          <div className="form-row py-2 px-3">
            <CheckField name="has_rent" fieldLabel="Ar nomu" variant="switch" className="col" />
            <CheckField name="has_production" fieldLabel="Ar ražošanu" variant="switch" className="col" />
          </div>
        </td>
      </tr>
      {has_rent ? (
        <tr>
          <th>Periods</th>
          <td>
            <div className="d-flex form-inline">
              <div className="form-row">
                <DatePickerField name="date_from" placeholder="No" required disabled className="col-6 pr-0" />
                <DatePickerField
                  name="date_to"
                  placeholder="Līdz"
                  required
                  validate={required}
                  className="col-6 pl-1"
                />
              </div>
              <Days />
            </div>
          </td>
        </tr>
      ) : null}
      <tr>
        <th>Borta numuru skaits</th>
        <td>
          <IntField name="rent_units" required validate={required} />
        </td>
      </tr>
      {has_rent && (
        <tr>
          <th></th>
          <td>
            <Allocation advert={advert} />
          </td>
        </tr>
      )}
      <Production />
    </>
  );
}
