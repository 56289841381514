import React from 'react';
import { TextField } from 'ffsdk';
import { CountrySelectField } from 'components';

export function Form(): React.ReactElement {
  return (
    <div className="row">
      <div className="col-md-6">
        <TextField name="name" label="Nosaukums" required />
        <TextField name="code" label="Kods" required />
        <CountrySelectField name="country" label="Valsts" required />
      </div>
    </div>
  );
}
