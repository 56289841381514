import React from 'react';
import { AllocationResult } from 'types';
import { Progress, ProgressBar } from 'ffsdk';
import './State.scss';
import { AllocationBadge } from 'components';
import { Conflicts } from './Conflicts';

interface StateProps extends AllocationResult {
  labels?: {
    neutralised: React.ReactNode;
    live: React.ReactNode;
    reserved: React.ReactNode;
    deficit: React.ReactNode;
    overspent: React.ReactNode;
    extended: React.ReactNode;
  };
}

export const defaultLabels = {
  neutralised: 'neitralizēti',
  live: 'aktīvi',
  reserved: 'rezervēti',
  deficit: 'deficīts',
  overspent: 'pārtēriņš',
  extended: 'pagarināti',
};

export function State({
  allocation: { neutralised, live, reserved, extended, deficit: providedDeficit },
  conflicts,
  labels = defaultLabels,
}: StateProps): React.ReactElement | null {
  const overspent = providedDeficit < 0 ? providedDeficit * -1 : 0;
  const deficit = providedDeficit > 0 ? providedDeficit : 0;

  const total = neutralised + live + reserved + deficit + extended;
  if (total === 0) {
    return null;
  }

  return (
    <>
      <div className="d-flex flex-column px-1 justify-content-around flex-grow-1 allocation-state">
        <div className="text-muted d-flex justify-content-between my-1">
          <AllocationBadge className="neutralised" count={neutralised} label={labels.neutralised} />
          <AllocationBadge className="live" count={live} label={labels.live} />
          <AllocationBadge className="reserved" count={reserved} label={labels.reserved} />
          <AllocationBadge className="deficit" count={deficit} label={labels.deficit} />
          <AllocationBadge className="overspent" count={overspent} label={labels.overspent} />
          <AllocationBadge className="extended" count={extended} label={labels.extended} />
        </div>
        <Progress>
          <ProgressBar className="neutralised" value={(neutralised / total) * 100} />
          <ProgressBar className="live" value={(live / total) * 100} />
          <ProgressBar className="reserved" value={(reserved / total) * 100} />
          <ProgressBar className="deficit" value={(deficit / total) * 100} />
          <ProgressBar className="overspent" value={(overspent / total) * 100} />
          <ProgressBar className="extended" value={(extended / total) * 100} />
        </Progress>
      </div>
      <Conflicts conflicts={conflicts} />
    </>
  );
}
