import React from 'react';
import { Action } from './actions';
import { ActionBar, Crumbs } from './actionbar';

export interface SceneProps {
  actions?: Action[];
  crumbs?: Crumbs;
  extraActions?: React.ReactElement;
}

export const Scene: React.FC<SceneProps> = ({ crumbs = [], children, actions, extraActions }) => {
  return (
    <>
      <ActionBar crumbs={crumbs} actions={actions} extraActions={extraActions} />
      <div className="container">{children}</div>
    </>
  );
};
