import React, { useCallback, useEffect, useState } from 'react';
import './Report.scss';

import { Alert, DateRangePickerField, Form, LoadingContainer, SelectField } from 'ffsdk';
import { Service, FilterState } from './types';
import { ServiceGroups } from './ServiceGroups';
import { Graph } from './Graph';
import { useAPIRequest } from 'core';
import { Button, useDebounce } from 'ffsdk';
import { useFormState, useForm } from 'react-final-form';

import eq from 'fast-deep-equal';

interface FilterProps {
  serviceOptions: Service[];
  filter: FilterState;
  setFilter: (f: FilterState) => void;
}
function Filter({ serviceOptions, setFilter, filter }: FilterProps): React.ReactElement {
  const onSubmit = (): void => undefined;
  return (
    <Form onSubmit={onSubmit}>
      <SelectField<Service>
        name="service"
        options={serviceOptions}
        label="Pakalpojums"
        placeholder="Meklēt..."
        getOptionLabel={({ title }: Service) => title}
        getOptionValue={({ id }: Service) => id.toString()}
        isClearable={false}
      />
      <DateRangePickerField fromName="date_from" toName="date_to" label="Periods" />
      <div className="text-right">
        <FilterControl filter={filter} setFilter={setFilter} />
      </div>
    </Form>
  );
}

function FilterControl({ filter, setFilter }: { filter: any; setFilter: (f: any) => void }): React.ReactElement | null {
  const { initialize } = useForm();
  const { valid, values } = useFormState({ subscription: { valid: true, values: true } });
  const applyFilter = useDebounce(
    useCallback(
      (values) => {
        if (!eq(filter, values)) {
          setFilter(values);
        }
      },
      [setFilter, filter],
    ),
    500,
  );

  useEffect(() => {
    valid && applyFilter(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid, values]);

  if (Object.keys(values).length !== 0) {
    return (
      <Button variant="outline-danger" size="sm" onClick={() => initialize(initialFilterState)}>
        Atcelt
      </Button>
    );
  }
  return null;
}

const initialFilterState = {
  service: null,
  date_from: null,
  date_to: null,
};

export function Report(): React.ReactElement {
  const [filter, setFilter] = useState<FilterState>(initialFilterState);
  const serviceOptions = useAPIRequest<Service[]>({ url: 'reporting/reservation/services/', method: 'GET' });
  return (
    <div className="container">
      <div className="main py-3">
        <LoadingContainer loading={!serviceOptions}>
          {serviceOptions && (
            <div className="row mt-3">
              <div className="col-md-9">
                <h4>Rezervācijas</h4>
                {filter.service ? (
                  <Graph service={filter.service} date_from={filter.date_from} date_to={filter.date_to} />
                ) : (
                  <Alert>Izvēlies pakalpojumu</Alert>
                )}
              </div>
              <div className="col-md-3">
                <Filter filter={filter} setFilter={setFilter} serviceOptions={serviceOptions} />
                <h5>Pakalpojumu groupa</h5>
                <ServiceGroups serviceOptions={serviceOptions} service={filter.service} />
              </div>
            </div>
          )}
        </LoadingContainer>
      </div>
    </div>
  );
}
