import React from 'react';
import { NavLink } from 'react-router-dom';

import { Person } from 'types';
import { Column } from 'ffsdk';

const prefix = {
  contact: '/crm/contacts/',
  fleet: '/transport/fleets/',
};

export const columns: Column<Person>[] = [
  {
    content: 'Persona',
    key: 'title',
    render: ({ id, first_name, last_name, entity: { id: entityId, type, title } }: Person) => {
      return (
        <NavLink to={`${prefix[type]}${entityId}/persons/${id}`}>
          {first_name} {last_name} ({title})
        </NavLink>
      );
    },
  },
  // contact
  {
    content: 'Loma',
    key: 'role',
  },
  {
    content: 'Tālrunis',
    key: 'mobile_phone',
    render: ({ mobile_phone, work_phone }: Person) => (
      <>
        {mobile_phone && <div>M: {mobile_phone}</div>}
        {work_phone && <div>W: {work_phone}</div>}
      </>
    ),
  },
  {
    content: 'E-pasts',
    key: 'email',
  },
  {
    content: 'Piezīmes',
    key: 'notes',
  },
];
