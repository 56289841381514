import { badgeFactory } from 'core/ui';
import { InvoiceState, PaymentState, invoiceStateLabels, paymentStateLabels } from 'types';

export const StateBadge = badgeFactory<InvoiceState>(
  {
    PENDING: 'secondary',
    DRAFT: 'info',
    CONFIRMED: 'success',
    CANCELED: 'secondary',
  },
  invoiceStateLabels,
  'invoice-state',
);

export const PaymentStateBadge = badgeFactory<PaymentState>(
  {
    NEW: 'secondary',
    LATE: 'danger',
    PARTIAL: 'warning',
    PAID: 'success',
  },
  paymentStateLabels,
  'invoice-payment-state',
);
