import React from 'react';
import { Crumb, CrumbNode, Crumbs } from 'core/ui';

import { List, NewAdd, NewChange, Item, Remove } from 'core/route';

import { Routes, Route } from 'react-router-dom';

import { agreementTypeLabels, Campaign, Invoice } from 'types';
import { columns } from './columns';
import { Form } from './form';
import { formatDate } from 'core/util';
import { StateBadge as AgreementStateBadge } from '../../campaigns/Agreements/badge';
import { NavLink } from 'react-router-dom';
import { StateBadge } from './badge';
import { EntityActions, ContactSelectField, InvoiceStateSelectField, InvoiceTypeSelectField } from 'components';
import { resolveActions } from './resolveActions';
import { ListFilter } from 'core';
import { TextField, DatePickerField, SelectField, useList, CheckField } from 'ffsdk';
import { Payment } from './Payment';
import Big from 'big.js';

const initialValues = {
  enterprise: null,
  client: null,
  checkout_date: null,
  payment_date: null,
  type: 'INVOICE',
  po_number: '',
  signature_person: null,
  vat_rate: 21,
  language: 'lv',
  motivation: '',
};
const endpoint = '/api/v1/invoicing/invoices/';

const initialFilter = { state: 'CONFIRMED' };
const paymentOptions = [
  { label: 'Jauns', value: 'NEW' },
  { label: 'Kavēts', value: 'LATE' },
  { label: 'Daļēji apmaksāts', value: 'PARTIAL' },
  { label: 'Apmaksāts', value: 'PAID' },
];

export function Footer(): React.ReactElement {
  const { data } = useList<Invoice>();
  const total = data.reduce((acc, { sum_total }) => acc.plus(sum_total), Big(0));
  return (
    <tfoot>
      <tr>
        <th className="text-right" colSpan={10}>
          Kopā
        </th>
        <th className="text-right">{total.toFixed(2)}</th>
      </tr>
    </tfoot>
  );
}

const formatCheck = (v: any): any => {
  return v === 'true';
};
const parseCheck = (v: any): any => {
  return v === true ? true : undefined;
};

export function Invoices(): React.ReactElement {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <List columns={columns} url={endpoint} addUrl="add" defaultFilter={initialFilter} tfoot={<Footer />}>
            <ListFilter>
              <TextField name="search" placeholder="Meklēt..." size="sm" />
              <InvoiceStateSelectField name="state" placeholder="Stāvoklis" size="sm" className="ml-1" />
              <SelectField
                name="payment_state"
                placeholder="Apmaksa"
                size="sm"
                className="ml-1"
                simple
                options={paymentOptions}
              />
              <InvoiceTypeSelectField name="type" placeholder="Tips" size="sm" className="ml-1" />
              <ContactSelectField
                name="enterprise"
                placeholder="Slēdzējiestāde"
                simple
                filter={{ is_enterprise: true }}
                size="sm"
                className="ml-1"
                defaultOptions
              />
              <ContactSelectField
                name="client"
                placeholder="Klients"
                simple
                filter={{ is_client: true }}
                className="ml-1"
                size="sm"
              />
              <DatePickerField name="date_from" size="sm" placeholder="No" className="ml-1" />
              <DatePickerField name="date_to" size="sm" placeholder="Līdz" className="ml-1" />
              <CheckField
                name="payment_date"
                fieldLabel="Apmaksa"
                className="ml-2"
                variant="custom"
                format={formatCheck}
                parse={parseCheck}
              />
            </ListFilter>
          </List>
        }
      />
      <Route path="add" element={<NewAdd initialValues={initialValues} element={<Form />} url={endpoint} />} />
      <Route
        path=":id/*"
        element={
          <Item
            title={(a: Invoice) => a.display_number}
            endpoint={{
              endpoint: `${endpoint}:id/`,
              method: 'GET',
            }}
          >
            {(itemContext) => {
              const {
                item: { id, agreement, campaign, state, payment_state },
              } = itemContext;
              const canEdit = state === 'DRAFT' || state === 'PENDING';

              const crumbs: Crumbs = [];

              if (campaign) {
                crumbs.push(
                  <CrumbNode
                    key="campaign"
                    subTitle="Kampaņa"
                    title={<NavLink to={`/campaigns/campaigns/${campaign.id}`}>{campaign.title}</NavLink>}
                  />,
                );
                if (agreement) {
                  crumbs.push(
                    <CrumbNode
                      right
                      title={
                        <NavLink to={`/campaigns/campaigns/${campaign.id}/agreements/${agreement.id}`}>
                          {agreement.number}
                        </NavLink>
                      }
                      subTitle={`${agreementTypeLabels[agreement.type]} ${formatDate(agreement.date)}`}
                      key="agreement"
                      className="pb-2"
                    >
                      <AgreementStateBadge state={agreement.state} />
                    </CrumbNode>,
                  );
                }
              }
              crumbs.push(
                <CrumbNode key="invoice" subTitle="Rēķins" className="mb-1" title={itemContext.title}>
                  <div>
                    <StateBadge state={state} />
                    {payment_state && (
                      <span className="ml-3">
                        <Payment invoice={itemContext.item} setInvoice={itemContext.setItem} />
                      </span>
                    )}
                  </div>
                </CrumbNode>,
              );
              return (
                <Routes>
                  <NewChange
                    crumbs={crumbs}
                    element={<Form hasAgreement={!!agreement} lock={!canEdit} sidebar />}
                    itemContext={itemContext}
                    url={`${endpoint}${id}/`}
                    extraActions={<EntityActions resolve={resolveActions} />}
                    removeUrl={canEdit || state === 'CANCELED' ? 'remove' : undefined}
                  />
                  <Route
                    path="remove"
                    element={
                      <Remove
                        crumbs={crumbs}
                        endpoint={{
                          endpoint: `${endpoint}${itemContext.item.id}/`,
                          method: 'DELETE',
                        }}
                        successUrl="../../"
                        backUrl="../"
                        itemTitle={itemContext.title}
                      />
                    }
                  />
                </Routes>
              );
            }}
          </Item>
        }
      />
    </Routes>
  );
}

export function CampaignInvoices(props: { campaign: Campaign; campaignCrumb: Crumb }): React.ReactElement {
  const { campaign, campaignCrumb } = props;
  const endpoint = `campaign/campaigns/${campaign.id}/invoices/`;

  // noAdd ?
  return <List crumbs={[campaignCrumb]} url={endpoint} columns={columns} />;
}
