import React, { useEffect, useState } from 'react';
import { Invoice } from 'types';
import { Form, TextField, Submit, useSubmitHandler, Button, SelectField } from 'ffsdk';
import { toast } from 'react-toastify';
import { Modal } from 'core/ui';
import { useDefaultOptions } from 'components/fields/useOptions';
import { useField } from 'react-final-form';
import { Icon } from 'components';

const initialValues = {
  email: '',
  body: '',
};

function SelectOrEnterEmail({ options }: { options: Array<any> }): React.ReactElement {
  const [input, setInput] = useState<boolean>(false);
  const {
    input: { value, onChange },
  } = useField('email', { subscription: { value: true } });
  useEffect(
    () => {
      if (value === '__OTHER__') {
        onChange(undefined);
        setInput(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  function onCancelInput(): void {
    onChange(undefined);
    setInput(false);
  }

  if (input) {
    return (
      <div className="row">
        <TextField label="E-pasts" name="email" type="email" required className="col" />
        <div className="d-flex pr-3 pb-3 align-items-end">
          <Button variant="outline-danger" type="button" onClick={onCancelInput}>
            <Icon icon="times" />
          </Button>
        </div>
      </div>
    );
  }
  return (
    <SelectField<any>
      label="E-pasts"
      name="email"
      options={[
        ...options,
        {
          label: <span style={{ fontWeight: 'bold', fontStyle: 'italic', color: '#86C24E' }}>Cits</span>,
          value: '__OTHER__',
        },
      ]}
      simple
      // eslint-disable-next-line
      // @ts-ignore
      isSearchable={false}
      required
    />
  );
}

export function EmailInvoice({
  id,
  setInvoice,
  contactId,
}: {
  id: number;
  contactId: number;
  setInvoice: (i: Invoice) => void;
}): React.ReactElement | null {
  const [open, setOpen] = useState<boolean>(false);
  const onSubmit = useSubmitHandler(
    {
      url: `invoicing/invoices/${id}/send_invoice/`,
      method: 'POST',
    },
    {
      onSuccess: (item: Invoice) => {
        toast.success('Rēķins nosūtīts!');
        setInvoice(item);
        setOpen(false);
      },
    },
  );
  const emailOptions = useDefaultOptions(`crm/contacts/${contactId}/emails/`);

  return (
    <>
      <Modal isOpen={open} ariaHideApp={false} onRequestClose={() => setOpen(false)}>
        <div className="card">
          <div className="card-header">Nosūtīt</div>
          <Form onSubmit={onSubmit} className="card-body" initialValues={initialValues}>
            <SelectOrEnterEmail options={emailOptions} />
            <TextField label="Saturs" name="body" multiline autosize />
            <Submit>Nosūtīt</Submit>
          </Form>
        </div>
      </Modal>
      <Button onClick={() => setOpen(true)} className="ml-auto">
        Nosūtīt rēķinu
      </Button>
    </>
  );
}
