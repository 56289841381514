import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSession, persistToken } from 'core';

export function Logout(): React.ReactElement | null {
  const { setUser, user } = useSession();

  useEffect(() => {
    if (user) {
      persistToken(null);
      setUser(null);
    }
  }, [user, setUser]);

  if (user === null) {
    return <Navigate to="/login" />;
  }
  return null;
}
