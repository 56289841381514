import React from 'react';

import { NavLink } from 'react-router-dom';

import { Fleet } from 'types';
import { Column } from 'ffsdk';

export const columns: Column<Fleet>[] = [
  {
    content: 'Nosaukums',
    key: 'name',
    render: ({ id, title }) => <NavLink to={`/transport/fleets/${id}`}>{title}</NavLink>,
  },
  {
    content: 'Tips',
    key: 'type',
  },
  {
    content: 'Kontakts',
    key: 'contact.title',
  },
];
