import React from 'react';
import { TextField, CheckField } from 'ffsdk';
import { GroupsSelectField } from 'components';

export function Form({ promptPassword }: { promptPassword?: boolean }): React.ReactElement {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-row">
          <TextField name="username" label="Lietotājvārds" required className="col" />
          <TextField name="email" label="E-pasts" type="email" required className="col" />
        </div>
        <div className="form-row">
          <TextField name="first_name" label="Vārds" className="col" />
          <TextField name="last_name" label="Uzvārds" className="col" />
        </div>

        <GroupsSelectField isMulti defaultOptions name="groups" label="Grupas" />
        <CheckField name="is_active" label="Aktīvs" />
        {!promptPassword && (
          <div className="form-row">
            <TextField name="password1" label="Parole" type="password" required className="col" />
            <TextField name="password2" label="Apstiprināt paroli" type="password" required className="col" />
          </div>
        )}
      </div>
    </div>
  );
}

export function PasswordForm(): React.ReactElement {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-row">
          <TextField name="password1" label="Parole" type="password" required className="col" />
          <TextField name="password2" label="Apstiprināt paroli" type="password" required className="col" />
        </div>
      </div>
    </div>
  );
}
