import React from 'react';

import { NavLink } from 'react-router-dom';

import { ListCampaign, EmbeddedAdvert, EmbeddedAgreement } from 'types';
import { AdvertTitle } from 'components';
import { Column } from 'ffsdk';
import { Link } from 'react-router-dom';
import { StateBadge } from '../Agreements/badge';
import { formatDate } from 'core/util';

export const columns: Column<ListCampaign>[] = [
  {
    key: 'title',
    content: 'Nosaukums',
    render: ({ id, title }) => <NavLink to={`/campaigns/campaigns/${id}`}>{title}</NavLink>,
    sortable: true,
    width: 230,
  },
  { content: 'Klients', key: 'client.title', width: 190 },
  { content: 'Slēdzējiestāde', key: 'enterprise.title', width: 190 },
  {
    key: 'adverts',
    width: 524,
    className: 'py-0',
    content: 'Reklāmas',
    render: ({ adverts }: ListCampaign) => (
      <div style={{ maxHeight: 152, overflowY: 'auto' }}>
        {adverts.map((advert: EmbeddedAdvert) => (
          <AdvertTitle key={advert.id} advert={advert} hideCampaign />
        ))}
      </div>
    ),
  },
  {
    key: 'agreements',
    className: 'py-0',
    content: 'Līgumi',
    render: ({ agreements }: ListCampaign) => (
      <div style={{ maxHeight: 152, overflowY: 'auto' }}>
        {agreements.map((agreement: EmbeddedAgreement) => (
          <div className="advert-title-container" key={agreement.id}>
            <div className="advert-title-content">
              <div className="advert-title-advert">
                <Link to={`/campaigns/campaigns/${agreement.campaign}/agreements/${agreement.id}`}>
                  <span className="advert-title-number">{agreement.number}</span>
                </Link>
              </div>
              <div className="advert-title-meta">
                <StateBadge state={agreement.state} /> {formatDate(agreement.date)}
              </div>
            </div>
          </div>
        ))}
      </div>
    ),
  },
];
