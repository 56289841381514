import React from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { Button, IntField, DecimalField } from 'ffsdk';
import { Icon } from 'components';

const initialStep = {
  step: null,
  price: null,
};

export function PriceSteps({ name }: { name: string }): React.ReactElement {
  const { fields } = useFieldArray(name);
  return (
    <fieldset className="border-top p-3 border mb-4">
      <legend className="w-auto pr-3 mb-0">Cenas soļi</legend>
      {fields.map((name, index) => (
        <div className="d-flex flex-row" key={name}>
          <div className="flex-grow-1 form-row">
            <IntField name={`${name}.step`} label="Solis" required className="col" size="sm" />
            <DecimalField name={`${name}.price`} label="Cena" required className="col" size="sm" />
          </div>
          <div className="ml-3 pt-4">
            <Button
              data-test-id="remove-inline"
              variant="outline-danger"
              type="button"
              onClick={() => fields.remove(index)}
              className="border-0 mt-2"
              size="sm"
            >
              <Icon icon="times" />
            </Button>
          </div>
        </div>
      ))}
      <Button
        type="button"
        variant="outline-success"
        size="sm"
        data-test-id="add-inline"
        onClick={() => {
          fields.push(initialStep);
        }}
      >
        Pievienot
      </Button>
    </fieldset>
  );
}
