import { Error as ApiError, HttpCode, Endpoint, handleEndpointRequest, Result } from 'core/api';
import { toast } from 'react-toastify';

interface ActionHandlerProps<Response> {
  toggleLoadingState?: (state: boolean) => void;
  onSuccess?: (item: Response) => void;
  onFailure?: (item: ApiError) => void;
}

export function handleActionError(error: ApiError): void {
  if (error.status === HttpCode.BadRequest) {
    // @TODO FIXME
    if (Array.isArray(error.detail)) {
      toast.error(error.detail.join(', '));
    }
  }
}

export function actionHandler<Payload, Response = Payload>(
  endpoint: Endpoint<Payload>,
  { toggleLoadingState, onFailure, ...rest }: ActionHandlerProps<Response>,
): Promise<Result<Response>> {
  return handleEndpointRequest(endpoint, {
    onBefore: toggleLoadingState ? () => toggleLoadingState(true) : undefined,
    onAfter: toggleLoadingState ? () => toggleLoadingState(false) : undefined,
    onFailure: (error) => {
      handleActionError(error);
      onFailure && onFailure(error);
    },
    ...rest,
  });
}
