import { ActionResolver } from 'components';
import { Invoice } from 'types';
import { ButtonProps } from 'ffsdk';
import { actionHandler } from 'core/form';

const actionUrl = (id: number, action: string): string => `/api/v1/invoicing/invoices/${id}/${action}/`;

export const resolveActions: ActionResolver<Invoice> = ({ id, state }, onSuccess, toggleLoadingState) => {
  const actions: ButtonProps[] = [];
  const actionProps = {
    onSuccess,
    toggleLoadingState,
  };

  switch (state) {
    case 'PENDING':
      actions.push({
        variant: 'outline-success',
        type: 'button',
        onClick: () =>
          actionHandler(
            {
              endpoint: actionUrl(id, 'draft'),
              method: 'POST',
            },
            actionProps,
          ),
        children: 'Sagatavot',
      });
      break;

    case 'DRAFT':
      actions.push({
        variant: 'outline-success',
        type: 'button',
        onClick: () =>
          actionHandler(
            {
              endpoint: actionUrl(id, 'confirm'),
              method: 'POST',
            },
            actionProps,
          ),
        children: 'Apstiprināt',
      });
      break;
    case 'CONFIRMED':
      actions.push({
        variant: 'outline-danger',
        type: 'button',
        onClick: () => {
          if (window.confirm('Tiešām anulēt?')) {
            actionHandler(
              {
                endpoint: actionUrl(id, 'cancel'),
                method: 'POST',
              },
              actionProps,
            );
          }
        },
        children: 'Anulēt',
      });
      break;
  }

  return actions;
};
