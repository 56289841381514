import React from 'react';
import { TextField, CheckField } from 'ffsdk';
import { CountrySelectField } from 'components';

export function Form(): React.ReactElement {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-row">
          <TextField name="name" label="Nosaukums" required className="col" />
          <CountrySelectField required name="country" label="Valsts" className="col" />
        </div>
        <CheckField name="is_capital" label="Ir galvaspilsēta" variant="switch" />
      </div>
    </div>
  );
}
