import { useState, useRef, useEffect, useCallback } from 'react';
import { Totals, Advert } from 'types';
import { useFormState } from 'react-final-form';
import { useDebounce, useAPI } from 'ffsdk';
import { toast } from 'react-toastify';

export function useEstimateTotals(campaing: number): [Totals | undefined, (values: any) => Promise<void>] {
  const { valid, values, touched: touchedFields } = useFormState<Advert>({
    subscription: { valid: true, values: true, touched: true },
  });
  const [totals, setTotals] = useState<Totals | undefined>(values.totals);
  const api = useAPI();
  const touched = touchedFields ? Object.values(touchedFields).some((v) => v) : false;

  const fetchTotals = useCallback(
    async (values) => {
      const response = await api.post<Totals, any>(`campaign/campaigns/${campaing}/estimate_totals/`, values);
      if (response.ok) {
        // Compare current totals?
        setTotals(response.data);
      } else if (response.status !== 400) {
        throw response.error;
      } else {
        if (response.data?.non_field_errors) {
          toast.error(JSON.stringify(response.data?.non_field_errors));
        } else {
          toast.error(JSON.stringify(response.data));
        }
        setTotals(undefined);
      }
    },
    [campaing, api],
  );

  const applyFilter = useDebounce(
    useCallback(
      async (values, valid, touched) => {
        if (touched && valid) {
          fetchTotals(values);
        } else if (touched) {
          setTotals(undefined);
        }
      },
      [fetchTotals],
    ),
    500,
  );

  useEffect(() => {
    applyFilter(values, valid, touched);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid, values, touched]);

  const didMount = useRef(false);

  // server updated totals
  useEffect(
    () => {
      if (!touched && values.totals) {
        if (didMount.current) {
          setTotals(values.totals);
        } else {
          didMount.current = true;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.modified_at, values.totals],
  );

  return [totals, fetchTotals];
}
