import React from 'react';

import { NavLink } from 'react-router-dom';
import { tBool } from 'core/ui';

import { User } from 'types';
import { Column } from 'ffsdk';

export const columns: Column<User>[] = [
  {
    content: 'Lietotājvārds',
    key: 'username',
    render: ({ id, username }) => <NavLink to={`/settings/security/users/${id}`}>{username}</NavLink>,
  },
  { content: 'Vārds', key: 'first_name' },
  { content: 'Uzvārds', key: 'last_name' },

  { content: 'E-pasts', key: 'email' },
  {
    content: 'Grupas',
    key: 'groups',
    render: ({ groups }) => groups.map(({ name }) => name).join(', '),
  },
  { content: 'Izveidots', key: 'date_joined' },
  {
    content: 'Aktīvs',
    key: 'is_active',
    className: 'text-center',
    headerClassName: 'text-center',
    render: tBool('is_active'),
  },
];
