import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { List, NewAdd, Item, NewChange, Remove, ItemContextType } from 'core/route';
import { CrumbNode, Nav } from 'core/ui';

import { Fleet } from 'types';
import { columns } from './columns';
import { Form } from './Form';

import { NestedPersons } from '../../crm/Persons';
import { NestedVehicles } from '../Vehicles';
import { ListFilter } from 'core';

const endpoint = `/api/v1/transport/fleets/`;

const initialValues = {
  contact: null,
  title: '',
  type: null,

  address: '',
  phone: '',
  email: '',

  notes: '',
  is_active: true,
};

const resolveCrumb = ({ title, url }: ItemContextType<Fleet>): React.ReactElement => (
  <CrumbNode subTitle="Autoparks" title={title}>
    <Nav
      items={[
        { to: url, end: true, children: 'Autoparks' },
        { to: `${url}/vehicles`, children: 'Transportlīdzekļi' },
        { to: `${url}/persons`, children: 'Personas' },
      ]}
    />
  </CrumbNode>
);

export function Fleets(): React.ReactElement {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <List columns={columns} url={endpoint} addUrl="add">
            <ListFilter />
          </List>
        }
      />
      <Route path="add" element={<NewAdd element={<Form />} initialValues={initialValues} url={endpoint} />} />
      <Route
        path=":id/*"
        element={
          <Item
            title={(fleet: Fleet) => fleet.title}
            endpoint={{
              endpoint: `${endpoint}:id/`,
              method: 'GET',
            }}
          >
            {(itemContext) => {
              const crumb = resolveCrumb(itemContext);
              return (
                <Routes>
                  <Route
                    path="/"
                    element={
                      <NewChange
                        removeUrl="remove"
                        element={<Form />}
                        itemContext={itemContext}
                        url={`${endpoint}${itemContext.item.id}/`}
                        crumbs={[crumb]}
                      />
                    }
                  />
                  <Route
                    path="persons/*"
                    element={
                      <NestedPersons
                        endpoint={`/api/v1/transport/fleets/${itemContext.item.id}/persons/`}
                        crumb={crumb}
                      />
                    }
                  />
                  <Route
                    path="vehicles/*"
                    element={
                      <NestedVehicles
                        endpoint={`/api/v1/transport/fleets/${itemContext.item.id}/vehicles/`}
                        fleet={itemContext.item}
                        crumb={crumb}
                      />
                    }
                  />
                  <Route
                    path="remove/*"
                    element={
                      <Remove
                        endpoint={{
                          endpoint: `${endpoint}${itemContext.item.id}/`,
                          method: 'DELETE',
                        }}
                        successUrl="../../"
                        backUrl="../"
                        itemTitle={itemContext.title}
                      />
                    }
                  />
                </Routes>
              );
            }}
          </Item>
        }
      />
    </Routes>
  );
}
