import { useEffect, useState, useCallback, Dispatch, SetStateAction, useRef } from 'react';
import { RequestArgs, useAPI } from 'ffsdk';

interface LoaderState<I> {
  loading: boolean;
  item: I | undefined | null;
}

interface Loader<I> extends LoaderState<I> {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setItem: Dispatch<SetStateAction<I | undefined | null>>;
  fetchItem: () => Promise<I | null>;
}

export function useLoader<I>(args: RequestArgs, ...deps: any[]): Loader<I> {
  const isMounted = useRef<boolean>(true);

  const [loading, setLoading] = useState<boolean>(true);
  const [item, setItem] = useState<I | undefined | null>(undefined);

  const api = useAPI();

  const fetchItem = useCallback(
    async (): Promise<I | null> => {
      if (isMounted.current) {
        setLoading(true);
      }
      const res = await api.request<I, unknown>(args);
      if (isMounted.current) {
        if (res.ok) {
          setItem(res.data);
          setLoading(false);
        } else if (res.status === 404) {
          setItem(null);
        }
      }
      if (res.ok) {
        return res.data;
      } else if (res.status === 404) {
        return null;
      } else {
        throw res.error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );

  useEffect(() => {
    isMounted.current = true;
    fetchItem();

    return () => {
      isMounted.current = false;
    };
  }, [fetchItem]);

  return {
    loading,
    setLoading,
    item,
    setItem,
    fetchItem,
  };
}
