import React, { useState } from 'react';
import { Alert, Button, DatePickerField, Form, IntField, Submit, useSubmitHandler } from 'ffsdk';
import { Card, Modal } from 'core/ui';
import { Advert } from 'types';
import { required } from 'core/fields/validate';
import { handleActionError } from 'core/form';

interface RentDoneActionProps {
  advert: Advert;
  updateItem: (item: any) => void;
}

export function RentDoneAction({ advert, updateItem }: RentDoneActionProps): React.ReactElement {
  const { date_to, rent_units, campaign, id } = advert;
  const [open, setOpen] = useState<boolean>(false);
  const initialValues = {
    date_to,
    rent_units,
  };

  const onSubmit = useSubmitHandler(
    {
      url: `campaign/campaigns/${campaign}/adverts/${id}/done/`,
      method: 'POST',
    },
    {
      onSuccess: (item) => {
        updateItem(item);
        setOpen(false);
      },
      onFailure: (error) => {
        console.log(error);
        handleActionError(error);
      },
    },
  );

  return (
    <>
      <Button type="button" variant="outline-info" onClick={() => setOpen(true)}>
        Pabeigt
      </Button>
      <Modal isOpen={open} ariaHideApp={false} onRequestClose={() => setOpen(false)}>
        <Card title="Pabeigt nomu">
          <Form initialValues={initialValues} onSubmit={onSubmit}>
            <Alert variant="warning" className="py-1 px-2 mb-2">
              <small>Labojot šīs vērtības tiks pārrēķināta tāme</small>
            </Alert>
            <div className="form-row">
              <DatePickerField
                name="date_to"
                label="Faktiskais beigu datums"
                required
                validate={required}
                className="col"
              />
              <IntField name="rent_units" label="Faktiskais skaits" required validate={required} className="col" />
            </div>
            <Submit>Iesniegt</Submit>
          </Form>
        </Card>
      </Modal>
    </>
  );
}
