import React from 'react';
import { toast } from 'react-toastify';
import { Entity } from '../types';
import { Scene, SceneProps, Action } from 'core/ui';
import { ItemContextType } from '../types';

import { Form, useSubmitHandler, FormError, HTTPMethod } from 'ffsdk';
import { useFormState } from 'react-final-form';

function ChangeScene({
  crumbs: sceneCrumbs = [],
  children,
  title,
  removeUrl,
  disabled,
  ...rest
}: SceneProps & {
  children: React.ReactNode;
  title: string;
  removeUrl?: string;
  disabled?: boolean;
}): React.ReactElement {
  const { submitting, validating } = useFormState({ subscription: { submitting: true, validating: true } });
  const crumbs = [...sceneCrumbs];
  if (crumbs.length === 0 && title) {
    crumbs.push({
      subTitle: 'Labot',
      title,
    });
  }
  const actions: Action[] = [];

  if (!disabled) {
    if (removeUrl) {
      actions.push({
        to: removeUrl,
        variant: 'outline-danger',
        children: 'Dzēst',
      });
    }

    actions.push({
      type: 'submit',
      variant: 'primary',
      disabled: submitting || validating,
      children: 'Iesniegt',
    });
  }

  return (
    <Scene actions={actions} {...rest} crumbs={crumbs} {...rest}>
      {children}
    </Scene>
  );
}

interface ChangeProps<E extends Entity> extends Omit<SceneProps, 'actions'> {
  itemContext: ItemContextType<E>;
  url: string;
  method?: HTTPMethod;
  element: React.ReactElement;
  removeUrl?: string;
  onSuccess?: (item: E) => void;
  successMessage?: string;
  disabled?: boolean;
}

export function Change<E extends Entity>(props: ChangeProps<E>): React.ReactElement {
  const {
    itemContext,
    url,
    onSuccess,
    successMessage = 'Ieraksts izlabots.',
    element,
    disabled,
    method = 'PUT',
    ...sceneProps
  } = props;

  const { title, item, setItem } = itemContext;

  const onSubmit = useSubmitHandler(
    {
      url,
      method,
    },
    {
      onSuccess: (item: E) => {
        toast.success(successMessage);
        if (onSuccess) {
          onSuccess(item);
        } else {
          setItem(item);
        }
      },
    },
  );

  return (
    <Form onSubmit={onSubmit} initialValues={item as any} renderErrors={false} disabled={disabled} noValidate>
      <ChangeScene title={title} disabled={disabled} {...sceneProps}>
        <FormError />
        {element}
      </ChangeScene>
    </Form>
  );
}
