import { addDays, formatISO, parseISO } from 'date-fns';
import { Advert, Rent, Position } from 'types';

export function rentFields(
  parent: Advert,
  has_rent: boolean,
): {
  has_rent: boolean;
  rent: Rent | null;
  date_from: string;
  date_to: string | null;
} {
  if (has_rent) {
    return {
      has_rent,
      rent: parent.rent,
      date_from: formatISO(addDays(parseISO(parent.date_to), 1), { representation: 'date' }),
      date_to: null,
    };
  }
  return {
    has_rent,
    rent: null,
    date_from: parent.date_from,
    date_to: parent.date_to,
  };
}

const extendible = ['PRINT', 'PRINT_PERFORATED', 'ANTI_GRAFFITI', 'SILICONE_LACQUER', 'EXPOSURE'];

export function productionFields(
  parent: Advert,
  has_production: boolean,
): {
  has_production: boolean;
  positions: Position[];
  production_units: number;
} {
  if (has_production) {
    return {
      has_production,
      positions: (parent.positions
        .filter(({ type }) => extendible.includes(type))
        .map((v) => ({ ...v, id: undefined })) as any) as Position[],
      production_units: 1,
    };
  }

  return {
    has_production,
    positions: [],
    production_units: 0,
  };
}
