import React, { useState } from 'react';
import { Entity } from '../types';
import { Result, Endpoint, HttpCode } from 'core/api';

import { toast } from 'react-toastify';
import { Scene, SceneProps } from 'core/ui';
import { Button, Alert, LoadingContainer } from 'ffsdk';
import { handleEndpointRequest } from 'core/api';
import { useNavigate } from 'react-router-dom';

export interface RemoveProps<T, Payload = undefined> extends SceneProps {
  itemTitle: string;
  endpoint: Endpoint<Payload>;
  successUrl: string;
  backUrl?: string;
  onSuccess?: () => void;
}

export function Remove<T extends Entity>(props: RemoveProps<T>): React.ReactElement {
  const {
    successUrl,
    backUrl,
    endpoint,
    itemTitle,
    actions = [],
    crumbs: providedCrumbs = [],
    onSuccess,
    ...sceneProps
  } = props;
  const navigate = useNavigate();

  const crumbs = [
    ...providedCrumbs,
    {
      title: itemTitle,
      subTitle: 'Dzēst',
    },
  ];

  const [{ loading }, setState] = useState<{ loading: boolean }>({
    loading: false,
  });

  const removeHandler = (): Promise<Result<undefined>> =>
    handleEndpointRequest<undefined>(endpoint, {
      onBefore: () => setState({ loading: true }),
      onAfter: () => setState({ loading: false }),
      onSuccess: () => {
        console.log('success');
        toast.success('Ieraksts dzēsts.');
        navigate(successUrl);
        onSuccess && onSuccess();
      },
      onFailure: (error) => {
        console.log('error');
        if (error.status === HttpCode.Forbidden) {
          // @TODO fixme
          toast.error(error.detail.detail || 'Error');
        } else if (error.status === HttpCode.BadRequest) {
          toast.error(JSON.stringify(error.detail) || 'Error');
        } else {
          console.log(error);
          // @TODO
          throw error;
        }
      },
    });

  const sceneActions = [...actions];

  if (backUrl) {
    sceneActions.push({
      to: backUrl,
      variant: 'link',
      children: 'Atpakaļ',
    });
  }

  return (
    <>
      <LoadingContainer loading={loading} testId="remove-loader">
        <Scene {...sceneProps} actions={sceneActions} crumbs={crumbs}>
          <Alert variant="danger">
            Vai esi drošs ka vēlies dzēst: &quot;<strong>{itemTitle}</strong>&quot;
          </Alert>
          <Button type="button" variant="danger" size="lg" onClick={removeHandler} disabled={loading}>
            Dzēst
          </Button>
        </Scene>
      </LoadingContainer>
    </>
  );
}
