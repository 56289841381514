import React, { useEffect, useCallback } from 'react';
import { Form, TextField, Button, useDebounce, useList } from 'ffsdk';
import { useFormState, useForm } from 'react-final-form';

import eq from 'fast-deep-equal';

function FilterControl({ filter, setFilter }: { filter: any; setFilter: (f: any) => void }): React.ReactElement | null {
  const { initialize } = useForm();
  const { valid, values } = useFormState({ subscription: { valid: true, values: true } });
  const applyFilter = useDebounce(
    useCallback(
      (values) => {
        if (!eq(filter, values)) {
          setFilter(values);
        }
      },
      [setFilter, filter],
    ),
    500,
  );

  useEffect(() => {
    valid && applyFilter(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid, values]);

  if (Object.keys(values).length !== 0) {
    return (
      <Button variant="outline-danger" size="sm" className="ml-1 mb-3 border-0" onClick={() => initialize({})}>
        ×
      </Button>
    );
  }
  return null;
}

const onSubmit = async (): Promise<void> => undefined;

interface ListFilterProps {
  children?: React.ReactNode;
  initialValues?: any;
}

export function ListFilter({ children }: ListFilterProps): React.ReactElement {
  const { filter, setFilter } = useList();

  return (
    <Form onSubmit={onSubmit} initialValues={filter} className="form-inline list-filter">
      {children ? children : <TextField name="search" placeholder="Meklēt..." size="sm" />}
      <FilterControl filter={filter} setFilter={setFilter} />
    </Form>
  );
}
