import React from 'react';
import { ActionResolver } from 'components';
import { Service } from 'types';
import { Button } from 'ffsdk';
import { actionHandler } from 'core/form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

function CopyAction({
  service,
  toggleLoadingState,
}: {
  service: Service;
  toggleLoadingState: (state: boolean) => void;
}): React.ReactElement {
  const navigate = useNavigate();

  function runAction(): void {
    const payload = {
      ...service,
      id: undefined,
      surfaces: service.surfaces.map((surface) => ({
        ...surface,
        id: undefined,
      })),
      positions: service.positions.map((position) => ({
        ...position,
        price_steps: position.price_steps.map((step) => ({
          ...step,
          id: undefined,
        })),
        id: undefined,
      })),
      title: `Kopija ${service.title}`,
    };
    actionHandler(
      {
        endpoint: '/api/v1/advert/services/',
        method: 'POST',
        payload,
      },
      {
        onSuccess: (created) => {
          toast.success('Kopija izveidota.');
          navigate(`/settings/services/${created.id}`);
        },
        toggleLoadingState,
        onFailure: (err) => {
          if (err.status === 400 && err.detail?.title) {
            toast.error('Kopija jau eksistē');
          } else {
            throw err;
          }
        },
      },
    );
  }

  return (
    <Button type="button" variant="outline-info" onClick={runAction}>
      Kopēt
    </Button>
  );
}

export const resolveActions: ActionResolver<Service> = (service, onSuccess, toggleLoadingState) => {
  return [<CopyAction service={service} toggleLoadingState={toggleLoadingState} key="copy" />];
};
