import React from 'react';
import { EmailInvoice } from './EmailInvoice';
import { useItem } from 'core/route';
import { Invoice } from 'types';
import { useAPIRequest } from 'core';
import { LoadingContainer, Button, Alert } from 'ffsdk';
import { parseISO, format } from 'date-fns';
import { Advance } from './Payment/types';

type Action =
  | {
      created_at: string;
      user: string;
      action: 'email';
      data: {
        id: number;
        email: string;
      };
    }
  | {
      created_at: string;
      user: string;
      action: 'payment';
      data: {
        id: number;
        amount: string;
        date: string;
        advance_number: string | null;
      };
    };

function ActionLog({ invoice }: { invoice: Invoice }): React.ReactElement {
  const { id, modified_at } = invoice;
  const data = useAPIRequest<Action[]>({ url: `invoicing/invoices/${id}/log/`, method: 'GET' }, id, modified_at);

  return (
    <LoadingContainer loading={!data}>
      <table className="table mt-3">
        <thead>
          <tr>
            <th>Laiks</th>
            <th>Lietotājs</th>
            <th>Darbība</th>
          </tr>
        </thead>
        <tbody>
          {(data || []).map(({ created_at, user, ...rest }, idx) => {
            let desc: React.ReactNode;
            if (rest.action === 'email') {
              desc = (
                <>
                  Nosūtīts uz <strong>{rest.data.email}</strong>
                </>
              );
            } else {
              const { date, amount, advance_number } = rest.data;
              desc = (
                <>
                  Pievienota apmaksa ({format(parseISO(date), 'dd.MM.yyyy')}) <strong>{amount}€</strong>{' '}
                  {advance_number && <strong>{advance_number}</strong>}
                </>
              );
            }
            return (
              <tr key={idx}>
                <td>{format(parseISO(created_at), 'dd.MM.yyyy HH:mm')}</td>
                <td>{user}</td>
                <td>{desc}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </LoadingContainer>
  );
}

function AdvanceAlert({ id }: { id: number }): React.ReactElement | null {
  const data = useAPIRequest<Advance[]>({ url: `invoicing/invoices/${id}/payment/`, method: 'GET' }, id);

  if (data && data.length > 0) {
    return <Alert variant="warning">Šim klientam ir pieejami avansa rēķini ko var attiecināti kā apmaksu.</Alert>;
  }
  return null;
}

export function Sidebar(): React.ReactElement {
  const { item: invoice, setItem } = useItem<Invoice>();

  return (
    <div>
      {invoice.type === 'INVOICE' && invoice.reminder !== '0.00' && <AdvanceAlert id={invoice.id} />}
      <div className="btn-toolbar mb-3">
        {invoice.public_url && (
          <>
            <Button
              href={`${invoice.public_url}?format=pdf`}
              target="_blank"
              variant="outline-primary"
              className="mr-1"
            >
              Lejupielādēt PDF
            </Button>
            <Button href={`${invoice.public_url}?format=html`} variant="outline-primary" className="mr-1">
              HTML versija
            </Button>
          </>
        )}

        {invoice.state === 'CONFIRMED' && (
          <EmailInvoice setInvoice={setItem} contactId={invoice.client.id} id={invoice.id} />
        )}
      </div>
      <ActionLog invoice={invoice} />
    </div>
  );
}
