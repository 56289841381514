import Big from 'big.js';

import { lightFormat, differenceInDays, parseISO } from 'date-fns';

export const DATE_FORMAT = 'dd.MM.yyyy';
const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm:ss';

export const formatDate = (date: string): string => lightFormat(parseISO(date), DATE_FORMAT);
export const formatDateTime = (date: string): string => lightFormat(parseISO(date), DATE_TIME_FORMAT);

export const formatPeriod = (
  { date_from, date_to }: { date_from: string; date_to: string },
  omitFrom = false,
): string => {
  const f = parseISO(date_from);
  const t = parseISO(date_to);
  const days = differenceInDays(t, f) + 1;
  return `${omitFrom ? '' : `${lightFormat(f, DATE_FORMAT)} - `}${lightFormat(t, DATE_FORMAT)} (${days}d) `;
};

const NUMERIC = /^-?(\d+(\.\d*)?|\.\d+)(e[+-]?\d+)?$/i;

export const parseNumber = (value: string): Big | null => {
  if (!NUMERIC.test((value += ''))) {
    return null;
  }
  return Big(value);
};

export const parseNumberOrZero = (value: string): Big => {
  if (!NUMERIC.test((value += ''))) {
    return Big(0);
  }
  return Big(value);
};
