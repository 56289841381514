import { useContext, createContext } from 'react';
import { Entity, ItemContextType } from '../types';

export const ItemContext = createContext<ItemContextType<any> | undefined>(undefined);

export function useItem<T extends Entity>(): ItemContextType<T> {
  const itemContext = useContext(ItemContext);
  if (!itemContext) {
    throw new Error('Item context missing');
  }

  return itemContext;
}
