import { badgeFactory } from 'core/ui';
import { AdvertState, advertStateLabels } from 'types';

export const StateBadge = badgeFactory<AdvertState>(
  {
    OFFER: 'primary',
    RESERVED: 'info',
    PLANNING: 'warning',
    PRODUCTION: 'danger',
    EXPOSURE: 'success',
    CANCELED: 'secondary',
    DONE: 'info',
  },
  advertStateLabels,
  'advert-state',
);
