import React from 'react';
import { StateBadge } from 'views/invoicing/Invoices/badge';
import { AgreementInvoice } from 'types';
import { formatDate } from 'core/util';
import { NavLink } from 'react-router-dom';

export function View({
  id,
  state,
  display_number,
  checkout_date,
  motivation,
  sum_total,
}: AgreementInvoice): React.ReactElement {
  return (
    <tr>
      <td>
        <div>
          <StateBadge state={state} />
        </div>
        <small>{id ? <NavLink to={`/campaigns/invoices/${id}`}>{display_number}</NavLink> : display_number}</small>
      </td>
      <td className="date">{formatDate(checkout_date)}</td>
      <td>{motivation || '—'}</td>
      <td className="text-right">{state === 'CANCELED' ? <del>{sum_total}</del> : <strong>{sum_total}</strong>}</td>
      <td />
    </tr>
  );
}
