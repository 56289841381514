import React from 'react';
import { typeOptions } from '../const';
import { SelectField, TextField } from 'ffsdk';

export function Form(): React.ReactElement {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-row">
          <SelectField name="type" label="Tips" options={typeOptions} simple required className="col" />
          <TextField name="title" label="Nosaukums" required className="col" />
          <TextField name="brand" label="Ražotajs" className="col" />
        </div>
        <TextField name="description" multiline autosize label="Apraksts" />
        <TextField name="notes" multiline autosize label="Piezīmes" />
      </div>
    </div>
  );
}
