import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { init } from '@sentry/browser';
import { ErrorBoundary } from 'components/ErrorBoundary';

if (process.env.NODE_ENV === 'production') {
  init({
    // @TODO read this from ENV
    dsn: 'https://5b56850ce91a401f8d14970b41a67630:dc93f375eb9d4d4ea59c6edb396697d4@sentry.first.lv/109',
  });
}

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
