import React from 'react';
import { saveAs } from 'file-saver';
import { ActionResolver } from 'components';
import { Advert } from 'types';
import { ButtonProps } from 'ffsdk';
import { actionHandler } from 'core/form';
import { actionUrl } from './util';
import { ReceivePrintFileAction } from 'views/Work/actions';
import { VehicleExposureAction } from 'views/transport/Vehicles/actions';
import { RentDoneAction } from './RentDoneAction';

export const resolveActions: ActionResolver<Advert> = (advert, onSuccess, toggleLoadingState) => {
  const { state, id, campaign, agreement, has_rent, has_production, reserved_at } = advert;
  const actions: Array<React.ReactElement | ButtonProps> = [];

  const callAction = (action: string): (() => void) => (): void => {
    actionHandler(
      {
        endpoint: actionUrl(id, campaign, action),
        method: 'POST',
      },
      {
        onSuccess,
        toggleLoadingState,
      },
    );
  };

  if (state !== 'CANCELED') {
    actions.push({
      variant: 'info',
      onClick: () =>
        actionHandler(
          {
            endpoint: actionUrl(id, campaign, 'estimate'),
            method: 'GET',
          },
          {
            onSuccess: (resp: Blob) => {
              saveAs(resp, 'estimate.xlsx');
            },
          },
        ),
      children: 'Tāme',
    });
  } else {
    if (!agreement) {
      actions.push({
        to: 'remove',
        variant: 'outline-danger',
        children: 'Dzēst',
      });
    }

    actions.push({
      variant: 'outline-success',
      onClick: callAction('offer'),
      children: 'Piedāvājums',
    });
  }

  switch (state) {
    case 'OFFER':
      if (!agreement && !reserved_at) {
        actions.push({
          variant: 'outline-warning',
          onClick: callAction('cancel'),
          children: 'Atcelt',
        });
      }
      if (has_rent) {
        actions.push({
          variant: 'outline-info',
          onClick: callAction('reserve'),
          children: 'Rezervēt',
        });
      }
      break;
    case 'RESERVED':
      actions.push({
        variant: 'outline-info',
        onClick: callAction('unreserve'),
        children: 'Atcelt rezervāciju',
      });
      if (agreement) {
        actions.push({
          variant: 'outline-info',
          onClick: callAction('plan'),
          children: 'Plānot',
        });
      }
      break;

    case 'PLANNING':
      actions.push({
        variant: 'outline-warning',
        onClick: callAction('unplan'),
        children: 'Atcelt plānošanu',
      });

      if (has_production) {
        actions.push(
          <ReceivePrintFileAction
            key="receive_print_file"
            endpoint={actionUrl(id, campaign, 'receive_print_file')}
            updateItem={onSuccess}
          />,
        );
      } else if (has_rent) {
        actions.push({
          variant: 'outline-info',
          onClick: callAction('expose'),
          children: 'Eksponēt',
        });
      } else {
        // mark done?
      }

      break;

    case 'EXPOSURE':
      actions.push(<VehicleExposureAction key="expose" advert={advert} updateItem={onSuccess} action="expose" />);
      actions.push(
        <VehicleExposureAction key="neutralize" advert={advert} updateItem={onSuccess} action="neutralize" />,
      );

      if (has_rent) {
        actions.push(<RentDoneAction advert={advert} updateItem={onSuccess} key="done" />);
      } else {
        actions.push({
          variant: 'outline-info',
          onClick: callAction('done'),
          children: 'Pabeigt',
        });
      }
      break;

    case 'DONE':
      actions.push({
        variant: 'outline-warning',
        onClick: callAction('cancel'),
        children: 'Atcelt',
      });
  }

  return actions;
};
