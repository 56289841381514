import React from 'react';
import { Campaign } from 'types';
import { Routes, Route } from 'react-router-dom';
import { List, NewAdd, Item, NewChange, ItemContextType, Remove } from 'core/route';

import { columns } from './columns';
import { Form } from './Form';
import { CampaignAdverts } from '../Adverts';
import { CampaignAgreements } from '../Agreements';
import { CampaignInvoices } from '../../invoicing/Invoices';
import { CrumbNode, Nav } from 'core/ui';
import { CitySelectField, ServiceSelectField, AdvertStateSelectField, UserSelectField } from 'components';
import { ListFilter } from 'core';
import { TextField, DatePickerField } from 'ffsdk';

const initialValues = {
  title: '',
  client: null,
  enterprise: null,
  managers: [],
  theme: 'COMMERCIAL',
  vat_rate: 21,
  language: 'lv',
};

const resolveCrumb = ({ item: campaign, title, url }: ItemContextType<Campaign>): React.ReactElement => (
  <CrumbNode subTitle={campaign.client.title} title={title}>
    <Nav
      items={[
        { to: url, end: true, children: 'Kampaņa' },
        { to: `${url}/adverts`, children: 'Reklāmas' },
        { to: `${url}/agreements`, children: 'Līgumi' },
        { to: `${url}/invoices`, children: 'Rēķini' },
      ]}
    />
  </CrumbNode>
);

const endpoint = `/api/v1/campaign/campaigns/`;

export function Campaigns(): React.ReactElement {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <List columns={columns} url={endpoint} addUrl="add">
            <ListFilter>
              <TextField name="search" placeholder="Meklēt.." size="sm" />
              <CitySelectField
                name="city"
                size="sm"
                placeholder="Pakalp. pilsēta"
                simple
                defaultOptions
                className="ml-1"
              />
              <ServiceSelectField name="service" size="sm" placeholder="Pakalpojums" simple className="ml-1" />
              <AdvertStateSelectField name="state" size="sm" placeholder="Stāvoklis" className="ml-1" />
              <UserSelectField
                name="manager"
                size="sm"
                placeholder="Proj. vad."
                simple
                defaultOptions
                className="ml-1"
                filter={{ is_manager: true }}
              />
              <DatePickerField name="date_from" size="sm" placeholder="Rekl. no" className="ml-1" />
              <DatePickerField name="date_to" size="sm" placeholder="Rekl. līdz" className="ml-1" />
            </ListFilter>
          </List>
        }
      />
      <Route path="add" element={<NewAdd initialValues={initialValues} element={<Form />} url={endpoint} />} />
      <Route
        path=":id/*"
        element={
          <Item
            title={(campaign: Campaign) => campaign.title}
            endpoint={{
              endpoint: `${endpoint}:id/`,
              method: 'GET',
            }}
          >
            {(itemContext) => {
              const campaignCrumb = resolveCrumb(itemContext);
              const props = { campaign: itemContext.item, campaignCrumb };
              const hasAdverts = itemContext.item.adverts.length > 0;
              return (
                <Routes>
                  <NewChange
                    removeUrl={hasAdverts ? undefined : 'remove'}
                    element={<Form disabled={itemContext.item.has_agreements} />}
                    url={`${endpoint}${itemContext.item.id}/`}
                    itemContext={itemContext}
                    crumbs={[campaignCrumb]}
                  />
                  <Route path="adverts/*" element={<CampaignAdverts {...props} setCampaign={itemContext.setItem} />} />
                  <Route path="agreements/*" element={<CampaignAgreements {...props} />} />
                  <Route path="invoices/*" element={<CampaignInvoices {...props} />} />
                  <Route
                    path="remove"
                    element={
                      <Remove
                        endpoint={{
                          endpoint: `${endpoint}${itemContext.item.id}/`,
                          method: 'DELETE',
                        }}
                        successUrl="../../"
                        backUrl="../"
                        itemTitle={itemContext.title}
                      />
                    }
                  />
                </Routes>
              );
            }}
          </Item>
        }
      />
    </Routes>
  );
}
