import React from 'react';
import { Campaign, Advert, Totals } from 'types';
import { ItemContextType } from 'core/route';
import { ExtensionFields } from './ExtensionFields';

import { RentFields, useEstimateTotals, TotalsFields, PositionFields } from '../common';
import { useField, useForm } from 'react-final-form';
import { Sidebar } from '../Sidebar';
import { AdvertTitle } from 'components';
import { ParentSelect } from './ParentSelect';
import { useOnFieldChange } from '../../util';
import { productionFields, rentFields } from './factory';

interface FormProps {
  campaign: Campaign;
  itemContext?: ItemContextType<Advert>;
}

function Rent({ totals }: { totals?: Totals }): React.ReactElement | null {
  const {
    input: { value },
  } = useField('has_rent', { subscription: { value: true } });

  if (!value) {
    return null;
  }
  return (
    <>
      <h4>Noma</h4>
      <RentFields name="extension_of.rent" totals={totals?.rent} lockTax />
    </>
  );
}

function Positions({ totals }: { totals?: Totals }): React.ReactElement | null {
  const {
    input: { value },
  } = useField('has_production', { subscription: { value: true } });

  if (!value) {
    return null;
  }
  return (
    <>
      <h4>Pozīcijas</h4>
      <PositionFields totals={totals?.positions} />
    </>
  );
}

export function ExtensionForm({ campaign, itemContext }: FormProps): React.ReactElement {
  const [totals, fetchTotals] = useEstimateTotals(campaign.id);
  const form = useForm();

  useOnFieldChange('has_rent', (has_rent) => {
    console.log('a');
    const values = form.getState().values;
    const advert = values.extension_of;

    const formValues = {
      ...values,
      ...rentFields(advert, has_rent),
    };
    form.initialize(formValues);
    form.getState().valid && fetchTotals(formValues);
  });

  useOnFieldChange('has_production', (has_production) => {
    console.log('b');
    const values = form.getState().values;
    const advert = values.extension_of;
    const formValues = {
      ...values,
      ...productionFields(advert, has_production),
    };

    form.initialize(formValues);
    form.getState().valid && fetchTotals(formValues);
  });

  return (
    <div className="row">
      <div className="col-md-6 ">
        <table className="table table-bordered table-form">
          <tbody>
            <tr>
              <th>Pamatreklāma</th>
              <td>
                {itemContext?.item.extension_of ? (
                  <div className="px-3">
                    <AdvertTitle advert={itemContext.item.extension_of} hideCampaign />
                  </div>
                ) : (
                  <ParentSelect campaignId={campaign.id} />
                )}
              </td>
            </tr>
            <ExtensionFields advert={itemContext?.item} />
          </tbody>
        </table>
        <Rent totals={totals} />
        <Positions totals={totals} />
        <h4>Kopā</h4>
        <TotalsFields totals={totals} />
      </div>
      <div className="col-md-6">{itemContext && <Sidebar itemContext={itemContext} />}</div>
    </div>
  );
}
