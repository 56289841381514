import React from 'react';

import { NavLink } from 'react-router-dom';

import { Vehicle } from 'types';
import { Column } from 'ffsdk';

export const columns: Column<Vehicle>[] = [
  {
    content: 'Borta numurs',
    sortable: true,
    key: 'number',
    render: ({ id, number, fleet: { id: fleetId } }: Vehicle) => {
      return <NavLink to={`/transport/fleets/${fleetId}/vehicles/${id}`}>{number}</NavLink>;
    },
  },
  {
    content: 'Ražotājs',
    sortable: true,
    key: 'model.brand',
  },
  {
    content: 'Tips',
    sortable: true,
    key: 'model.type',
  },
  {
    content: 'Modelis',
    sortable: true,
    key: 'model.title',
  },
  {
    content: 'Autoparks',
    sortable: true,
    key: 'fleet.title',
  },
];
