import React from 'react';
import { useItem, Entity } from 'core/route';
import { useFormState } from 'react-final-form';
import { Button } from 'ffsdk';
import { EntityActionProps } from './types';

export function EntityActions<T extends Entity>({ resolve }: EntityActionProps<T>): React.ReactElement | null {
  const { item, setItem, setLoading } = useItem<T>();
  const { submitting, validating, dirty } = useFormState({
    subscription: { submitting: true, validating: true, dirty: true },
  });

  if (dirty) {
    return null;
  }

  const formDisabled = submitting || validating;

  return (
    <>
      {resolve(item, setItem, setLoading).map((action, idx) => {
        if (React.isValidElement(action)) {
          return action;
        }

        return <Button key={idx} {...action} disabled={action.disabled || formDisabled} />;
      })}
    </>
  );
}
