import React, { useState } from 'react';
import { Card, Modal } from 'core/ui';

import { Button, TextField, Form, useSubmitHandler, Submit } from 'ffsdk';

// const d = `TL inv. Nr.	Reklāmas nosaukums	5/25/2019
// 74700	Drogas	0
// 74755	Drogas	0
// 75576	Drogas	0
// `;

const d = '';

export const ImportAction: React.FC<{
  onSuccess: (d: string) => void;
}> = ({ onSuccess }) => {
  const [open, setOpen] = useState<boolean>(false);

  const initialValues = {
    data: d,
  };
  const onSubmit = useSubmitHandler(
    {
      url: `/api/v1/transport/idle/import/`,
      method: 'POST',
    },
    {
      onSuccess,
    },
  );

  return (
    <>
      <Button type="button" variant="primary" onClick={() => setOpen(true)}>
        Importēt
      </Button>
      <Modal isOpen={open} ariaHideApp={false} onRequestClose={() => setOpen(false)}>
        <Card title="Importēt">
          <Form initialValues={initialValues} onSubmit={onSubmit}>
            <TextField name="data" rows={10} multiline />
            <Submit>Iesniegt</Submit>
          </Form>
        </Card>
      </Modal>
    </>
  );
};
