import React from 'react';
import { NavLink } from 'react-router-dom';
import { Service } from 'types';
import { Column } from 'ffsdk';

export const columns: Column<Service>[] = [
  {
    content: 'Nosaukums',
    key: 'title',
    render: ({ id, title }: Service) => <NavLink to={`/settings/services/${id}`}>{title}</NavLink>,
    sortable: true,
  },
  {
    content: 'Country',
    key: 'country.name',
  },
  {
    content: 'City',
    key: 'city.name',
  },
];
