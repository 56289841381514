import { User, Group } from 'types';
import { createSelectField } from './createSelectField';

export const UserSelectField = createSelectField<User>('security/users/', {
  getOptionLabel: ({ username, first_name, last_name }: User) => (first_name ? `${first_name} ${last_name}` : username),
  getOptionValue: ({ id }: User) => id.toString(),
});

export const GroupsSelectField = createSelectField<Group>('security/groups/', {
  getOptionLabel: ({ name }: Group) => name,
  getOptionValue: ({ id }: Group) => id.toString(),
});
