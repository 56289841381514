import { Option } from 'components/fields/createSelectField';
import { EmbeddedAdvert } from './campaigns/adverts';
import { EmbeddedCampaign } from './campaigns/campaigns';

export type PrintJobState = 'RECEIVED' | 'APPROVED' | 'PASSED_FOR_PRINT' | 'DONE' | 'REJECTED';

export const printJobStateLabels: Record<PrintJobState, string> = {
  RECEIVED: 'Saņemts',
  APPROVED: 'Apstiprināts',
  PASSED_FOR_PRINT: 'Nodots drukai',
  DONE: 'Pabeigts',
  REJECTED: 'Noraidīts',
};

export const printJobStateOptions: Option<PrintJobState>[] = ([
  'RECEIVED',
  'APPROVED',
  'PASSED_FOR_PRINT',
  'DONE',
  'REJECTED',
] as PrintJobState[]).map((value) => ({
  value,
  label: printJobStateLabels[value],
}));

interface Common {
  id: number;

  state: PrintJobState;
  file: string;
  rejection_reason: string | null;
  modified_at: string;
}

export interface EmbeddedPrintJob extends Common {
  advert: number;
}

export interface PrintJob extends Common {
  advert: EmbeddedAdvert;
  campaing: EmbeddedCampaign;
}
