export enum HttpCode {
  OK = 200,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  NotAcceptable = 406,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
}

export interface Error {
  status: number | null; // null if we fail to complete request
  detail?: any; //?? string array, _form, etc, comm error vs resource error
  // code?: ErrorCode;

  // source?: string;
}

export type Result<T> = Success<T> | Failure<Error>;

export interface Success<T> {
  ok: true;
  value: T;
}

export interface Failure<T> {
  ok: false;
  error: T;
}

export interface PaginatedResponse<T> {
  count: number;
  next: null | string;
  prev: null | string;
  results: T[];
}
