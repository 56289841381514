import { useEffect, useRef } from 'react';
import { useField } from 'react-final-form';
import { Advert, EmbeddedAdvert } from 'types';

export const isImmutable = (advert: Advert): boolean => !!advert.reserved_at || advert.state !== 'OFFER';

export const url = ({ id, campaign: { id: campaignId } }: EmbeddedAdvert): string =>
  `/campaigns/campaigns/${campaignId}/adverts/${id}`;

export const title = ({ rent_units, service: { title } }: EmbeddedAdvert | Advert): string =>
  `${rent_units} × ${title}`;

export const actionUrl = (id: number, campaing: number, action: string): string =>
  `/api/v1/campaign/campaigns/${campaing}/adverts/${id}/${action}/`;

export function useOnFieldChange<V = any>(name: string, callback: (newValue: V, oldValue: V) => void): void {
  const {
    input: { value },
    meta: { active },
  } = useField<V>(name, { subscription: { value: true, active: true } });

  const previous = useRef(value);
  useEffect(() => {
    if (value !== previous.current) {
      active && callback(value, previous.current);
      previous.current = value;
    }
  });
}
