import React from 'react';
import { useAPIRequest } from 'core';
import { LoadingContainer, Alert } from 'ffsdk';
import { PrintJob } from 'types';

import { AdvertTitle } from 'components';

export function RejectedPrintJobs(): React.ReactElement {
  const jobs = useAPIRequest<PrintJob[]>({
    url: 'work/print_jobs/',
    method: 'GET',
    queryParams: {
      page_size: '',
      rejected: true,
    },
  });

  const rows = jobs || [];
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Noraidītie drukas faili</h5>
        <LoadingContainer loading={!jobs}>
          {rows.length > 0 ? (
            <table className="table table-sm mb-0">
              <thead>
                <tr>
                  <th>Reklāma</th>
                  <th className="text-right">Iemesls</th>
                </tr>
              </thead>
              <tbody>
                {rows.map(({ id, advert, rejection_reason }) => (
                  <tr key={id}>
                    <td>
                      <AdvertTitle advert={advert} />
                    </td>
                    <td className="text-right">{rejection_reason}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Alert variant="success" className="mb-0">
              Nav noraidītu drukas failu
            </Alert>
          )}
        </LoadingContainer>
      </div>
    </div>
  );
}
