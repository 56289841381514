import React from 'react';
import { TextField, SelectField, DecimalField, CheckField, Button } from 'ffsdk';
import { Icon } from 'components';

import { priceUnitOptions, positionTypeOptions } from '../const';
import { useFieldArray } from 'react-final-form-arrays';
import { useField } from 'react-final-form';
import { PriceSteps } from './PriceSteps';

const initialPosition = {
  title: '',
  type: null,
  area: null,
  price_unit: 'AREA',
  is_range: false,
  price_steps: [],
  price: null,
  optional: false,
};

function AreaField({ name }: { name: string }): React.ReactElement {
  const {
    input: { value: price_unit },
  } = useField(`${name}.price_unit`, { subscription: { value: true } });
  return (
    <DecimalField
      name={`${name}.area`}
      label="Laukums"
      precision={4}
      className="col"
      required={price_unit === 'AREA'}
    />
  );
}

function OptionRow({ name }: { name: string }): React.ReactElement {
  const {
    input: { value: is_range },
  } = useField(`${name}.is_range`, { subscription: { value: true } });

  return (
    <>
      <div className="form-row">
        <CheckField name={`${name}.optional`} label="Neobligāts" variant="switch" className="col-3" />
        <CheckField name={`${name}.is_range`} label="Soļi" variant="switch" className="col-3" />
        <div className="col-6">{!is_range && <DecimalField name={`${name}.price`} label="Cena" required />}</div>
      </div>
      {is_range && <PriceSteps name={`${name}.price_steps`} />}
    </>
  );
}

export function Positions(): React.ReactElement {
  const { fields } = useFieldArray('positions');
  return (
    <fieldset className=" p-3 border mb-4">
      <legend className="w-auto pr-3  mb-0">Pozīcijas</legend>
      {fields.map((name, index) => (
        <div className="d-flex flex-row border-bottom mb-3" key={name}>
          <div className="flex-grow-1">
            <div className="form-row">
              <TextField name={`${name}.title`} label="Nosaukums" required className="col" />
              <SelectField
                name={`${name}.type`}
                label="Tips"
                simple
                options={positionTypeOptions}
                className="col"
                required
              />
            </div>
            <div className="form-row">
              <AreaField name={name} />
              <SelectField
                name={`${name}.price_unit`}
                label="Cenas vienība"
                simple
                options={priceUnitOptions}
                className="col"
                required
              />
            </div>
            <OptionRow name={name} />
          </div>
          <div className="ml-3 pt-4">
            <Button
              data-test-id="remove-inline"
              variant="outline-danger"
              type="button"
              onClick={() => fields.remove(index)}
              className="border-0 mt-2"
              size="sm"
            >
              <Icon icon="times" />
            </Button>
          </div>
        </div>
      ))}
      <Button
        type="button"
        variant="outline-success"
        size="sm"
        data-test-id="add-inline"
        onClick={() => {
          fields.push(initialPosition);
        }}
      >
        Pievienot
      </Button>
    </fieldset>
  );
}
