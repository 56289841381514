import React from 'react';
import { coerceNullProps } from 'core/fields';
import { EmbeddedAdvert, Fleet, Vehicle } from 'types';
import { AdvertTitle, ModelSelectField } from 'components';
import { TextField, CheckField, LoadingContainer } from 'ffsdk';
import { useLoader } from 'core';
import { useItem } from 'core/route';
import { formatDate } from 'core/util';

export interface FormProps {
  fleet: Fleet;
  log?: boolean;
}

interface AdvertAction {
  action: 'ADVERT';
  date_from: string;
  date_to: string | null;
  advert: EmbeddedAdvert;
}
interface IdleAction {
  action: 'IDLE';
  date_from: string;
  date_to: string;
  advert: null;
}
type Action = AdvertAction | IdleAction;

function Log(): React.ReactElement {
  const {
    item: {
      id,
      fleet: { id: fleetId },
    },
  } = useItem<Vehicle>();

  const { loading, item } = useLoader<Action[]>(
    { url: `transport/fleets/${fleetId}/vehicles/${id}/log/`, method: 'GET' },
    id,
  );

  return (
    <LoadingContainer loading={loading}>
      <table className="table table-sm">
        <thead>
          <tr>
            <th>Darbība</th>
            <th>No</th>
            <th>Līdz</th>
            <th>Reklāma</th>
          </tr>
        </thead>
        <tbody>
          {(item || []).map(({ action, date_from, date_to, advert }, idx) => {
            return (
              <tr key={idx}>
                <td>{action === 'ADVERT' ? 'Eksponēšana' : 'Dīkstave'}</td>
                <td>{formatDate(date_from)}</td>
                <td>{date_to && formatDate(date_to)}</td>
                <td className="py-0">{advert && <AdvertTitle advert={advert} />}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </LoadingContainer>
  );
}

export function Form({ fleet, log }: FormProps): React.ReactElement {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-row">
          <ModelSelectField name="model" label="modelis" required filter={{ type: fleet.type }} className="col" />
          <TextField name="number" label="Borta numurs" required className="col" />
          <TextField name="registration_number" label="Reģistrācijas numurs" {...coerceNullProps} className="col" />
        </div>
        <TextField name="notes" label="Piezīmes" multiline autosize />
        <CheckField name="is_active" label="Iespējot" variant="switch" />
      </div>
      <div className="col-md-6">{log && <Log />}</div>
    </div>
  );
}
