import { stringify } from 'qs';

import { Result, HttpCode, Error as ResponseError } from './types';
import { loadToken } from 'core';
export * from './types';

const rq: RequestInit = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // 'X-CSRFToken': getCookie('csrftoken') || '',
  },
  // mode: "cors",
  // credentials: 'include',
  cache: 'no-cache',
};

async function handleResponse<T>(response: Response): Promise<Result<T>> {
  try {
    if (!response.ok) {
      const error = await response.json();
      return {
        ok: false,
        error: {
          status: response.status,
          detail: error,
        },
      };
    }

    if (response.status === HttpCode.NoContent) {
      return {
        ok: true,
        value: (null as unknown) as T,
      };
    } else {
      const contentType = response.headers.get('Content-Type');
      let result: T;
      if (contentType && ~contentType.indexOf('json')) {
        result = await response.json();
      } else {
        result = ((await response.blob()) as unknown) as T;
      }

      return {
        ok: true,
        value: result,
      };
    }
  } catch (e) {
    return {
      ok: false,
      error: {
        status: null,
        detail: 'Failed to parse response',
      },
    };
  }
}

// @TODO const ENDPOINT = ''; // 'http://localhost:8000';

interface RequestProps {
  url: string;
  settings: RequestInit;
  // settings
  // auth
}

export async function makeRequest<T>({ url, settings }: RequestProps): Promise<Result<T>> {
  const req = new Request(url, { ...settings, ...rq });
  const token = loadToken();
  try {
    // signal: settings && settings.signal,
    if (token) {
      req.headers.set('Authorization', `Token ${token}`);
    }
    const resp = await fetch(req);
    return handleResponse<T>(resp);
  } catch (e) {
    return {
      ok: false,
      error: {
        status: null,
        detail: 'Unable to reach server',
      },
    };
  }
}

export interface Endpoint<Payload = undefined> {
  endpoint: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  params?: object;
  payload?: Payload;
  signal?: AbortSignal;
}

export function endpointToRequest<Payload>({
  endpoint,
  params,
  payload,
  method,
  signal,
}: Endpoint<Payload>): {
  url: string;
  settings: {
    method: string;
    body: string | undefined;
    signal?: AbortSignal;
  };
} {
  const url = `${endpoint}${params ? stringify(params, { addQueryPrefix: true }) : ''}`;
  const body = typeof payload === 'undefined' ? undefined : JSON.stringify(payload);

  return { url, settings: { method, body, signal } };
}

interface RequestHandlerProps<Response> {
  onSuccess?: (item: Response) => void;
  onFailure?: (error: ResponseError) => void;
  onBefore?: (request: RequestProps) => void; // extra args?
  onAfter?: (result: Result<Response>) => void;
}

export async function handleRequest<Response>(
  request: RequestProps,
  callbacks?: RequestHandlerProps<Response>,
): Promise<Result<Response>> {
  // @FIXME
  const { onSuccess, onFailure, onBefore, onAfter } = callbacks || {
    onSuccess: undefined,
    onFailure: undefined,
    onBefore: undefined,
    onAfter: undefined,
  };
  onBefore && onBefore(request);
  const response = await makeRequest<Response>(request);
  onAfter && onAfter(response);

  if (response.ok) {
    onSuccess && onSuccess(response.value);
  } else {
    // soft error, could be relatable to suer
    const status = response.error.status;
    if (status && 400 <= status && status < 500) {
      onFailure && onFailure(response.error);
    } else {
      // @TODO hard error
      throw Error;
    }
  }
  return response;
}

export function handleEndpointRequest<Payload, Response = Payload>(
  endpoint: Endpoint<Payload>,
  callbacks?: RequestHandlerProps<Response>,
): Promise<Result<Response>> {
  return handleRequest<Response>(endpointToRequest(endpoint), callbacks);
}
