import Big from 'big.js';
import { EmbeddedAdvert, AgreementInvoice } from 'types';
import { AgreementBalance } from '../types';
import { parseNumberOrZero } from 'core/util';
import { useField } from 'react-final-form';
import { useMemo } from 'react';

export function useAgreementBalance(): AgreementBalance {
  const {
    input: { value: adverts },
  } = useField<EmbeddedAdvert[]>('adverts', { subscription: { value: true } });

  const {
    input: { value: invoices },
  } = useField<AgreementInvoice[]>('invoices', { subscription: { value: true } });

  return useMemo(() => {
    const advertSum = adverts.reduce((acc, { sum_total }) => {
      return acc.plus(parseNumberOrZero(sum_total));
    }, Big(0));
    const invoiceSum = invoices
      .filter(({ state }) => state !== 'CANCELED')
      .reduce((acc, { sum_total }) => {
        return acc.plus(parseNumberOrZero(sum_total));
      }, Big(0));

    return {
      total: advertSum,
      bound: invoiceSum,
      diff: advertSum.sub(invoiceSum),
    };
  }, [adverts, invoices]);
}
