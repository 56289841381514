import React from 'react';
import { Advert } from 'types';
import { ItemContextType } from 'core/route';
import { actionHandler } from 'core/form';

import { formatDateTime } from 'core/util';
import { Card } from 'core/ui';

import { actionUrl } from '../../util';
import { Check } from 'ffsdk';

const ACTION_MAP = {
  RECEIVE_SKETCH: {
    label: 'Skice saņemta',
    name: 'sketch_received',
  },
  CONFIRM_SKETCH: {
    label: 'Skice apstiprināta',
    name: 'sketch_confirmed',
  },
  CONFIRM_FLEET: {
    label: 'Autoparks apstiprināts',
    name: 'fleet_confirmed',
  },
};

interface PlanningProps {
  itemContext: ItemContextType<Advert>;
}

interface PlanningActionProps extends PlanningProps {
  action: keyof typeof ACTION_MAP;
}

function PlanningAction({
  action,
  itemContext: { item, setItem: onSuccess, setLoading: toggleLoadingState },
}: PlanningActionProps): React.ReactElement {
  const { label, name } = ACTION_MAP[action];

  // @ts-ignore
  const value: string | null = item[`${name}_at`];

  return (
    <div className="form-row d-flex justify-content-between">
      <Check
        name={name}
        checked={!!value}
        label={label}
        variant="custom"
        onChange={() =>
          actionHandler(
            {
              endpoint: actionUrl(item.id, item.campaign, 'plan_action'),
              method: 'POST',
              payload: { action: value ? `UN${action}` : action },
            },
            {
              toggleLoadingState,
              onSuccess,
            },
          )
        }
      />
      {value && formatDateTime(value)}
    </div>
  );
}

export function Planning({ itemContext }: PlanningProps): React.ReactElement {
  const {
    item: { has_rent, has_production },
  } = itemContext;
  return (
    <>
      {has_production && (
        <Card title="Valsts valodas centrs" className="mb-1">
          <PlanningAction action="RECEIVE_SKETCH" itemContext={itemContext} />
          <PlanningAction action="CONFIRM_SKETCH" itemContext={itemContext} />
        </Card>
      )}
      {has_rent && (
        <Card title="Autoparks" className="mb-1">
          <PlanningAction action="CONFIRM_FLEET" itemContext={itemContext} />
        </Card>
      )}
    </>
  );
}
