import { ActionResolver } from 'components';
import { Agreement } from 'types';
import { ButtonProps } from 'ffsdk';
import { actionHandler } from 'core/form';

const actionUrl = (id: number, campaing: number, action: string): string =>
  `/api/v1/campaign/campaigns/${campaing}/agreements/${id}/${action}/`;

export const resolveActions: ActionResolver<Agreement> = (
  { id, campaign, state, invoices },
  onSuccess,
  toggleLoadingState,
) => {
  const actions: ButtonProps[] = [];
  const actionProps = {
    onSuccess,
    toggleLoadingState,
  };

  if (invoices.filter((i) => i.state !== 'PENDING').length === 0) {
    actions.push({
      to: 'remove',
      variant: 'outline-danger',
      children: 'Dzēst',
    });
  }

  switch (state) {
    case 'PENDING':
      actions.push({
        variant: 'outline-success',
        type: 'button',
        onClick: () =>
          actionHandler(
            {
              endpoint: actionUrl(id, campaign, 'confirm'),
              method: 'POST',
            },
            actionProps,
          ),
        children: 'Apstiprināt',
      });
      break;
    case 'CONFIRMED':
      actions.push({
        variant: 'outline-warning',
        type: 'button',
        onClick: () =>
          actionHandler(
            {
              endpoint: actionUrl(id, campaign, 'unconfirm'),
              method: 'POST',
            },
            actionProps,
          ),
        children: 'Atcelt',
      });
      actions.push({
        variant: 'outline-success',
        type: 'button',

        onClick: () =>
          actionHandler(
            {
              endpoint: actionUrl(id, campaign, 'receive'),
              method: 'POST',
            },
            actionProps,
          ),
        children: 'Atzīmēt kā saņemtu',
      });
      break;
  }

  return actions;
};
