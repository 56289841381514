import React from 'react';

import { NavLink } from 'react-router-dom';

import { Contact } from 'types';
import { Column } from 'ffsdk';
import { tBool } from 'core/ui';

export const columns: Column<Contact>[] = [
  {
    content: 'Nosaukums',
    key: 'title',
    render: ({ id, title }) => <NavLink to={`/crm/contacts/${id}`}>{title}</NavLink>,
  },
  {
    content: 'Slēdzējiestāde',
    key: 'is_enterprise',
    className: 'text-center',
    headerClassName: 'text-center',
    render: tBool('is_enterprise'),
  },
  {
    content: 'Klients',
    key: 'is_client',
    className: 'text-center',
    headerClassName: 'text-center',
    render: tBool('is_client'),
  },
  {
    content: 'Aktīvs',
    key: 'is_active',
    className: 'text-center',
    headerClassName: 'text-center',
    render: tBool('is_active'),
    width: '115px',
  },
];
