import { PriceUnit, TimeUnit, SurfaceType, PositionType } from 'types';

export const priceUnitOptions: {
  value: PriceUnit;
  label: string;
}[] = [
  { value: 'AREA', label: 'Cena par m²' },
  { value: 'UNIT', label: 'Cena par vienību' },
  { value: 'FIXED', label: 'Fiksēta cena' },
];

export const timeUnitOptions: {
  value: TimeUnit;
  label: string;
}[] = [
  { value: 'DAY', label: 'Cena par dienu' },
  { value: 'WEEK', label: 'Cena par nedēļu' },
  { value: 'MONTH', label: 'Cena par mēnesi' },
];

export const surfaceTypeOptions: {
  value: SurfaceType;
  label: string;
}[] = [
  { value: 'NORMAL', label: 'Normāla' },
  { value: 'GLASS', label: 'Stikla' },
  { value: 'LIGHT', label: 'Gaismas' },
];

export const positionTypeOptions: {
  value: PositionType;
  label: string;
}[] = [
  { value: 'PRINT', label: 'Druka' },
  { value: 'PRINT_PERFORATED', label: 'Druka perforēta' },
  { value: 'ANTI_GRAFFITI', label: 'Anti graffiti' },
  { value: 'SILICONE_LACQUER', label: 'Silikona pārklājums' },
  { value: 'EXPOSURE', label: 'Eksponēšana' },
  { value: 'RESTORE', label: 'Fona atjaunošana' },
];
