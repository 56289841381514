import React, { useState } from 'react';
import { Card, Modal } from 'core/ui';
import { actionHandler } from 'core/form';
import { Button, TextField, Form, useSubmitHandler, Submit } from 'ffsdk';

export const ReceivePrintFileAction: React.FC<{
  endpoint: string;
  updateItem: (item: any) => void;
}> = ({ endpoint, updateItem }) => {
  const [open, setOpen] = useState<boolean>(false);

  const onSubmit = useSubmitHandler(
    {
      url: endpoint,
      method: 'POST',
    },
    {
      onSuccess: (item) => {
        updateItem(item);
        setOpen(false);
      },
    },
  );

  return (
    <>
      <Button type="button" onClick={() => setOpen(true)} variant="outline-info">
        Saņemt drukas failu
      </Button>
      <Modal isOpen={open} ariaHideApp={false} onRequestClose={() => setOpen(false)}>
        <Card title="Saņemt drukas failu">
          <Form onSubmit={onSubmit} initialValues={{ file: '' }}>
            <TextField name="file" label="Fails" multiline autosize />
            <Submit>Iesniegt</Submit>
          </Form>
        </Card>
      </Modal>
    </>
  );
};

export const RejectPrintJobAction: React.FC<{
  printJobId: number;
  updateItem: (item: any) => void;
}> = ({ updateItem, printJobId }) => {
  const [open, setOpen] = useState<boolean>(false);

  const onSubmit = useSubmitHandler(
    {
      url: `/api/v1/work/print_jobs/${printJobId}/reject/`,
      method: 'POST',
    },
    {
      onSuccess: (item) => {
        // setOpen(false);, we are re-rendering table
        updateItem(item);
      },
    },
  );

  return (
    <>
      <Button type="button" variant="outline-danger" onClick={() => setOpen(true)}>
        Noraidīt
      </Button>
      <Modal isOpen={open} ariaHideApp={false} onRequestClose={() => setOpen(false)}>
        <Card title="Noraidīt drukas darbu">
          <Form onSubmit={onSubmit} initialValues={{ reason: '' }}>
            <TextField name="reason" label="Iemesls" multiline autosize />
            <Submit>Iesniegt</Submit>
          </Form>
        </Card>
      </Modal>
    </>
  );
};

export type JobAction = 'approve' | 'pass_for_print' | 'mark_done';

export const PrintJobAction: React.FC<{
  printJobId: number;
  toggleLoadingState: (loading: boolean) => void;
  updateItem: (item: any) => void;
  action: JobAction;
  title: string;
}> = ({ printJobId, toggleLoadingState, updateItem: onSuccess, action, title }) => {
  const onClick = (): void => {
    actionHandler(
      {
        endpoint: `/api/v1/work/print_jobs/${printJobId}/${action}/`,
        method: 'POST',
      },
      { toggleLoadingState, onSuccess },
    );
  };
  return (
    <Button type="button" variant="outline-info" onClick={onClick}>
      {title}
    </Button>
  );
};
