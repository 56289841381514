import React from 'react';
import { BrowserRouter as Router, Routes, Navigate, Route, useRoutes } from 'react-router-dom';

import { ToastContainer, Slide, toast } from 'react-toastify';
import './style.scss';

import { Login, Logout, routes } from './views';

import { APIProvider, FetchClient } from 'ffsdk';

import { Session, useSession, loadToken } from './core';

const ProtectedRoutes: React.FC = () => {
  const { user } = useSession();
  const element = useRoutes(routes);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return element;
};

const client = new FetchClient({
  baseURL: '/api/v1',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  credentials: 'same-origin',
  cache: 'no-cache',
  before: (r: Request) => {
    const token = loadToken();
    if (token) {
      r.headers.set('Authorization', `Token ${token}`);
    }
  },
});

function ErrorElement(): null {
  throw Error('Sample error');
}

export function App(): React.ReactElement {
  return (
    <APIProvider client={client}>
      <Session>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/trigger_error" element={<ErrorElement />}></Route>
            <Route path="/*" element={<ProtectedRoutes />} />
          </Routes>
        </Router>
      </Session>
      <ToastContainer
        autoClose={2000}
        position={toast.POSITION.BOTTOM_CENTER}
        draggable={false}
        transition={Slide}
        closeButton={false}
      />
    </APIProvider>
  );
}
