import React, { Fragment, isValidElement } from 'react';
import styled from '@emotion/styled';

import { ButtonProps, Button } from 'ffsdk';

export type Action = ButtonProps | React.ReactElement;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  & > * {
    margin-left: 10px;
    white-space: nowrap;
  }
`;

export const Actions: React.FC<{
  actions?: Action[];
  children?: React.ReactNode;
}> = ({ actions, children }) =>
  (!actions || actions.length === 0) && !children ? null : (
    <ActionsContainer>
      {children}
      {actions
        ? actions.map((action, key) => {
            if (isValidElement(action)) {
              return <Fragment key={key}>{action}</Fragment>;
            } else {
              return <Button key={key} {...action} />;
            }
          })
        : null}
    </ActionsContainer>
  );
