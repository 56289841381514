import { badgeFactory } from 'core/ui';
import { AgreementState, agreementStateLabels } from 'types';

export const StateBadge = badgeFactory<AgreementState>(
  {
    PENDING: 'warning',
    CONFIRMED: 'success',
    RECEIVED: 'secondary',
  },
  agreementStateLabels,
  'agreement-state',
);
