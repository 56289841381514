import React, { useCallback } from 'react';

import { Invoice, invoiceTypeLabels } from 'types';
import { formatDate } from 'core/util';

import { StateBadge } from './badge';
import { Column, useList } from 'ffsdk';
import { NavLink } from 'react-router-dom';
import { Payment } from './Payment';

function PaymentColumn({ invoice }: { invoice: Invoice }): React.ReactElement {
  const { setState } = useList<Invoice>();
  const setItem = useCallback((item: Invoice) => {
    setState(({ data, ...state }) => ({
      data: data.map((i) => (i.id === item.id ? item : i)),
      ...state,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Payment invoice={invoice} setInvoice={setItem} />;
}

export const columns: Column<Invoice>[] = [
  {
    content: 'Stāvoklis',
    key: 'state',
    width: '110px',
    render: ({ state }) => <StateBadge state={state} />,
    sortable: true,
  },
  {
    content: 'Numurs',
    key: 'checkout_date',
    sortable: true,
    render: ({ id, display_number }: Invoice) => {
      return <NavLink to={`/campaigns/invoices/${id}`}>{display_number}</NavLink>;
    },
  },
  {
    content: 'Tips',
    key: 'type',
    sortable: true,
    render: ({ type }: Invoice) => invoiceTypeLabels[type],
  },
  {
    content: 'Kampaņa',
    key: 'campaign',
    render: ({ campaign }: Invoice) => {
      if (campaign) {
        return <NavLink to={`/campaigns/campaigns/${campaign.id}`}>{campaign.title}</NavLink>;
      }
      return null;
    },
  },
  {
    content: 'Līgums',
    key: 'agreement',
    render: ({ agreement, campaign }: Invoice) => {
      if (agreement && campaign) {
        return (
          <NavLink to={`/campaigns/campaigns/${campaign.id}/agreements/${agreement.id}`}>{agreement.number}</NavLink>
        );
      }
      return null;
    },
  },
  {
    key: 'enterprise.id',
    content: 'Slēdzējiestāde',
    render: ({ enterprise: { id, title } }) => title,
  },
  {
    key: 'client.title',
    content: 'Klients',
    sortable: true,
    render: ({ client: { id, title } }) => title,
  },
  {
    key: 'payment_date',
    content: 'Apm. dat.',
    sortable: true,
    render: ({ payment_date }) => formatDate(payment_date),
  },
  {
    content: 'Apmaksa',
    key: 'payment_state',
    className: 'text-nowrap',
    render: (invoice) => <PaymentColumn invoice={invoice} />,
  },
  {
    width: 45,
    content: 'Kav.',
    key: '_days_late',
    headerClassName: 'text-center small px-1',
    className: 'text-center small align-middle',
    sortable: true,
    render: ({ days_late, payment_state }) => {
      if (days_late === null || typeof days_late === 'undefined') {
        return null;
      }

      if (payment_state === 'PAID') {
        return <span className={days_late < 0 ? 'text-success' : 'text-danger'}>{Math.abs(days_late)}</span>;
        // $days_paid_term < 0 ? '<span style="color:green">'.abs($days_paid_term).'</span>' : '<span style="color:red">'.abs($days_paid_term).'</span>';
      } else {
        days_late = days_late * -1;
        if (days_late > 0) {
          return (
            <span className={`${days_late > 10 ? 'font-weight-normal' : 'font-weight-bold'} text-success`}>
              {days_late}
            </span>
          );
        } else {
          return <span className="text-danger">{days_late}</span>;
        }
      }
    },
  },
  {
    content: 'Summa',
    className: 'text-right',
    headerClassName: 'text-right',
    sortable: true,
    key: 'sum_total',
  },
];
