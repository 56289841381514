import React, { useState } from 'react';
import Big from 'big.js';
import { useFieldArray } from 'react-final-form-arrays';
import { Button, Input, TextArea, useIsDisabled } from 'ffsdk';
import { useAgreementBalance } from './useAgreementBalance';
import { Header } from './Header';
import './Invoices.scss';
import { useField } from 'react-final-form';
import { AgreementInvoice } from 'types';
import { View } from './View';
import { Edit } from './Edit';

interface ToolbarProps {
  reminder: Big;
  numInvoices: number;
  push: (value: any) => void;
  campaignTitle: string;
}

const invoiceInitialProps = {
  checkout_date: null,
  motivation: '',
  number: undefined,
  state: 'PENDING',
};

function Toolbar({ reminder, push, numInvoices, campaignTitle }: ToolbarProps): React.ReactElement {
  const {
    input: { value: number },
  } = useField<string>('number', { subscription: { value: true } });

  const [num, setNum] = useState<number | ''>(1);
  const [motivation, setMotivation] = useState(
    '[N]. maksājums saskaņā ar Pakalpojumu līgumu Nr. [NR] par reklāmas "[TITLE]" izvietošanu',
  );

  function getMotivation(offset = 1): string {
    const map = {
      '[N]': numInvoices + offset,
      '[NR]': number,
      '[TITLE]': campaignTitle,
    };
    return motivation.replace(
      /\[N\]|\[NR\]|\[TITLE\]/gi,
      (m): string =>
        //@ts-ignore
        map[m as any],
    );
  }

  function numChangeHandler(e: React.ChangeEvent<HTMLInputElement>): void {
    const parsed = parseInt(e.target.value, 10);
    if (!isNaN(parsed)) {
      setNum(parsed);
    } else {
      setNum('');
    }
  }

  function onCreateClick(): void {
    if (num === '') {
      return;
    }
    // round down
    let invoiceSum = reminder.div(num).round(2, 0);
    let assigned = Big(0);

    for (let i = 0; i < num; i++) {
      assigned = assigned.plus(invoiceSum);
      // Add rounding error to last invoice
      if (i === num - 1 && assigned.lt(reminder)) {
        invoiceSum = invoiceSum.plus(reminder.minus(assigned));
      }
      push({
        ...invoiceInitialProps,
        motivation: getMotivation(i + 1),
        sum_total: invoiceSum.toFixed(2),
      });
    }
  }

  return (
    <div className="d-flex flex-row p-3 agreement-invoices-footer">
      <div className="flex-grow-1">
        <div className="form-row">
          <div className="col-date">
            <Button
              type="button"
              variant="outline-success"
              className="btn-block"
              data-test-id="add-inline"
              size="sm"
              onClick={() => {
                push({ ...invoiceInitialProps, motivation: getMotivation() });
              }}
            >
              Pievienot
            </Button>
          </div>
          <div className="col">
            <TextArea
              placeholder="Motivācija"
              autosize
              value={motivation}
              onChange={(e) => setMotivation(e.target.value)}
              size="sm"
            />
          </div>

          <div className="col-sum">
            {reminder.gt(0) && (
              <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                  <Button variant="outline-success" onClick={onCreateClick}>
                    Izveidot
                  </Button>
                </div>
                <Input type="number" name="num_invoices" value={num.toString()} onChange={numChangeHandler} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function Invoices({ campaignTitle }: { campaignTitle: string }): React.ReactElement {
  const balance = useAgreementBalance();
  const disabled = useIsDisabled();
  const { fields } = useFieldArray<AgreementInvoice>('invoices', { subscription: { value: true } });

  return (
    <div className="agreement-invoices">
      <fieldset className="p-3 border">
        <Header balance={balance} />
        <table className="table table-sm">
          <thead>
            <tr>
              <th className="state">Rēķins</th>
              <th className="date">Datums</th>
              <th className="motivation">Motivācija</th>
              <th className="text-right summ">Summa</th>
              <th className="tools" />
            </tr>
          </thead>
          <tbody>
            {fields.map((name, index) => {
              const value = fields.value[index];
              if (value.state === 'PENDING') {
                return (
                  <Edit
                    name={name}
                    id={value.id}
                    state={value.state}
                    display_number={value.display_number}
                    key={index}
                    index={index}
                    remove={disabled ? undefined : fields.remove}
                  />
                );
              } else {
                return <View {...value} key={index} />;
              }
            })}
          </tbody>
        </table>
      </fieldset>
      {!disabled && (
        <Toolbar
          push={fields.push}
          numInvoices={fields.value.length}
          reminder={balance.diff}
          campaignTitle={campaignTitle}
        />
      )}
    </div>
  );
}
