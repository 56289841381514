import React from 'react';

import { NavLink } from 'react-router-dom';

import { Agreement } from 'types';
import { formatDate } from 'core/util';

import { StateBadge } from './badge';
import { Column } from 'ffsdk';

export const columns: Column<Agreement>[] = [
  {
    content: 'Stāvoklis',
    key: 'state',
    width: '110px',
    headerClassName: 'tl',
    render: ({ state }: Agreement) => <StateBadge state={state} />,
  },
  {
    content: 'Numurs',
    key: 'number',
    render: ({ id, number, campaign: campaignId }: Agreement) => (
      <NavLink to={`/campaigns/campaigns/${campaignId}/agreements/${id}`}>{number}</NavLink>
    ),
  },
  {
    content: 'Datums',
    key: 'date',
    render: ({ date }: Agreement) => formatDate(date),
  },
];
