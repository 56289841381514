import React, { useEffect, useState } from 'react';
import { Advert, AllocationResult } from 'types';
import { useAPI } from 'ffsdk';
import { State } from './State';

export function Reservation({ id, modified_at }: Advert): React.ReactElement | null {
  const api = useAPI();
  const [allocation, setAllocation] = useState<AllocationResult | undefined>(undefined);
  useEffect(() => {
    api.post<AllocationResult, unknown>(`reservation/allocation/${id}/`).then((res) => {
      if (res.ok) {
        setAllocation(res.data);
      } else {
        throw res.error;
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, modified_at]);

  if (allocation) {
    return <State {...allocation} />;
  }
  return null;
}
