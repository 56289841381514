import React from 'react';
import { TextField, SelectField, DecimalField, CheckField } from 'ffsdk';
import { CountrySelectField, CitySelectField, ModelSelectField } from 'components';

import { priceUnitOptions, timeUnitOptions } from '../const';
import { Surfaces } from './Surfaces';
import { Positions } from './Positions';

export function Form(): React.ReactElement {
  return (
    <div className="row">
      <div className="col-md-6">
        <TextField name="title" label="Nosaukums" required />
        <div className="form-row">
          <CountrySelectField name="country" label="Valsts" required className="col" />
          <CitySelectField name="city" label="Pilsēta" className="col" />
        </div>
        <div className="form-row">
          <DecimalField name="price" label="Noma" required className="col" />
          <DecimalField
            name="price_political"
            label="Noma politiskai reklāmai"
            className="col"
            help="Ja atšķirās (pirms koeficienta)"
          />
          <div className="col" />
        </div>
        <div className="form-row">
          <SelectField
            name="price_unit"
            label="Cenas vienība"
            simple
            options={priceUnitOptions}
            required
            className="col"
          />
          <SelectField name="time_unit" label="Laika vienība" simple options={timeUnitOptions} className="col" />
          <div className="col" />
        </div>
        <div className="form-row">
          <CheckField name="estimate_units" label="Nomas vienības" variant="switch" className="col" />
          <CheckField name="is_taxable" label="Apliekams ar nodevu" variant="switch" className="col" />
          <CheckField name="is_active" label="Iespējots" variant="switch" className="col" />
        </div>
        <ModelSelectField label="Modeļi" name="transport_models" isMulti required />

        <Surfaces />
      </div>
      <div className="col-md-6">
        <Positions />
      </div>
    </div>
  );
}
