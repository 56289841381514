import React, { useState } from 'react';
import cx from 'classnames';
import { Icon, AllocationBadge } from 'components';
import { Advert } from 'types';
import { formatDate } from 'core/util';
import { LoadingContainer } from 'ffsdk';
import { useAPIRequest } from 'core';
import './Exposure.scss';

interface VehicleState {
  id: number;
  title: string;
  reserved: number;
  live: number;
  neutralised: number;
  exposures: {
    id: number;
    number: string;
    date_from: string;
    date_to: string | null;
  }[];
}

function StateCard({ title, reserved, live, neutralised, exposures }: VehicleState): React.ReactElement {
  const [open, setOpen] = useState<boolean>(false);
  const hasExposures = exposures.length > 0;
  const cardTitle = (
    <div className="d-flex align-items-center">
      {hasExposures ? (
        <div onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
          <Icon icon={open ? 'chevron-down' : 'chevron-right'} style={{ marginRight: '0.5rem' }} />
          {title}
        </div>
      ) : (
        title
      )}
      <div className="ml-auto d-flex">
        <AllocationBadge className="reserved" count={reserved} label="rezervēti" />
        <AllocationBadge className="live ml-2" count={live} label="aktīvi" />
        <AllocationBadge className="neutralised ml-2" count={neutralised} label="neitralizēti" />
      </div>
    </div>
  );
  return (
    <div className="exposure-card card mb-1">
      <div className="card-header">{cardTitle}</div>
      {open && (
        <ul className="list-group list-group-flush">
          {exposures.map(({ id, number, date_from, date_to }) => (
            <li key={id} className="list-group-item">
              <div className="row">
                <div className="col">
                  <span className={cx('vehicle-state', date_to ? 'neutralised' : 'live')}>{number}</span>
                </div>
                <div className="col text-center">{formatDate(date_from)}</div>
                <div className="col text-right">{date_to && formatDate(date_to)}</div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export function Exposure({ advert: { id, campaign, modified_at } }: { advert: Advert }): React.ReactElement {
  const data = useAPIRequest<VehicleState[]>(
    { url: `campaign/campaigns/${campaign}/adverts/${id}/vehicle_state/`, method: 'GET' },
    id,
    modified_at,
  );
  return (
    <LoadingContainer loading={!data}>
      {(data || []).map((state) => (
        <StateCard key={state.id} {...state} />
      ))}
    </LoadingContainer>
  );
}
