import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Entity } from '../types';

import { Scene, SceneProps, Action } from 'core/ui';

import { Form, useSubmitHandler, FormError } from 'ffsdk';
import { useFormState } from 'react-final-form';

interface AddProps<E> extends Omit<SceneProps, 'actions'> {
  url: string;
  initialValues: any;
  // @TODO contracts
  element: React.ReactElement;
  onSuccess?: (item: E) => void;
  successMessage?: string;
}

function AddScene({
  crumbs: sceneCrumbs,
  children,
  ...rest
}: SceneProps & { children: React.ReactNode }): React.ReactElement {
  const { submitting, validating } = useFormState({ subscription: { submitting: true, validating: true } });
  const crumbs = sceneCrumbs || [{ title: 'Pievienot' }];
  const actions: Action[] = [
    {
      type: 'submit',
      variant: 'primary',
      disabled: submitting || validating,
      children: 'Iesniegt',
    },
  ];

  return (
    <Scene actions={actions} {...rest} crumbs={crumbs} {...rest}>
      {children}
    </Scene>
  );
}

export function Add<E extends Entity>(props: AddProps<E>): React.ReactElement {
  const navigate = useNavigate();
  const { url, onSuccess, successMessage = 'Ieraksts izveidots.', initialValues, element, ...sceneProps } = props;

  const onSubmit = useSubmitHandler(
    {
      url,
      method: 'POST',
    },
    {
      onSuccess: (item: E) => {
        toast.success(successMessage);
        if (onSuccess) {
          onSuccess(item);
        } else {
          navigate(`../${item.id}`);
        }
      },
    },
  );

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} renderErrors={false} noValidate>
      <AddScene {...sceneProps}>
        <FormError />
        {element}
      </AddScene>
    </Form>
  );
}
