import { Option } from 'components/fields/createSelectField';
import { InvoiceState } from 'types/invoicing';
import { EmbeddedAdvert } from './adverts';
import { EmbeddedCampaign } from './campaigns';
export type AgreementType = 'AGREEMENT' | 'ANNEX';
export const agreementTypeLabels: Record<AgreementType, string> = {
  AGREEMENT: 'Līgums',
  ANNEX: 'Pielikums',
};

export const agreementTypeOptions: Option<AgreementType>[] = (['AGREEMENT', 'ANNEX'] as AgreementType[]).map(
  (value) => ({
    value,
    label: agreementTypeLabels[value],
  }),
);

export type AgreementState = 'PENDING' | 'CONFIRMED' | 'RECEIVED';

export const agreementStateLabels: Record<AgreementState, string> = {
  PENDING: 'Gaida',
  CONFIRMED: 'Apstiprināts',
  RECEIVED: 'Saņemts',
};

export const agreementStateOptions: Option<AgreementState>[] = ([
  'PENDING',
  'CONFIRMED',
  'RECEIVED',
] as AgreementState[]).map((value) => ({
  value,
  label: agreementStateLabels[value],
}));

export interface EmbeddedAgreement {
  id: number;
  date: string;
  po_number: string;
  number: string;
  campaign: number;
  type: AgreementType;
  state: AgreementState;
  note: string;
}

export interface AgreementInvoice {
  id: number;
  checkout_date: string;
  po_number: string;
  sum_total: string;
  state: InvoiceState;
  display_number: string;
  motivation: string;
}

export interface Agreement extends EmbeddedAgreement {
  adverts: EmbeddedAdvert[];
  invoices: AgreementInvoice[];
}

export interface ListAgreement {
  id: number;
  date: string;
  payment_days: number;
  number: string;
  campaign: EmbeddedCampaign;
  type: AgreementType;
  state: AgreementState;
}
