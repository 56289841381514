import React from 'react';

import { NavLink } from 'react-router-dom';

import { EmbeddedAdvert } from 'types';
import { formatPeriod } from 'core/util';

import { StateBadge } from './badge';
import { StateBadge as AgreementStateBadge } from '../Agreements/badge';
import { url, title } from './util';

import { Column } from 'ffsdk';

const number = {
  content: 'Nr.',
  key: 'number',
  render: (advert: EmbeddedAdvert) => <NavLink to={url(advert)}>{advert.number}</NavLink>,
};

const state = {
  content: 'Stāvoklis',
  key: 'state',
  width: '110px',
  render: ({ state }: EmbeddedAdvert) => <StateBadge state={state} />,
};

const advert = {
  content: 'Reklāma',
  key: 'id',
  render: (advert: EmbeddedAdvert) => <NavLink to={url(advert)}>{title(advert)}</NavLink>,
};

const period = {
  content: 'Periods',
  key: 'date_from',
  width: '200px',
  render: (advert: EmbeddedAdvert) => <small>{formatPeriod(advert)}</small>,
};

const total = {
  content: 'Summa',
  className: 'text-right',
  headerClassName: 'text-right',
  key: 'sum_total',
};

export const columns: Column<EmbeddedAdvert>[] = [
  number,
  state,
  advert,

  {
    content: 'Kampaņa',
    key: 'campaign',
    render: ({ campaign: { id, title } }: EmbeddedAdvert) => (
      <NavLink to={`/campaigns/campaigns/${id}`}>{title}</NavLink>
    ),
  },
  {
    content: 'Klients',
    key: 'campaign.client.title',
  },
  period,
  total,
];

export const campaignColumns: Column<EmbeddedAdvert>[] = [
  number,
  state,
  advert,
  {
    content: 'Līg. stāv.',
    width: '110px',
    key: 'agreement',
    render: ({ agreement }: EmbeddedAdvert) => (agreement ? <AgreementStateBadge state={agreement.state} /> : null),
  },
  {
    content: 'Līg. Nr.',
    key: 'agreement.number',
    render: ({ agreement, campaign: { id: campaignId } }) =>
      agreement ? (
        <NavLink to={`/campaigns/campaigns/${campaignId}/agreements/${agreement.id}`}>{agreement.number}</NavLink>
      ) : null,
  },
  period,
  total,
];
