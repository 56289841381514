import styled from '@emotion/styled';
import React from 'react';
import ReactModal, { Props } from 'react-modal';

interface AdapterProps extends Props {
  className?: string; // passed in from stlyed
}

type Size = 'sm' | 'md' | 'lg';

const ReactModalAdapter: React.FC<AdapterProps> = ({ className, ...props }) => {
  const contentClassName = `${className} _content`;

  const overlayClasses = {
    afterOpen: `_overlay__open`,
    base: `_overlay`,
    beforeClose: `_overlay__close`,
  };

  return (
    <ReactModal portalClassName={className} className={contentClassName} overlayClassName={overlayClasses} {...props} />
  );
};

const SIZE_MAP: Record<Size, string> = {
  sm: '24rem',
  md: '34rem',
  lg: '46rem',
};

export const Modal = styled(ReactModalAdapter)<{
  size?: Size;
}>`
  & ._overlay {
    position: fixed;
    z-index: 180;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  & ._overlay__open {
    opacity: 1;
    transition: opacity 150ms ease-out;
  }

  & ._overlay__close {
    opacity: 0;
  }

  & ._content {
    width: ${({ size = 'md' }) => SIZE_MAP[size]};
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: #fff;
    overflow: visible;
    max-height: calc(100vh - 2rem);
  }
`;
