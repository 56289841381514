import { CampaignTheme } from 'types';

export const themeOptions: {
  value: CampaignTheme;
  label: string;
}[] = [
  { value: 'COMMERCIAL', label: 'Komerciāla' },
  { value: 'SOCIAL', label: 'Sociāla' },
  { value: 'POLITICAL', label: 'Politiska' },
];

export const laguageOptions: {
  value: string;
  label: string;
}[] = [
  { value: 'lv', label: 'Latviešu' },
  { value: 'en', label: 'Angļu' },
];
