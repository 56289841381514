import React from 'react';
import { useField } from 'react-final-form';
import { parseISO } from 'date-fns';
import { differenceInDays } from 'date-fns';

export function Days(): React.ReactElement {
  const {
    input: { value: date_from },
  } = useField('date_from', { subscription: { value: true } });
  const {
    input: { value: date_to },
  } = useField('date_to', { subscription: { value: true } });
  let days = '—';
  if (date_from && date_to) {
    days = (differenceInDays(parseISO(date_to), parseISO(date_from)) + 1).toString();
  }
  return <div className="ml-2">{days} dienas</div>;
}
