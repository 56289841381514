import React from 'react';
import cx from 'classnames';

export const Card: React.FC<{
  className?: string;
  title?: React.ReactNode;
}> = ({ children, className, title }) => (
  <div className={cx('card', className)}>
    {title && <div className="card-header">{title}</div>}
    <div className="card-body">{children}</div>
  </div>
);
